import styled from 'styled-components';

export const StyledIconLinkContent = styled.span`
  display: flex;
  width: 200px;
  height: 30px;
  font-family: 'General Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  color: #858dbd;
  position: relative;
  flex: 0 0 auto;
`;
