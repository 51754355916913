import { useState } from 'react';

import { DatePicker } from '@components/form-elements/DatePicker';
import { Button } from '@components/form-elements/buttons/Button';
import { CloseIcon } from '@components/icons/Close';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';

import { TProps } from './types/TProps';

import { Wrapper } from './styles/Wrapper';
import { Close } from './styles/Close';
import { FilterTitle } from './styles/FilterTitle';
import { ButtonsWrapper } from './styles/ButtonsWrapper';
import { SuggestionButtonsWrapper } from './styles/SuggestionButtonsWrapper';
import { BottomButton } from './styles/BottomButton';

import { firstDayOfCurrentWeek } from './helpers/firstDayOfCurrentWeek';
import { firstDayOfCurrentMonth } from './helpers/firstDayOfCurrentMonth';
import { startOfCurrentDay } from './helpers/startOfCurrentDay';
import { getSelectedDateFilterValue } from './helpers/getSelectedDateFilterValue';

export const Filter = ({ filter, setFilter, close }: TProps) => {
  const [tempDateFilterSelected, setTempDateFilterSelected] = useState(getSelectedDateFilterValue(filter));
  const [tempFilter, setTempFilter] = useState(filter);

  const tempSubmit = () => {
    setFilter(tempFilter);
    close();
  };
  const tempClose = () => {
    setTempFilter(filter);
    close();
  };

  return (
    <Wrapper>
      <FilterTitle>Filters</FilterTitle>
      <Close onClick={tempClose}>
        <CloseIcon />
      </Close>

      <div className="label">Jobs submitted</div>
      <div className="datepicker-wrapper">
        <DatePicker
          label="From"
          minDate={new Date(0)}
          maxDate={tempFilter.to || new Date()}
          value={tempFilter.from}
          onChange={(date: Date | null) => setTempFilter({ ...tempFilter, from: date })}
        />
      </div>

      <div className="datepicker-wrapper">
        <DatePicker
          label="To"
          minDate={tempFilter.from}
          maxDate={new Date()}
          value={tempFilter.to}
          onChange={(date: Date | null) =>
            setTempFilter((v: TJobsFilter) => {
              const newVal: TJobsFilter = Object.assign({}, v);
              let newDate = undefined;
              if (date) {
                const dateObj = new Date(date);
                dateObj.setHours(23);
                dateObj.setMinutes(59);
                newDate = dateObj;
              }
              newVal.to = newDate;
              return newVal;
            })
          }
        />
      </div>

      <SuggestionButtonsWrapper>
        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'today'}
          onClick={() => {
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = startOfCurrentDay();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('today');
          }}
        >
          Today
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'week'}
          onClick={() => {
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = firstDayOfCurrentWeek();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('week');
          }}
        >
          This week
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'month'}
          onClick={() => {
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = firstDayOfCurrentMonth();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('month');
          }}
        >
          This month
        </Button>
      </SuggestionButtonsWrapper>

      <ButtonsWrapper>
        <BottomButton
          variant="outlined"
          onClick={() => {
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = undefined;
              newVal.to = undefined;
              return newVal;
            });
            setTempDateFilterSelected('');
            setFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = undefined;
              newVal.to = undefined;
              return newVal;
            });
            close();
          }}
        >
          Reset
        </BottomButton>
        <BottomButton onClick={tempSubmit}>Apply</BottomButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};
