import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 12px 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  white-space: nowrap;

  .name {
    color: #b4b9d9;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
  }

  .tag {
    padding: 4px 6px;
    border-radius: 5px;
    border: 1px solid #d5def2;
    background: #f8fafb;
    color: #b4b9d9;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    cursor: pointer;

    &:hover,
    &.active {
      color: #40608f;
      background: #d5def2;
    }
  }
`;

export type StatusFilters = {
  all: boolean;
  JOB_STATUS_DRAFT: boolean;
  JOB_STATUS_PROCESSING: boolean;
  JOB_STATUS_TRANSCRIBING: boolean;
  JOB_STATUS_PROOFING: boolean;
  JOB_STATUS_COMPLETE: boolean;
  JOB_STATUS_READY_FOR_TRANSCRIBING: boolean;
  JOB_STATUS_READY_FOR_PROOFREADING: boolean;
  JOB_STATUS_READY_FOR_FINAL: boolean;
  JOB_STATUS_FINALIZING: boolean;
  AS_ONE_LOCKED: boolean;
};

type TProps = {
  statusFilters: StatusFilters;
  setStatusFilters: (value: StatusFilters) => void;
};

export const StatusFilters = ({ statusFilters, setStatusFilters }: TProps) => {
  const changeStatusFilters = (key: keyof typeof statusFilters) => {
    if (key === 'all' && !statusFilters.all) {
      setStatusFilters({
        all: true,
        JOB_STATUS_DRAFT: false,
        JOB_STATUS_PROCESSING: false,
        JOB_STATUS_TRANSCRIBING: false,
        JOB_STATUS_PROOFING: false,
        JOB_STATUS_COMPLETE: false,
        JOB_STATUS_READY_FOR_TRANSCRIBING: false,
        JOB_STATUS_READY_FOR_PROOFREADING: false,
        JOB_STATUS_READY_FOR_FINAL: false,
        JOB_STATUS_FINALIZING: false,
        AS_ONE_LOCKED: false,
      });
      return;
    }
    if (key === 'all' && statusFilters.all) {
      setStatusFilters({
        all: false,
        JOB_STATUS_DRAFT: true,
        JOB_STATUS_PROCESSING: true,
        JOB_STATUS_TRANSCRIBING: false,
        JOB_STATUS_PROOFING: false,
        JOB_STATUS_COMPLETE: false,
        JOB_STATUS_READY_FOR_TRANSCRIBING: false,
        JOB_STATUS_READY_FOR_PROOFREADING: false,
        JOB_STATUS_READY_FOR_FINAL: false,
        JOB_STATUS_FINALIZING: false,
        AS_ONE_LOCKED: false,
      });
      return;
    }
    if (!statusFilters[key]) {
      setStatusFilters({ ...statusFilters, all: false, [key]: !statusFilters[key] });
      return;
    }
    const truthyKey = Object.keys(statusFilters).find(
      (k) => k !== key && k !== 'all' && statusFilters[k as keyof typeof statusFilters],
    );
    setStatusFilters({ ...statusFilters, all: !truthyKey, [key]: !statusFilters[key] });
  };

  return (
    <Wrapper className="status-filter">
      <div className="name">Filter by status:</div>
      <div className={`tag ${statusFilters.all ? 'active' : ''}`} onClick={() => changeStatusFilters('all')}>
        All
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_DRAFT ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_DRAFT')}
      >
        Draft
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_PROCESSING ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_PROCESSING')}
      >
        Processing
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_TRANSCRIBING ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_TRANSCRIBING')}
      >
        Editing
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_PROOFING ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_PROOFING')}
      >
        Proofreading
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_COMPLETE ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_COMPLETE')}
      >
        Complete
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_READY_FOR_TRANSCRIBING ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_READY_FOR_TRANSCRIBING')}
      >
        Ready for editing
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_READY_FOR_PROOFREADING ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_READY_FOR_PROOFREADING')}
      >
        Ready for proofreading
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_READY_FOR_FINAL ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_READY_FOR_FINAL')}
      >
        Ready for final
      </div>
      <div
        className={`tag ${statusFilters.JOB_STATUS_FINALIZING ? 'active' : ''}`}
        onClick={() => changeStatusFilters('JOB_STATUS_FINALIZING')}
      >
        Finalizing
      </div>
      <div
        className={`tag ${statusFilters.AS_ONE_LOCKED ? 'active' : ''}`}
        onClick={() => changeStatusFilters('AS_ONE_LOCKED')}
      >
        Locked
      </div>
    </Wrapper>
  );
};
