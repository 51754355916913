import styled from 'styled-components';

export const DropIconHolder = styled.div`
  width: 15px;
  margin: 0 0 0 10px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 8px;
    height: 8px;
    display: block;
  }
`;
