import styled from 'styled-components';

export const Wrapper = styled.span`
  text-align: left;
  display: inline-block;
  width: 100%;
  position: relative;

  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  .react-datepicker {
    border: none;
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding: 10px 15px;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-names {
    margin-bottom: 0;
  }

  .react-datepicker__day-name {
    color: #858dbd;
  }

  .react-datepicker__day {
    color: #00122d;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border: 1px solid #40608f;
    border-radius: 20px;
    background: transparent;
    width: 20px !important;
    line-height: 20px !important;
    margin: 3px !important;

    &:hover {
      border-radius: 20px;
      background: transparent;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 26px;
    width: 26px;
    margin: 0;
  }

  .react-datepicker-popper {
    z-index: 15;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
  }

  .react-datepicker__triangle::after {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
    left: -20px !important;
  }

  .react-datepicker__header {
    background: #fff;
    border: none;
    padding: 0;
  }

  .react-datepicker__tab-loop {
    position: fixed;
    z-index: 100;
  }

  .react-datepicker__input-container {
    display: flex;
  }

  input {
    color: #858dbd;
    background: #f8fafb;
    border-radius: 5px;
    border: none;
    flex: 1 1 auto;
    padding: 14px 45px 14px 16px;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(202, 220, 248, 1);
    }

    &:hover {
      //background: #f8fafb;
      //color: #858dbd;
    }

    &:focus {
      background: #ffffff;
    }

    &:disabled {
      background: #f1f5fb !important;
      color: #b4b9d9 !important;
      outline: none !important;
    }

    &::placeholder {
      color: #858dbd;
      opacity: 1;
      font-weight: 500;
    }

    &.error {
      outline: none;
      box-shadow: 0 0 0 1px #ff2f2f !important;
    }
  }

  .icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    z-index: 10;

    &.clickable {
      cursor: pointer;
      pointer-events: all;
    }
  }
`;
