import styled from 'styled-components';

export const NavigationBlock = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0;

  .divider {
    margin: 1px 0;
  }
`;
