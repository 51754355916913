export const SMSWhitelistIcon = ({ color = '#858DBD' }: { color?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Sms">
      <g id="Group 4542">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 10.6732C3 6.2602 7.17791 2.92969 12 2.92969C16.8221 2.92969 21 6.2602 21 10.6732C21 15.0847 16.823 18.4149 12.0017 18.4157H12V17.5156L12.0033 18.4156L12.0017 18.4157C10.7939 18.4199 9.59634 18.2096 8.46439 17.7958L4.88318 20.0336C4.57686 20.225 4.18579 20.2147 3.88993 20.0075C3.59408 19.8003 3.45077 19.4362 3.52597 19.083L4.42496 14.8591C3.53024 13.6625 3 12.2258 3 10.6732ZM12 4.72969C7.87564 4.72969 4.8 7.52682 4.8 10.6732C4.8 11.9152 5.26369 13.0809 6.08181 14.0523C6.26071 14.2647 6.33153 14.5478 6.27372 14.8194L5.72808 17.3831L7.89427 16.0295C8.1421 15.8746 8.44969 15.8497 8.71919 15.9627C9.75689 16.3978 10.8715 16.6198 11.9967 16.6157L12 16.6156C16.1245 16.6156 19.2 13.8184 19.2 10.6732C19.2 7.52682 16.1244 4.72969 12 4.72969Z"
          fill={color}
        />
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.999 11.9293C12.5052 11.9293 13.0115 11.423 13.0115 10.9168C13.0115 10.4105 12.5052 9.9043 11.999 9.9043C11.4927 9.9043 10.9875 10.4105 10.9875 10.9168C10.9875 11.423 11.4927 11.9293 11.999 11.9293ZM7.94899 11.9293C8.45524 11.9293 8.96149 11.423 8.96149 10.9168C8.96149 10.4105 8.45524 9.9043 7.94899 9.9043C7.44274 9.9043 6.9375 10.4105 6.9375 10.9168C6.9375 11.423 7.44274 11.9293 7.94899 11.9293ZM16.049 11.9293C16.5552 11.9293 17.0625 11.423 17.0625 10.9168C17.0625 10.4105 16.5563 9.9043 16.05 9.9043C15.5438 9.9043 15.0375 10.4105 15.0375 10.9168C15.0375 11.423 15.5438 11.9293 16.05 11.9293H16.049Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);
