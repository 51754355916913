import styled from 'styled-components';
import { getShortName } from '@helpers/getShortName';

import { TOrganization } from 'app/types/entities/TOrganization';
import { TUser } from 'app/types/entities/TUser';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div`
  display: flex;
  gap: 9px;
  flex-wrap: wrap;

  .organization {
    display: flex;
    gap: 10px;
    width: 265px;
    background: #ffffff;
    border-radius: 5px;

    .organization-shortName {
      flex: 0 0 auto;
      width: 72px;
      height: 72px;
      background: #cadcf8;
      border-radius: 5px;
      font-style: normal;
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 72px;
      letter-spacing: 0.01em;
      color: #ffffff;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .organization-right {
      padding: 10px 0;

      .organization-name {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #40608f;
        width: 173px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .organization-usersCount {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        padding: 2px 0 0;
        width: 173px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

type TProps = {
  user: TUser;
};

export const Organizations = ({ user }: TProps) => {
  if (!user) {
    return null;
  }

  const renderOrganization = (organization: TOrganization) => {
    const organizationShortname = getShortName(organization.name || organization.slug);
    return (
      <div className="organization" key={organization.id}>
        <div className="organization-shortName">
          {organization.image ? <img alt="" src={organization.image} /> : organizationShortname}
        </div>
        <div className="organization-right">
          <div className="organization-name">{organization.name}</div>
          <div className="organization-usersCount">
            {organization.userCount} {organization.userCount === 1 ? 'member' : 'members'}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Wrapper>
        <LeftSide>
          <h3>Organization memberships</h3>
        </LeftSide>

        <RightSide>{(user.orgRelations || []).map((organization) => renderOrganization(organization))}</RightSide>
      </Wrapper>
    </>
  );
};
