import { client } from 'app/http';

export const getRepositories = async (orgId: string) => {
  try {
    const response = await client.get(`/organizations/${orgId}/repositories`);
    return response.data;
  } catch (err) {
    console.log('getRepositories request failed:', err);
    return [];
  }
};
