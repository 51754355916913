import { useState } from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import { Scrollbars } from 'react-custom-scrollbars-2';

const PickerHeader = styled.div`
  display: flex;
  justify-content: center;
`;
const CurrentMonth = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: #40608f;
`;

const DatePickerWrapper = styled.div`
  padding: 20px 0 12px;

  .react-datepicker {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-names {
    margin-bottom: 0;
  }

  .react-datepicker__day-name {
    font-weight: 500 !important;
    color: #858dbd;
  }

  .react-datepicker__day {
    color: #00122d;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border: 1px solid #40608f;
    border-radius: 28px;
    background: transparent;
    width: 28px !important;
    line-height: 28px !important;
    margin: 3px !important;

    &:hover {
      border-radius: 28px;
      background: transparent;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 34px;
    width: 34px;
    margin: 0;
  }

  .react-datepicker-popper {
    z-index: 15;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
  }

  .react-datepicker__triangle::after {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }

  .react-datepicker__header {
    background: #fff;
    border: none;
    padding: 0;
  }

  .react-datepicker__tab-loop {
    position: fixed;
    z-index: 100;
  }

  .react-datepicker__input-container {
    display: flex;
  }
`;

const JobsList = styled.div`
  margin: 0 -20px 0 0;

  .jobsList-wrapper {
    padding: 0 20px 0 0;
  }

  .jobsList-noJobs {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #858dbd;
    text-align: center;
    padding: 5px 0 10px;
  }

  .jobsList-item:last-child {
    margin: 0;
    padding: 0 0 5px;
    border-bottom: none;
  }

  .jobsList-item {
    padding: 0 0 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #d5def2;

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: #858dbd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-assignee {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const getExcludeDates = (currentDate = new Date(), initDates: CalendarDate[]): Date[] => {
  const endOfThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 10);
  let iterableDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const dates: Date[] = [];
  while (iterableDate.getTime() < endOfThisMonth.getTime()) {
    if (!initDates.find((date: CalendarDate) => date.date === getDateYMD(iterableDate))) {
      dates.push(new Date(iterableDate));
    }
    iterableDate = new Date(iterableDate.getFullYear(), iterableDate.getMonth(), iterableDate.getDate() + 1);
  }
  return dates;
};

const getDateYMD = (date: Date | string | undefined) => {
  const d = new Date(date ?? 0);
  const month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
  const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;
  return `${month}-${day}-${d.getFullYear()}`;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type CalendarJob = {
  id: string;
  name: string;
  assignee?: {
    name: string;
    lastname: string;
  };
};

type CalendarDate = {
  date: string;
  jobs: CalendarJob[];
};

type TProps = {
  calendar: CalendarDate[];
};

export const DeadlineCalendar = ({ calendar = [] }: TProps) => {
  const [currentDate, setCurrentDate] = useState<Date | undefined>(new Date());
  const excludeDates = getExcludeDates(currentDate, calendar);
  const currentDateInfo = calendar.find((date) => date.date === getDateYMD(currentDate));
  let currentDateJobs: CalendarJob[] = [];
  if (currentDateInfo) {
    currentDateJobs = currentDateInfo.jobs;
  }
  const startOfThisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const endOfThisMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
  const dateFormat = 'MM/dd/yyyy';

  return (
    <>
      <DatePickerWrapper>
        <ReactDatePicker
          dateFormat={dateFormat}
          minDate={startOfThisMonth}
          maxDate={endOfThisMonth}
          excludeDates={excludeDates}
          renderCustomHeader={({ date }) => (
            <PickerHeader>
              {/* <StyledControlIcon icon={leftIcon} onClick={decreaseMonth} />*/}
              <CurrentMonth>{months[date.getMonth()]}</CurrentMonth>
              {/* <StyledControlIcon icon={rightIcon} onClick={increaseMonth} />*/}
            </PickerHeader>
          )}
          selected={currentDate}
          onChange={(date: Date | null) => setCurrentDate(date ?? new Date())}
          disabledKeyboardNavigation
          inline
        />
      </DatePickerWrapper>

      <JobsList>
        {!currentDateJobs.length ? <div className="jobsList-noJobs">No Jobs on this date</div> : null}
        {currentDateJobs.length ? (
          <Scrollbars autoHeight autoHeightMin={30} autoHeightMax={320}>
            <div className="jobsList-wrapper">
              {currentDateJobs.map((cdj: CalendarJob) => {
                const assignee = cdj.assignee ? `${cdj.assignee.name} ${cdj.assignee.lastname}` : 'Job is not assigned';
                return (
                  <div className="jobsList-item" key={cdj.id}>
                    <div className="jobsList-item-title">{cdj.name}</div>
                    <div className="jobsList-item-assignee">{assignee}</div>
                  </div>
                );
              })}
            </div>
          </Scrollbars>
        ) : null}
      </JobsList>
    </>
  );
};
