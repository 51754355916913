import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { AddPlusButton } from '@components/form-elements/buttons/AddPlusButton';

import { OrganizationCard } from './components/OrganizationCard';
import { useAuthContext } from '@providers/AuthProvider';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 20px 0 0;

  & > .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export function OrganizationCards() {
  const { openModal } = useModalContext();
  const { me } = useAuthContext();

  const handleCreateOrganizationModalOpen = () => {
    openModal(ModalNamesEnum.NewOrganizationModal);
  };

  return (
    <Wrapper>
      <ul role="list" className="list">
        {me.organizations.map((organization, idx) => (
          <OrganizationCard key={idx} organization={organization} />
        ))}
        <AddPlusButton onClick={handleCreateOrganizationModalOpen} />
      </ul>
    </Wrapper>
  );
}
