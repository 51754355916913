import { SVGProps } from 'react';

export const BillingAccountsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.38462 6C4.1722 6 4 6.1722 4 6.38462V14.6923C4 14.9047 4.1722 15.0769 4.38462 15.0769H16.1538C16.3663 15.0769 16.5385 14.9047 16.5385 14.6923V6.38462C16.5385 6.1722 16.3663 6 16.1538 6H4.38462ZM2 6.38462C2 5.06763 3.06763 4 4.38462 4H16.1538C17.4708 4 18.5385 5.06763 18.5385 6.38462V14.6923C18.5385 16.0093 17.4708 17.0769 16.1538 17.0769H4.38462C3.06763 17.0769 2 16.0093 2 14.6923V6.38462Z"
      fill="#858DBD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2692 9.46155C9.67448 9.46155 9.19232 9.9437 9.19232 10.5385C9.19232 11.1332 9.67448 11.6154 10.2692 11.6154C10.864 11.6154 11.3462 11.1332 11.3462 10.5385C11.3462 9.9437 10.864 9.46155 10.2692 9.46155ZM7.19232 10.5385C7.19232 8.83913 8.56991 7.46155 10.2692 7.46155C11.9686 7.46155 13.3462 8.83913 13.3462 10.5385C13.3462 12.2378 11.9686 13.6154 10.2692 13.6154C8.56991 13.6154 7.19232 12.2378 7.19232 10.5385Z"
      fill="#858DBD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 10.2308C21.5523 10.2308 22 10.6785 22 11.2308V18.1539C22 18.7863 21.7488 19.3928 21.3016 19.84C20.8544 20.2872 20.2478 20.5385 19.6154 20.5385H7.15387C6.60158 20.5385 6.15387 20.0908 6.15387 19.5385C6.15387 18.9862 6.60158 18.5385 7.15387 18.5385H19.6154C19.7174 18.5385 19.8152 18.4979 19.8874 18.4258C19.9595 18.3537 20 18.2559 20 18.1539V11.2308C20 10.6785 20.4477 10.2308 21 10.2308Z"
      fill="#858DBD"
    />
  </svg>
);
