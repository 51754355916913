import { client } from 'app/http';

type AssignLicensePayload = {
  subscriptionId: string;
  licenseId: string;
  assigneeId: string | null;
};

export const assignLicense = async (
  baid: string,
  payload: AssignLicensePayload,
): Promise<{ success: boolean; error?: string }> => {
  if (!baid) {
    console.error('[assignLicense] baid was not provided');
    return { success: false };
  }
  const { data } = await client.put(`/billing-account/${baid}/assign-license`, payload);
  return data;
};
