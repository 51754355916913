import { BillingAccountCard } from './BillingAccountCard';
import styled from 'styled-components';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';

const Wrapper = styled.div`
  margin: 20px 0 0;

  & > .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

type TProps = {
  billingAccount?: TBillingAccount;
  organizationId: string;
};

export function BillingAccount({ billingAccount }: TProps) {
  return (
    <Wrapper>
      <ul role="list" className="list">
        {billingAccount && <BillingAccountCard billingAccount={billingAccount} />}
      </ul>
    </Wrapper>
  );
}
