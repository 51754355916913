import React from 'react';
import styled from 'styled-components';

import { Input } from '@components/form-elements/Input';
import { Block } from '@components/Block';

import { ReporterAssigneeBlock } from './ReporterAssigneeBlock';
import { TimeIcon } from '@pages/User/NewJobPage/components/Content/blocks/icons/Time';
import { AddressIcon } from '@pages/User/NewJobPage/components/Content/blocks/icons/Address';
import { LinkIcon } from '@pages/User/NewJobPage/components/Content/blocks/icons/Link';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';
import { SmallWhiteTimeInput } from '@components/form-elements/TimeInput';
import { SmallWhiteTimeZoneSelect } from '@components/form-elements/TimeZoneSelect/variations/SmallWhiteTimeZoneSelect';
import { TextDatePicker } from '@components/form-elements/TextDatePicker';
import { Switch } from '@components/form-elements/Switch';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';
import { TJob } from 'app/types/entities/TJob';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';

const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px 0 44px;
    height: 30px;
  }
`;

const Wrapper = styled.div`
  .before-switch {
    display: block;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    left: 10px;
    z-index: 10;
    transform: translate(0px, -50%);
    top: 50%;
    pointer-events: none;
  }
`;

const FlexTimeWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;
const DatePickerWrapper = styled.div`
  padding: 0;

  .icon {
    right: auto;
    left: 15px;
  }

  input {
    padding: 7px 10px 7px 44px;
    background: #ffffff;
  }
`;

export const ScheduleBlock = () => {
  const { form, setForm, setFormItemValue, isLoading } = usePageContext();

  const showMeetingLocation = form.item?.meetingType !== 'RECORDING_SESSION_REMOTE';
  const showMeetingLink = form.item?.meetingType !== 'RECORDING_SESSION_IN_PERSON';

  const handleInputChange = async (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    await setFormItemValue(name, value);
  };

  const handleTimeChange = async (value: string) => {
    await setFormItemValue('meetingTime', value);
  };

  const handleMeetingTypeChange = async (item: { label: string; value: string }) => {
    await setFormItemValue('meetingType', item?.value === 'null' ? null : item?.value);
  };
  const handleTimeZoneChange = async (name: string, value: string) => {
    await setFormItemValue('meetingTimezone', value === 'null' ? null : value);
  };

  const switchStatus = async () => {
    const newStatus = form.item?.status === 'JOB_STATUS_SCHEDULED' ? 'JOB_STATUS_DRAFT' : 'JOB_STATUS_SCHEDULED';
    if (newStatus === 'JOB_STATUS_DRAFT') {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setForm({
        ...form,
        item: {
          ...form.item,
          meetingAddress: '',
          meetingLink: '',
          meetingType: undefined,
          meetingDate: undefined,
          meetingTime: '12:00',
          meetingTimezone: tz,
          meetingAssignee: undefined,
          meetingAssigneeId: undefined,
          status: newStatus,
          layoutId: null,
        } as TJob,
      });
    } else {
      setForm({
        ...form,
        item: {
          ...form.item,
          status: newStatus,
          layoutId: null,
        } as TJob,
      });
    }
  };

  return (
    <Wrapper>
      <Block title="Schedule AutoScriptOne Realtime">
        <span className="before-switch" />
        <Switch checked={form.item?.status && jobIsScheduled(form.item?.status)} onChange={switchStatus} />
      </Block>

      {form.item?.status && jobIsScheduled(form.item?.status) ? (
        <Block title="Schedule">
          <SmallWhiteSelect
            name="meetingType"
            label="Meeting type"
            required={true}
            value={form.item?.meetingType ?? ''}
            disabled={isLoading}
            onChange={(label, value) => handleMeetingTypeChange({ label, value })}
            options={[
              {
                value: 'null',
                label: 'Not set',
              },
              {
                value: 'RECORDING_SESSION_REMOTE',
                label: 'Remote',
              },
              {
                value: 'RECORDING_SESSION_HYBRID',
                label: 'Hybrid',
              },
              {
                value: 'RECORDING_SESSION_IN_PERSON',
                label: 'In Person',
              },
            ]}
          />

          <>
            {form.item?.meetingType?.length ? (
              <>
                <DatePickerWrapper>
                  <TextDatePicker
                    label="Date"
                    required={true}
                    minDate={new Date()}
                    maxDate={new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)}
                    value={form.item?.meetingDate ?? null}
                    onChange={(date: string | null) => {
                      if (!date) {
                        setFormItemValue('meetingDate', null);
                        return;
                      }
                      setFormItemValue('meetingDate', date);
                    }}
                  />
                </DatePickerWrapper>

                <FlexTimeWrapper>
                  <InputWrapper>
                    <TimeIcon />
                    <SmallWhiteTimeInput
                      onChange={handleTimeChange}
                      required={true}
                      label="Time"
                      value={form.item?.meetingTime ?? '12:00'}
                    />
                  </InputWrapper>

                  <SmallWhiteTimeZoneSelect
                    onChange={handleTimeZoneChange}
                    required={true}
                    name="meetingTimeZone"
                    label="Time zone"
                    value={form.item?.meetingTimezone ?? ''}
                  />
                </FlexTimeWrapper>

                {showMeetingLocation ? (
                  <InputWrapper>
                    <AddressIcon />
                    <SmallWhiteInput
                      onChange={handleInputChange}
                      required={(form.item?.meetingType ?? '') !== 'RECORDING_SESSION_HYBRID'}
                      name="meetingAddress"
                      label="Address"
                      value={form.item?.meetingAddress ?? ''}
                    />
                  </InputWrapper>
                ) : null}
                {showMeetingLink ? (
                  <InputWrapper>
                    <LinkIcon />
                    <SmallWhiteInput
                      onChange={handleInputChange}
                      required={(form.item?.meetingType ?? '') !== 'RECORDING_SESSION_HYBRID'}
                      name="meetingLink"
                      label="Meeting link"
                      value={form.item?.meetingLink ?? ''}
                    />
                  </InputWrapper>
                ) : null}
              </>
            ) : null}
          </>
        </Block>
      ) : null}

      {form.item?.meetingType?.length ? <ReporterAssigneeBlock /> : null}
    </Wrapper>
  );
};
