import { SVGProps } from 'react';

export const DownloadZip = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={20} fill="none" {...props}>
    <path
      stroke="#858DBD"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={1.84}
      d="M12 13V1m0 12L8 9m4 4 4-4M2 15l.621 2.485A2 2 0 0 0 4.561 19h14.878a2 2 0 0 0 1.94-1.515L22 15"
    />
  </svg>
);
