import { useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';

import DownIcon from '@components/Table/SortingDirection/down.svg';
import UpIcon from '@components/Table/SortingDirection/up.svg';
import { WorkspaceIcon, WorkspaceIconVariant } from '@components/WorkspaceIcon/WorkspaceIcon';
import { ClickAway } from '@components/ClickAway';

import { WorkspaceList } from './components/WorkspaceList';
import { SelectedWorkspaceName } from './components/SelectedWorkspaceName';

import { WorkspacesWrapper } from './styles/WorkspacesWrapper';
import { DropIconHolder } from './styles/DropIconHolder';
import { StyledAnchor } from './styles/StyledAnchor';
import { StyledIconLinkContent } from './styles/StyledIconLinkContent';
import { TWorkspace } from 'app/types/entities/TWorkspace';

export const WorkspacesListWrapper = () => {
  const { me, workspace, organization } = useAuthContext();
  const [workspaceListOpen, setWorkspaceListOpen] = useState(false);

  if (!me || !workspace || !organization) {
    return <></>;
  }

  const multipleWorkspacesInOrg =
    me.workspaces.filter((ws: TWorkspace) => ws.organizationId === organization?.id).length > 1;

  return (
    <WorkspacesWrapper>
      <ClickAway onClickAway={() => setWorkspaceListOpen(false)}>
        <StyledAnchor>
          <StyledIconLinkContent>
            <WorkspaceIcon
              name={workspace.name}
              onClick={() => multipleWorkspacesInOrg && setWorkspaceListOpen(!workspaceListOpen)}
              variant={WorkspaceIconVariant.Small}
            />
            <SelectedWorkspaceName
              workspace={workspace}
              organization={organization}
              isOpen={workspaceListOpen}
              setWorkspaceListOpen={setWorkspaceListOpen}
              multipleWorkspacesInOrg={multipleWorkspacesInOrg}
            />
            {multipleWorkspacesInOrg ? (
              <DropIconHolder onClick={() => setWorkspaceListOpen(!workspaceListOpen)}>
                {workspaceListOpen ? <UpIcon className="w-2" /> : <DownIcon className="w-2" />}
              </DropIconHolder>
            ) : null}
          </StyledIconLinkContent>
        </StyledAnchor>

        {multipleWorkspacesInOrg && workspaceListOpen ? (
          <div className="relative">
            <WorkspaceList closeList={() => setWorkspaceListOpen(false)} />
          </div>
        ) : null}
      </ClickAway>
    </WorkspacesWrapper>
  );
};
