import React, { createRef, useEffect, useState } from 'react';
import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { usePageContext } from '../../../../providers/PageProvider';
import { sendOTPCode } from '@queries/registration/sendOTPCode';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { PhoneIcon } from '@components/icons/Phone';
import { Error } from '@components/Error';
import { verifyOTPCode } from '@queries/registration/verifyOTPCode';

export const RegistrationPageCodeStage = () => {
  const {
    isLoading,
    setIsLoading,
    phone,
    codeSentDate,
    setCodeSentDate,
    setOTPCode,
    error,
    setError,
    goToLogin,
    register,
  } = usePageContext();
  const isTestEnvironment = window.location.hostname === 'localhost';
  const RESEND_TIMEOUT = 60000;

  const [now, setNow] = useState(Date.now());
  const [code, setCode] = useState({
    code0: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
  });

  const codeRefs = Array.from({ length: 6 }, () => createRef<HTMLInputElement>());

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 1000);
    return () => clearInterval(interval);
  }, []);

  const handleFormSubmit = async (evt: React.FormEvent) => {
    setError('');
    evt.preventDefault();
    setOTPCode(`${code.code0[0]}${code.code1[0]}${code.code2[0]}${code.code3[0]}${code.code4[0]}${code.code5[0]}`);
    setIsLoading(true);
    const verifyResponse = await verifyOTPCode(
      phone,
      `${code.code0[0]}${code.code1[0]}${code.code2[0]}${code.code3[0]}${code.code4[0]}${code.code5[0]}`,
    );

    if (!verifyResponse.result) {
      setError('The code is invalid');
      setIsLoading(false);
      if (!isTestEnvironment) {
        return;
      }
    }

    await register();
  };

  const resend = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (codeSentDate > Date.now() - RESEND_TIMEOUT) {
      console.log('Only once a minute!');
      return;
    }
    setIsLoading(true);
    await sendOTPCode(phone);
    setCodeSentDate(Date.now());
    setCode({
      code0: '',
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
    });
    setIsLoading(false);
  };

  const onCodeChange = (
    evt: React.ChangeEvent<
      | HTMLInputElement
      | {
          name: string;
          value: string;
        }
    >,
  ) => {
    setError('');
    const { name, value } = evt.target;
    setCode({ ...code, [name]: value[0] });
  };

  const isButtonDisabled = !phone?.length || !code.code5;

  const useNextRef = (evt: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (evt.key === 'Backspace' && index > 0) {
      return () => codeRefs[index - 1].current?.focus();
    }

    if (index === 5) {
      return () => handleFormSubmit;
    }
    return () => {
      codeRefs[index + 1].current?.focus();
    };
  };

  return (
    <div className="root">
      <h1 className="header">Verify Your Phone Number</h1>

      <form onSubmit={handleFormSubmit}>
        {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
        <h2 className="enterPhoneNumber">Enter the verification code that has been sent to</h2>
        <div className="margin20">
          <Input
            onChange={() => {}}
            name="phone"
            label="Phone number"
            type="phone"
            value={phone || ''}
            icon={PhoneIcon}
            disabled={true}
          />
        </div>
        <div className="margin20 flexCode">
          <Input
            onChange={onCodeChange}
            name="code0"
            label="X"
            value={code.code0 || ''}
            disabled={isLoading}
            ref={codeRefs[0]}
            onKeyUp={(evt) => useNextRef(evt, 0)()}
          />
          -
          <Input
            onChange={onCodeChange}
            name="code1"
            label="X"
            value={code.code1 || ''}
            disabled={isLoading || !code.code0}
            ref={codeRefs[1]}
            onKeyUp={(evt) => useNextRef(evt, 1)()}
          />
          -
          <Input
            onChange={onCodeChange}
            name="code2"
            label="X"
            value={code.code2 || ''}
            disabled={isLoading || !code.code1}
            ref={codeRefs[2]}
            onKeyUp={(evt) => useNextRef(evt, 2)()}
          />
          -
          <Input
            onChange={onCodeChange}
            name="code3"
            label="X"
            value={code.code3 || ''}
            disabled={isLoading || !code.code2}
            ref={codeRefs[3]}
            onKeyUp={(evt) => useNextRef(evt, 3)()}
          />
          -
          <Input
            onChange={onCodeChange}
            name="code4"
            label="X"
            value={code.code4 || ''}
            disabled={isLoading || !code.code3}
            ref={codeRefs[4]}
            onKeyUp={(evt) => useNextRef(evt, 4)()}
          />
          -
          <Input
            onChange={onCodeChange}
            name="code5"
            label="X"
            value={code.code5 || ''}
            disabled={isLoading || !code.code4}
            ref={codeRefs[5]}
            onKeyUp={(evt) => useNextRef(evt, 5)()}
          />
        </div>

        <Button size="medium" disabled={isButtonDisabled} className="margin30" type="submit">
          Verify
        </Button>

        {codeSentDate < now - RESEND_TIMEOUT ? (
          <p className="haveAnAccount">
            Did not receive a code?&nbsp;&nbsp;
            <span onClick={resend} className="signIn">
              Resend
            </span>
          </p>
        ) : null}

        {error ? (
          <div className="formError" data-testid="errorMessage">
            <Error error={error} />
          </div>
        ) : null}
      </form>

      <p className="haveAnAccount">
        Have an account?&nbsp;&nbsp;
        <span onClick={goToLogin} className="signIn">
          Sign In
        </span>
      </p>
    </div>
  );
};
