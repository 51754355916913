import { MouseEvent } from 'react';
import styled from 'styled-components';
import { getShortName } from '@helpers/getShortName';

type WorkspaceNameProps = {
  variant?: WorkspaceIconVariant;
};

const WorkspaceName = styled.div<WorkspaceNameProps>`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.variant === WorkspaceIconVariant.Small ? '20px' : '36px')};
  line-height: 130%;
  text-transform: uppercase;
  border-radius: 5px;
  height: ${(props) => (props.variant === WorkspaceIconVariant.Small ? '30px' : '72px')};
  width: ${(props) => (props.variant === WorkspaceIconVariant.Small ? '30px' : '72px')};
`;

export enum WorkspaceIconVariant {
  Default = 'default',
  Small = 'small',
}

type TProps = {
  name: string;
  onClick: (event: MouseEvent) => void;
  variant?: WorkspaceIconVariant;
};

export const WorkspaceIcon = ({ name, onClick, variant }: TProps) => {
  const workspaceShortname = getShortName(name);

  return (
    <WorkspaceName
      variant={variant}
      className={`flex-shrink-0 flex items-center justify-center text-white font-medium cursor-pointer bg-lavender-blue ${
        variant === WorkspaceIconVariant.Small ? 'h-8 w-8 text-xs' : 'text-sm'
      }}`}
      onClick={onClick}
    >
      {workspaceShortname}
    </WorkspaceName>
  );
};
