import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAPI } from '@hooks/useAPI';

import { JobsService } from 'app/API';
import { Button } from '@components/form-elements/buttons/Button';

import { getASOneDownloadLink } from '@helpers/getASOneDownloadLink';

import Wave from './wave.svg';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

const Root = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 130px;
  position: relative;
  text-align: center;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
`;

const ClickOpenBelow = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding: 40px;

  p {
    margin: 0;
  }
`;
const LaunchButtonWrapper = styled.div`
  padding: 0 40px 40px;
`;
const WaveWrapper = styled.div`
  padding: 0 40px 40px;

  svg {
    margin: 0 auto;
  }
`;
const LaunchButton = styled(Button)`
  width: 190px;
`;
const DontHaveAutoScriptInstalled = styled.div`
  padding: 0 40px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;
const DownloadNow = styled.a`
  display: block;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  padding: 0 40px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #858dbd;
`;

const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 0 50px;
  color: #858dbd;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  a {
    color: #858dbd;
  }
`;
const TermsPrivacy = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  padding: 20px 40px 10px;
`;
const CopyRight = styled.div`
  padding: 0 40px;
`;

const getLink = (id: string): string => {
  const location = window.location.hostname;
  if (location === 'www.autoscript.ai') {
    return `aso://${id}`;
  }
  if (location === 'demo.autoscript.ai') {
    return `aso-demo://${id}`;
  }
  if (location === 'staging.autoscript.ai') {
    return `aso-dev://${id}`;
  }
  // using staging for local development
  return `aso-dev://${id}`;
};

export const OpenInASOneJobPage = () => {
  const { call } = useAPI();

  const { id } = useParams<{ id: string }>();
  const [isLocked, setIsLocked] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const launchHandler = () => {
    // console.log('launchHandler!', getLink(jobId));
    window.open(new URL(getLink(id ?? '')), '_self');
  };

  useEffect(() => {
    call(JobsService.getJobLockedStatusByRecordingId({ id: id ?? '' }))
      .then((isLockedResult) => {
        setIsLocked(isLockedResult.result);
        setIsLoading(false);
        if (!isLockedResult.result) {
          setTimeout(launchHandler, 1000);
        }
      })
      .catch(() => {
        // FIXME: The error is because the `isLockedResult` is undefined when the request sends an error status
        setIsLoading(false);
        setNotFound(true);
      });
  }, [id]);

  if (isLoading) {
    return (
      <Root>
        <AbsoluteSpinner />
      </Root>
    );
  }

  if (isLocked) {
    return (
      <Root>
        <ClickOpenBelow>
          <p>
            This job is <b>locked</b>. In order to unlock this job you need to <b>pay remaining sum</b>
          </p>
        </ClickOpenBelow>

        <Footer>
          <TermsPrivacy>
            <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </TermsPrivacy>
          <CopyRight>&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</CopyRight>
        </Footer>
      </Root>
    );
  }

  if (notFound) {
    return (
      <Root>
        <ClickOpenBelow>
          <p>
            Sorry, this job was <b>not found</b>.
          </p>
        </ClickOpenBelow>

        <Footer>
          <TermsPrivacy>
            <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </TermsPrivacy>
          <CopyRight>&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</CopyRight>
        </Footer>
      </Root>
    );
  }

  return (
    <Root>
      <ClickOpenBelow>
        <p>
          Click <b>Open AutoScriptOne App</b> on the dialog shown by your browser.
        </p>
        <p>
          If you don’t see a dialog, click <b>Launch App</b> below.
        </p>
      </ClickOpenBelow>
      <LaunchButtonWrapper>
        <LaunchButton size="small" onClick={launchHandler}>
          Launch AutoScriptOne App
        </LaunchButton>
      </LaunchButtonWrapper>
      <WaveWrapper>
        <Wave />
      </WaveWrapper>
      <DontHaveAutoScriptInstalled>Don’t have AutoScriptOne App installed?</DontHaveAutoScriptInstalled>
      <DownloadNow href={getASOneDownloadLink()} target="_blank">
        Download Now
      </DownloadNow>

      <Footer>
        <TermsPrivacy>
          <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          |{' '}
          <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </TermsPrivacy>
        <CopyRight>&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</CopyRight>
      </Footer>
    </Root>
  );
};
