import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';

import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Header } from '@components/Header';
import { Add } from '@components/icons/Add';
import { Move as MoveIcon } from '@components/icons/Move';
import { TrashBin } from '@components/icons/TrashBin';

import { usePageContext } from '../../providers/PageProvider';

import { TabSwitcher } from './components/TabSwitcher';
import { StatusFilters } from './components/StatusFilters';
import { JobsTable } from './components/JobsTable';
import { WSUpdateReceiver } from './components/WSUpdateReceiver';
import { FilterPopover } from './components/FilterPopover';

import { Wrapper } from './styles/Wrapper';
import { StyledButton } from './styles/StyledButton';
import { MoveButton } from './styles/MoveButton';
import { DeleteButton } from './styles/DeleteButton';

export const Content = () => {
  const {
    reload,
    currentTab,
    setCurrentTab,
    checkedJobs,
    setAnchorEl,
    dateFilter,
    search,
    setSearch,
    jobs,
    isLoading,
    exportCSV,
    statusFilters,
    setStatusFilters,
    page,
    setPage,
    pageSize,
    setPageSize,
    orderBy,
    setOrderBy,
    orderType,
    setOrderType,
    setCheckedJobs,
  } = usePageContext();
  const { openModal, closeModal } = useModalContext();
  const { organization, workspace } = useAuthContext();
  const navigate = useNavigate();

  const addJob = () => {
    navigate(routes.newJob.make(organization?.slug ?? '', workspace?.slug ?? ''));
  };

  const headerLeftSideContent = [];
  if (organization?.permissions?.submitJobs) {
    headerLeftSideContent.push(
      <StyledButton key="button" data-testid="create_job_header_button" size="small" onClick={addJob}>
        <Add height="12px" />
        Create New Job
      </StyledButton>,
    );
  }
  headerLeftSideContent.push(<TabSwitcher key="TabSwitcher" currentTab={currentTab} setCurrentTab={setCurrentTab} />);

  const showMoveJobsToWSModal = () => {
    openModal(ModalNamesEnum.MoveJobsToWSModal, {
      jobIds: checkedJobs,
      onSuccess: () => {
        reload();
        closeModal();
      },
    });
  };

  const showRemoveJobsModal = () => {
    openModal(ModalNamesEnum.DeleteJobsModal, {
      jobIds: checkedJobs,
      onSuccess: () => {
        reload();
        closeModal();
      },
    });
  };

  if (!jobs) {
    return null;
  }

  return (
    <>
      <Header
        setSearch={setSearch}
        search={search}
        buttonLabel={jobs.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={jobs.data?.length ? () => exportCSV() : undefined}
        leftSideContent={headerLeftSideContent}
        onFilterClick={(e) => setAnchorEl(e.currentTarget)}
        filterCounter={dateFilter.from || dateFilter.to ? 1 : 0}
      />
      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <div className="flex gap-3 items-center">
          <StatusFilters statusFilters={statusFilters} setStatusFilters={setStatusFilters} />
          {checkedJobs.length > 0 && (
            <>
              <svg
                style={{ marginTop: '-8px' }}
                width="2"
                height="35"
                viewBox="0 0 2 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0V35" stroke="#D5DEF2" />
              </svg>
              <div className="selected-wrapper">
                <div className="selected-tag">{checkedJobs.length} selected</div>

                {organization?.permissions?.submitJobs ? (
                  <MoveButton title="Move to another Workspace" onClick={() => showMoveJobsToWSModal()}>
                    <MoveIcon />
                  </MoveButton>
                ) : null}
                {organization?.permissions?.editJobs ? (
                  <DeleteButton title="Remove" onClick={() => showRemoveJobsModal()}>
                    <TrashBin />
                  </DeleteButton>
                ) : null}
              </div>
            </>
          )}
        </div>

        <JobsTable
          jobs={jobs}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderType={orderType}
          setOrderType={setOrderType}
          reload={reload}
          search={search}
          setSearch={setSearch}
          checkedJobs={checkedJobs}
          setCheckedJobs={setCheckedJobs}
        />
      </Wrapper>

      <WSUpdateReceiver jobs={jobs} reload={reload} />
      <FilterPopover />
    </>
  );
};
