import styled from 'styled-components';

export const WorkspaceName = styled.span`
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  flex: 0 0 auto;
  margin: -2px 0 0;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
