import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { AddBlue } from '@components/icons/AddBlue';

import { WorkspaceCard } from './components/WorkspaceCard';
import { TWorkspace } from 'app/types/entities/TWorkspace';

export const Wrapper = styled.div`
  margin: 20px 0 0;

  & > .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const PlusOne = styled.div`
  width: 72px;
  height: 72px;
  border: 1px dashed #cadcf8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;

    path {
      stroke: #cadcf8;
      fill: transparent;
    }
  }

  &:hover {
    background: #f1f5fb;
  }
`;

export type TProps = {
  workspaces: TWorkspace[];
};

export function WorkspacesCards({ workspaces }: TProps) {
  const navigate = useNavigate();
  const { me } = useAuthContext();
  const { openModal } = useModalContext();

  const handleWorkspaceClick = (event: React.MouseEvent, workspace: TWorkspace) => {
    const organization = me.organizations.find((o) => o.id == workspace.organizationId);
    event.preventDefault();
    if (organization) {
      navigate(routes.jobsList.make(organization?.slug ?? '', workspace.slug));
    }
  };

  const handleCreateWorkspace = () => {
    openModal(ModalNamesEnum.NewWorkspaceModal);
  };

  return (
    <Wrapper>
      <ul role="list" className="list">
        {workspaces.map((workspace, idx) => {
          const organization = me.organizations.find((o) => o.id == workspace.organizationId);
          return (
            <WorkspaceCard
              key={idx}
              workspace={workspace}
              organization={organization}
              handleWorkspaceClick={handleWorkspaceClick}
            />
          );
        })}
        <li key="create-new" className="flex">
          <PlusOne onClick={handleCreateWorkspace}>
            <AddBlue className="" onClick={handleCreateWorkspace} />
          </PlusOne>
        </li>
      </ul>
    </Wrapper>
  );
}
