import { StyledIconLinkContent } from './styles/StyledIconLinkContent';
import { TProps } from './types/TProps';

export const LinkContent = ({ text, Icon, iconOffset, active = false }: TProps) => {
  return (
    <StyledIconLinkContent iconOffset={iconOffset}>
      <Icon color={active ? '#40608f' : '#858DBD'} />
      {text}
    </StyledIconLinkContent>
  );
};
