import styled from 'styled-components';

type TeamsCellProps = {
  color?: string;
};

export const TeamsCell = styled.div<TeamsCellProps>`
  /* height: 22px; */
  padding: 6px;
  background-color: ${(props) => props.color || 'white'};
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 90%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;

  svg {
    cursor: pointer;

    path {
      stroke: #ffffff;
    }
  }
`;
