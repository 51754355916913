import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';

import { AdminCard } from './components/AdminCard';

import { ChevronLeftIcon } from '../../../../icons/ChevronLeft';

const BackToOrganization = styled(RouterLink)`
  color: #858dbd;
  display: flex;
  gap: 11px;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  padding: 15px 11px 20px;

  &:hover {
    text-decoration: none;
  }

  svg {
    padding: 0;
    height: 18px;
  }
`;

const UserPaymentWrapper = styled('div')`
  padding: 0 0 15px;
`;

export const WelcomeAdmin = () => {
  const { organization } = useAuthContext();
  return (
    <div className="w-full">
      <div className="border-geyser border-t w-11/12 m-auto h-px pb-2" />
      <BackToOrganization to={routes.dashboard.make(organization?.slug ?? '')}>
        <ChevronLeftIcon />
        Back to the Organization
      </BackToOrganization>

      <UserPaymentWrapper>
        <AdminCard />
      </UserPaymentWrapper>
    </div>
  );
};
