import styled from 'styled-components';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { Button } from '@components/form-elements/buttons/Button';

const Content = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);
  align-items: center;
  justify-content: center;
  display: flex;

  .content {
    width: 360px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  button {
    width: 155px;
    margin: 20px 0 0;
  }
`;

export const NoOrgsPage = () => {
  const { openModal } = useModalContext();

  const openCreateOrganizationModal = () => {
    openModal(ModalNamesEnum.NewOrganizationModal);
  };

  return (
    <Content>
      <div className="content">
        It looks like you {"don't"} belong to any organization.
        <br />
        Create your own organization to continue.
        <Button type="button" onClick={() => openCreateOrganizationModal()}>
          Create Organization
        </Button>
      </div>
    </Content>
  );
};
