import styled from 'styled-components';

export const MainContent = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }

  .add-blue {
    width: 16px;
    height: 16px;
    flex: 0 0 auto;
    cursor: pointer;
    stroke: #d5def2;
    :hover {
      stroke: #40608f;
    }
  }

  .edit-members {
    margin: 0 0 0 5px;
    :hover {
      svg {
        path {
          fill: #40608f;
        }
      }
    }
  }
`;
