import { useState } from 'react';
import styled from 'styled-components';

import { DatePicker } from '@components/form-elements/DatePicker';
import { Button } from '@components/form-elements/buttons/Button';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';

const Wrapper = styled.div`
  padding: 15px 0;

  .label {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    padding: 0 0 15px;
    color: #858dbd;
  }

  input {
    height: 30px;
    width: 150px;
    background: #ffffff;
  }
`;

const SuggestionButtonsWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const BottomButton = styled(Button)`
  width: 54px;
`;

const firstDayOfCurrentWeek = () => {
  const dayOfWeek = new Date().getDay();
  const firstDayOfWeek = new Date();
  const diff = dayOfWeek >= 0 ? dayOfWeek : 6 - dayOfWeek;
  firstDayOfWeek.setDate(new Date().getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);
  return firstDayOfWeek;
};

const firstDayOfCurrentMonth = () => {
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDate() + 1);
  firstDayOfMonth.setHours(0, 0, 0, 0);
  return firstDayOfMonth;
};

const startOfCurrentDay = () => {
  const currentDay = new Date();
  currentDay.setHours(0, 0, 0, 0);
  return currentDay;
};

const getSelectedDateFilterValue = (jobsFilter: TJobsFilter) => {
  let to = jobsFilter.to || 0;
  let from = jobsFilter.from || 0;
  const today = startOfCurrentDay().getTime();
  const thisWeekStart = firstDayOfCurrentWeek().getTime();
  const thisMonthStart = firstDayOfCurrentMonth().getTime();
  if (from) {
    from = parseInt(new Date(from).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (to) {
    to = parseInt(new Date(to).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (from === today && to === today) {
    return 'today';
  }
  if (from === thisWeekStart && to === today) {
    return 'week';
  }
  if (from === thisMonthStart && to === today) {
    return 'month';
  }
  return '';
};

type TProps = {
  filter: any;
  setFilter: (arg: any) => void;
};

export const Filter = ({ filter, setFilter }: TProps) => {
  const [tempDateFilterSelected, setTempDateFilterSelected] = useState(getSelectedDateFilterValue(filter));

  return (
    <Wrapper>
      <div className="label">Pick the period</div>
      <div className="flex items-center gap-4">
        <div className="datepicker-wrapper">
          <DatePicker
            label="From"
            minDate={new Date(0)}
            maxDate={filter.to || new Date()}
            value={filter.from}
            onChange={(date: Date | null) => setFilter({ ...filter, from: date })}
          />
        </div>

        <div className="datepicker-wrapper">
          <DatePicker
            label="To"
            minDate={filter.from}
            maxDate={new Date()}
            value={filter.to}
            onChange={(date: Date | null) =>
              setFilter((v: TJobsFilter) => {
                const newVal: TJobsFilter = Object.assign({}, v);
                let newDate = undefined;
                if (date) {
                  const dateObj = new Date(date);
                  dateObj.setHours(23);
                  dateObj.setMinutes(59);
                  newDate = dateObj;
                }
                newVal.to = newDate;
                return newVal;
              })
            }
          />
        </div>

        <SuggestionButtonsWrapper>
          <Button
            variant="outlinedBold"
            size="small"
            disabled={tempDateFilterSelected === 'today'}
            onClick={() => {
              setFilter((v: TJobsFilter) => {
                const newVal = Object.assign({}, v);
                newVal.from = startOfCurrentDay();
                newVal.to = new Date();
                return newVal;
              });
              setTempDateFilterSelected('today');
            }}
          >
            Today
          </Button>

          <Button
            variant="outlinedBold"
            size="small"
            disabled={tempDateFilterSelected === 'week'}
            onClick={() => {
              setFilter((v: TJobsFilter) => {
                const newVal = Object.assign({}, v);
                newVal.from = firstDayOfCurrentWeek();
                newVal.to = new Date();
                return newVal;
              });
              setTempDateFilterSelected('week');
            }}
          >
            This week
          </Button>

          <Button
            variant="outlinedBold"
            size="small"
            disabled={tempDateFilterSelected === 'month'}
            onClick={() => {
              setFilter((v: TJobsFilter) => {
                const newVal = Object.assign({}, v);
                newVal.from = firstDayOfCurrentMonth();
                newVal.to = new Date();
                return newVal;
              });
              setTempDateFilterSelected('month');
            }}
          >
            This month
          </Button>
        </SuggestionButtonsWrapper>

        <BottomButton
          variant="outlined"
          onClick={() => {
            setFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = undefined;
              newVal.to = undefined;
              return newVal;
            });
            setTempDateFilterSelected('');
            setFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = undefined;
              newVal.to = undefined;
              return newVal;
            });
          }}
        >
          Reset
        </BottomButton>
      </div>
    </Wrapper>
  );
};
