import { Wrapper } from './styles/Wrapper';

export const Footer = () => {
  return (
    <Wrapper>
      <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
        Terms of Service
      </a>{' '}
      |{' '}
      <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      <p>© 2022 VoiceScript Inc. All Rights Reserved</p>
    </Wrapper>
  );
};
