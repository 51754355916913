import { createContext, useContext, useState } from 'react';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { useAPI } from '@hooks/useAPI';
import { useNavigate } from 'react-router';

import { AuthService } from 'app/API';
import { useAuthContext } from '@providers/AuthProvider';
import { useInitContext } from '@providers/InitProvider';

type TState = {
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  email: string;
  setEmail: (v: string) => void;
  password: string;
  setPassword: (v: string) => void;
  error: string;
  login: (evt: React.FormEvent<HTMLFormElement>) => void;
};

const PageContext = createContext<TState>({
  isLoading: false,
  setIsLoading: () => null,
  email: '',
  setEmail: () => null,
  password: '',
  setPassword: () => null,
  error: '',
  login: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const navigate = useNavigate();
  const { authenticate } = useInitContext();
  const { updateMe } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const urlSearchParams = new URLSearchParams(location.search);

  const login = async (evt: React.FormEvent<HTMLFormElement>) => {
    setError('');
    setIsLoading(true);
    evt.preventDefault();
    const product = urlSearchParams.get('product');
    const data = await call(
      AuthService.login({
        requestBody: {
          email,
          password,
          product: product || '',
        },
      }),
      { dontCatchErrors: true },
    );
    if (!data) {
      setError('Something went wrong');
      setIsLoading(false);
      return;
    }
    if (data.code || data.message) {
      setError(data.code || data.message);
      setIsLoading(false);
      return;
    }
    authenticate();
    updateMe();
    const goto = urlSearchParams.get('goto');
    if (goto) {
      navigate(goto);
    }
  };

  return (
    <PageContext.Provider
      value={{
        isLoading,
        setIsLoading,
        email,
        setEmail,
        password,
        setPassword,
        error,
        login,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
