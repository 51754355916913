import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const BalanceLink = styled(RouterLink)`
  text-align: left;
  color: #858dbd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
`;
