import { client } from 'app/http';

export const checkSlugIsAvailable = async (
  orgSlug: string,
  slug: string,
  id: string,
): Promise<{ available: boolean }> => {
  if (!id || !slug || !orgSlug) {
    return { available: false };
  }
  const { data } = await client.get(`/repositories/is-available/${orgSlug}/${id}/${slug}`);
  return data;
};
