import styled from 'styled-components';
import { Button } from '@components/form-elements/buttons/Button';

export const StyledButton = styled(Button)`
  width: 140px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
  margin-right: 12px;
`;
