export const getDownloadDesktopAppLink = () => {
  const location = window.location.hostname;

  if (location === 'www.autoscript.ai') {
    return 'https://as-desktop.s3.amazonaws.com/AutoscriptDesktop-win-Setup.exe';
  }

  if (location === 'staging.autoscript.ai') {
    return 'https://as-desktop-staging.s3.amazonaws.com/AutoscriptDesktopStaging-win-Setup.exe';
  }

  return 'https://as-desktop-demo.s3.amazonaws.com/AutoscriptDesktopDemo-win-Setup.exe';
};
