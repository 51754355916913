import styled from 'styled-components';

export const Wrapper = styled.div`
  display: contents;
  .root {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
    width: 100%;
    max-width: 66%;
  }
  .setNewPassword {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #40608f;
    margin: 0 0 30px;
  }
  .yourNewPassword {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #858dbd;
    text-align: center;
    margin: 0 auto 30px;
    max-width: 225px;
  }
  .form {
    text-align: initial;
  }
  .content {
    margin: auto;
  }
  .dontHaveAnAccount {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #40608f;
    text-align: center;
    margin: 0 0 30px;
  }
  .signUp {
    color: #40608f;
    cursor: pointer;
    text-decoration: underline;
  }
  .formError {
    margin-bottom: 30px;
  }
  .margin30 {
    margin-bottom: 30px;
  }
  .positiveIcon {
    margin: 0 0 30px;
  }
  .checkEmailIcon {
    margin: 0 0 30px;
  }
  .completePasswordReset {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #40608f;
    margin: 0 0 30px;
  }
  .completeYourPasswordHasBeen {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #858dbd;
    margin: 0 0 30px;
  }
`;
