import { createContext, useContext, useEffect, useState } from 'react';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { useAPI } from '@hooks/useAPI';

import { AuthService } from 'app/API';

type TState = {
  isComplete: boolean;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  email: string;
  setEmail: (v: string) => void;
  password: string;
  setPassword: (v: string) => void;
  confirmation: string;
  setConfirmation: (v: string) => void;
  code: string;
  setCode: (v: string) => void;
  error: string;
  setError: (v: string) => void;
  reset: () => void;
};

const PageContext = createContext<TState>({
  isComplete: false,
  isLoading: false,
  setIsLoading: () => null,
  email: '',
  setEmail: () => null,
  password: '',
  setPassword: () => null,
  confirmation: '',
  setConfirmation: () => null,
  code: '',
  setCode: () => null,
  error: '',
  setError: () => null,
  reset: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramsCode = params.get('code');
    const paramsEmail = params.get('email');
    setCode(paramsCode ?? '');
    setEmail(paramsEmail ?? '');
  }, [location.search]);

  const reset = async () => {
    setIsLoading(true);
    const response = await call(AuthService.passwd({ requestBody: { password, email, code } }));
    if (response.data?.code && response.data?.code === 'ExpiredCodeException') {
      setError('ExpiredCodeException');
      setIsLoading(false);
      setIsComplete(true);
      return;
    }
    setError('');
    setIsLoading(false);
    setIsComplete(true);
  };

  return (
    <PageContext.Provider
      value={{
        isComplete,
        isLoading,
        setIsLoading,
        email,
        setEmail,
        password,
        setPassword,
        confirmation,
        setConfirmation,
        code,
        setCode,
        error,
        setError,
        reset,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
