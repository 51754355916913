import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const CheckGuide = styled(RouterLink)`
  display: flex;
  margin: 10px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;
