import { client } from 'app/http';
import { TSubscription } from 'app/types/entities/TSubscription';
import { TLicense } from 'app/types/entities/TLicense';

export type TGetSubscriptionDetailsResponse = (TSubscription & { licenses: TLicense[] }) | null;

export const getSubscriptionDetails = async (
  baid: string,
  aOneId: string,
): Promise<TGetSubscriptionDetailsResponse> => {
  if (!baid) {
    console.error('[getSubscriptionDetails] baid was not provided');
    return null;
  }
  const { data } = await client.get(`/billing-account/${baid}/subscription/${aOneId}`);
  return data;
};
