import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { formatPrice } from '@helpers/formatPrice';
import { EPermission } from 'app/types/enums/EPermission';

import { Header } from '@components/Header';
import { Add as AddIcon } from '@components/icons/Add';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { DeadlineCalendar } from './DeadlineCalendar';
import { DailyActivity } from './Daily';
import { PerformanceReview } from './Performance';

import { useDashboard } from './hooks/useDashboard';

import { StyledButton } from './styles/StyledButton';
import { Wrapper } from '@pages/User/DashboardPage/styles/Wrapper';
import { FlexWrapper } from '@pages/User/DashboardPage/styles/FlexWrapper';
import { YearEndSpecial } from '@pages/User/DashboardPage/components/YearEndSpecial';

// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// const getPrevMonthName = () => {
//   const now = new Date();
//   const prevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 15).getMonth();
//   return months[prevMonth];
// };

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { organization, workspace, me } = useAuthContext();

  const { dashboardInfo, isLoading } = useDashboard();

  if (!me.organizations.length) {
    navigate(routes.accountPage.make());
    return null;
  }

  const handleAddJob = () => {
    navigate(routes.newJob.make(organization?.slug ?? '', workspace?.slug ?? ''));
  };

  const handleAddCase = () => {
    navigate(
      {
        pathname: routes.newRepository.make(organization?.slug ?? ''),
      },
      {
        state: {
          from: routes.dashboard.make(organization?.slug ?? ''),
        },
      },
    );
  };

  const headerLeftSideContent = [];
  if (organization?.permissions?.submitJobs) {
    headerLeftSideContent.push(
      <div className="flex flex-row">
        <StyledButton key="button" data-testid="create_job_header_button" size="small" onClick={handleAddJob}>
          <AddIcon height="12px" width="12px" />
          Create New Job
        </StyledButton>
        <Restricted to={EPermission.editRepositories}>
          <StyledButton
            key="button"
            data-testid="header_case_button"
            size="small"
            variant="outlined"
            onClick={handleAddCase}
          >
            <AddIcon height="12px" width="12px" stroke="#2451ae" />
            Create New Case
          </StyledButton>
        </Restricted>
      </div>,
    );
  }

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>Dashboard - AutoScript</title>
        </Helmet>
        <Header title="Dashboard" leftSideContent={headerLeftSideContent} />

        <LinearProgressLoader active={isLoading} />
        <Wrapper />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - AutoScript</title>
      </Helmet>
      <Header title="Dashboard" globalSearch={true} leftSideContent={headerLeftSideContent} />

      <Wrapper>
        <YearEndSpecial />
        <FlexWrapper>
          <div className="left-wrapper">
            <div className="line">
              <div className="card">
                <div className="title">Organization Members</div>
                <div className="subTitle">{dashboardInfo?.members?.count} Total</div>
                <div className="description">Active last 7 days</div>
                <div className="description">
                  {dashboardInfo?.members?.active7days}{' '}
                  {dashboardInfo?.members?.active7days === 1 ? 'member' : 'members'}
                </div>
              </div>
              <div className="card">
                <div className="title">Workspaces Activity</div>
                <div className="subTitle">{dashboardInfo?.workspaceActivity?.count} Total</div>
                <div className="two-columns-description">
                  <div className="left-side">
                    <div className="description">ASR Rate</div>
                    <div className="description">
                      {formatPrice(parseFloat(dashboardInfo?.workspaceActivity?.postProcessingASRRate))}/Hr
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="description">Billing</div>
                    <div className="description">{dashboardInfo?.workspaceActivity?.billingCard}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="review-activity-wrapper">
              <div className="card card-performance">
                <div className="superTitle">Performance Review</div>
                <PerformanceReview data={dashboardInfo?.performanceReview ?? {}} />
              </div>
              <div className="card card-daily">
                <div className="superTitle">Daily Active Members</div>
                <DailyActivity data={dashboardInfo?.dailyActivity ?? []} />
              </div>
            </div>
          </div>
          <div className="card card-deadline">
            <div className="superTitle">Deadline Calendar</div>
            <DeadlineCalendar calendar={dashboardInfo?.deadlineCalendar ?? []} />
          </div>
        </FlexWrapper>
      </Wrapper>
    </>
  );
};
