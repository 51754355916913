import styled from 'styled-components';

export const Wrapper = styled.p`
  padding: 6px;
  background: white;
  color: #858dbd;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  a {
    font-size: 12px;
    line-height: 14px;
  }

  svg {
    width: 12px;
    height: 12px;
    flex: 0 0 auto;
    cursor: pointer;
    margin: 0 0 0 5px;

    :hover {
      path {
        stroke: #40608f;
      }
    }
  }
`;
