export const TimeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0001 7.3501C11.6373 7.3501 11.3501 7.63725 11.3501 8.0001V11.9993C11.349 12.1115 11.3772 12.2221 11.4319 12.3202C11.4867 12.4185 11.5665 12.5007 11.663 12.5587L14.1618 14.0581C14.27 14.1253 14.3891 14.1501 14.5008 14.1501C14.6426 14.1494 14.7803 14.1026 14.8931 14.0166C15.0059 13.9306 15.0875 13.8102 15.1257 13.6737C15.1639 13.5371 15.1565 13.3918 15.1047 13.2598C15.0529 13.1278 14.9594 13.0163 14.8385 12.9422L12.6501 11.635V8.0001C12.6501 7.63725 12.363 7.3501 12.0001 7.3501Z"
      fill="#40608F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 4.8501C8.05726 4.8501 4.8501 8.05726 4.8501 12.0001C4.8501 15.9429 8.05726 19.1501 12.0001 19.1501C15.9429 19.1501 19.1501 15.9429 19.1501 12.0001C19.1501 8.05726 15.9429 4.8501 12.0001 4.8501ZM6.1501 12.0001C6.1501 8.77294 8.77294 6.1501 12.0001 6.1501C15.2273 6.1501 17.8501 8.77294 17.8501 12.0001C17.8501 15.2273 15.2273 17.8501 12.0001 17.8501C8.77294 17.8501 6.1501 15.2273 6.1501 12.0001Z"
      fill="#40608F"
    />
  </svg>
);
