import { client } from 'app/http';
import { TReceipt } from 'app/types/entities/TReceipt';

export const getTransactions = async (id: string): Promise<TReceipt[]> => {
  try {
    const { data } = await client.get(`billing-account/${id}/transactions`);
    return data;
  } catch (err) {
    console.log('getTransactions request failed:', err);
    return [];
  }
};
