import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';

import { DashboardPage } from '@pages/User/DashboardPage';
import { NewJobPage } from '@pages/User/NewJobPage';
import { JobDetailsPage } from '@pages/User/JobDetailsPage';
import { MembersPage } from '@pages/User/MembersPage';
import { BillingAccountDetailsPage } from '@pages/User/BillingAccountDetailsPage';
import { JobsReport } from '@pages/User/JobsReport';
import { UserJobsReport } from '@pages/User/UserJobsReport';
import { OrganizationsListPage } from '@pages/User/OrganizationsListPage';
import { AccessRequestPage } from '@pages/User/AccessRequestPage';
import { AccountPage } from '@pages/User/AccountPage';
import { OrganizationDetails } from '@pages/User/OrganizationDetailsPage';
import { RepositoryPage } from '@pages/User/RepositoryPage';
import { WorkspaceDetailsPage } from '@pages/User/WorkspaceDetailsPage';
import { UserDetailsPage } from '@pages/User/UserDetailsPage';
import { JobsListPage } from '@pages/User/JobsListPage';
import { TeamDetailsPage } from '@pages/User/TeamDetailsPage';
import { InvitationsPage } from '@pages/User/InvitationsPage';
import { ScriberDashboardPage } from '@pages/User/ScriberDashboardPage';
import { DesktopAppHelpPage } from '@pages/User/DesktopAppHelpPage';
import { RepositoriesListPage } from '@pages/User/RepositoriesListPage';
import { MemberReportsPage } from '@pages/User/MemberReportsPage';
import { AllJobsPage } from '@pages/User/AllJobs';
import { ASOneSubscriptionDetails } from '@pages/User/ASOneSubscriptionDetails';
import { TeamsPage } from '@pages/User/TeamsPage';

import { AdminWorkspacesListPage } from '@pages/Admin/WorkspacesListPage';
import { AdminOrganizationsListPage } from '@pages/Admin/OrganizationsListPage';
import { AdminUsersListPage } from '@pages/Admin/UsersListPage';
import { AdminUserDetailsPage } from '@pages/Admin/UserDetailsPage';
import { AdminOrganizationUsersListPage } from '@pages/Admin/OrganizationUsersListPage';
import { AdminOrganizationInvitationsListPage } from '@pages/Admin/OrganizationInvitationsListPage';
import { BillingAccountsPage } from '@pages/Admin/BillingAccountsPage';
import { AdminJobsReportPage } from '@pages/Admin/JobsReportPage';
import { AdminSSOProviderListPage } from '@pages/Admin/SSOProviders';
import { AdminTransactionCodesPage } from '@pages/Admin/TransactionCodesPage';
import { AdminJobDetailsPage } from '@pages/Admin/JobDetailsPage';
import { AdminASOneSubscriptionsPage } from '@pages/Admin/ASOneSubscriptions';
import { AdminTransactionsPage } from '@pages/Admin/TransactionsPage';
import { AdminSMSCountriesList } from '@pages/Admin/SMSCountries';
import { AdminSMSWhitelist } from '@pages/Admin/SMSWhitelist';
import { AdminAdminsListPage } from '@pages/Admin/AdminsListPage';
import { AdminDashboardPage } from '@pages/Admin/DashboardPage';
import { AdminSettingsPage } from '@pages/Admin/SettingsPage';
import { AdminOrganizationDetailsPage } from '@pages/Admin/OrganizationDetailsPage';
import { AdminBillingAccountDetailsPage } from '@pages/Admin/BillingAccountDetailsPage';
import { AdminWorkspaceDetailsPage } from '@pages/Admin/WorkspaceDetailsPage';
import { AdminASOneSubscriptionDetails } from '@pages/Admin/ASOneSubscriptionDetails';
import { AdminDiffPage } from '@pages/Admin/DiffPage';
import { AdminUtteranceConfidencePage } from '@pages/Admin/UtteranceConfidencePage';
import { AdminTeamDetailsPage } from '@pages/Admin/TeamDetailsPage';
import { AdminRepositoryPage } from '@pages/Admin/RepositoryPage';
import { AdminCanvasCoursesPage } from '@pages/Admin/CanvasCoursesPage';
import { AdminCanvasCourseStudentsPage } from '@pages/Admin/CanvasCourseStudentsPage';

import { Sidebar } from './components/Sidebar';
import { Wrapper } from './styles/Wrapper';
import { NotificationsPage } from '@pages/User/NotificationsPage';
import { NotificationSettingsPage } from '@pages/User/NotificationSettingsPage';

export const Routes = () => {
  const { me, organization } = useAuthContext();

  if (!me.id) {
    return null;
  }

  if (!me.organizations?.length) {
    return (
      <>
        <Wrapper>
          <Switch>
            <Route path={routes.accountPage.pattern} element={<AccountPage />} />

            <Route path={routes.desktopAppHelp.pattern} element={<DesktopAppHelpPage />} />

            <Route path={routes.organizationsList.pattern} element={<OrganizationsListPage />} />

            <Route path="*" element={<OrganizationsListPage />} />
          </Switch>
        </Wrapper>
        <Sidebar />
      </>
    );
  }

  const isVsAdmin = me?.roles.ROLE_VS_ADMIN;
  const isSuperAdmin = me?.roles?.ROLE_SUPER_ADMIN;
  const isOrgAdmin = organization?.permissions?.manageOrganization;

  // const workspaceViewReports = (workspace?.Teams ?? []).find((team) => team.viewReports);
  // const shouldShowReports = !!workspaceViewReports;
  const shouldShowReports = !!organization?.permissions?.viewReports;
  const shouldShowAllJobs =
    !!organization?.permissions?.manageWorkspaces ||
    !!organization?.permissions?.manageOrganization ||
    !!organization?.permissions?.owner;
  const shouldShowUsers = !!organization?.permissions?.manageMembers;

  const defaultOrg = organization?.id ? organization : me.organizations[0];

  let adminRoutes = <></>;
  if (isVsAdmin) {
    adminRoutes = (
      <>
        <Route path={routes.adminDiff.pattern} element={<AdminDiffPage />} />
        <Route path={routes.adminJobUtterances.pattern} element={<AdminUtteranceConfidencePage />} />
        <Route path={routes.adminDashboard.pattern} element={<AdminDashboardPage />} />
        {isSuperAdmin ? <Route path={routes.adminAdminsList.pattern} element={<AdminAdminsListPage />} /> : null}
        <Route path={routes.adminCanvasCoursesList.pattern} element={<AdminCanvasCoursesPage />} />
        <Route path={routes.adminCanvasCourseStudentsPage.pattern} element={<AdminCanvasCourseStudentsPage />} />
        <Route path={routes.adminOrganizationsList.pattern} element={<AdminOrganizationsListPage />} />
        <Route path={routes.adminOrganizationDetails.pattern} element={<AdminOrganizationDetailsPage />} />
        <Route path={routes.adminWorkspacesList.pattern} element={<AdminWorkspacesListPage />} />
        <Route path={routes.adminWorkspaceDetails.pattern} element={<AdminWorkspaceDetailsPage />} />
        <Route path={routes.adminTeamDetails.pattern} element={<AdminTeamDetailsPage />} />
        <Route path={routes.adminRepositoryDetails.pattern} element={<AdminRepositoryPage />} />
        <Route path={routes.adminSettings.pattern} element={<AdminSettingsPage />} />
        <Route path={routes.adminUsersList.pattern} element={<AdminUsersListPage />} />
        <Route path={routes.adminBillingAccountsList.pattern} element={<BillingAccountsPage />} />
        <Route path={routes.adminJobsReport.pattern} element={<AdminJobsReportPage />} />
        <Route path={routes.adminOrganizationUsersList.pattern} element={<AdminOrganizationUsersListPage />} />
        <Route
          path={routes.adminOrganizationInvitationsList.pattern}
          element={<AdminOrganizationInvitationsListPage />}
        />
        <Route path={routes.adminUserDetails.pattern} element={<AdminUserDetailsPage />} />
        <Route path={routes.adminSSOProvidersList.pattern} element={<AdminSSOProviderListPage />} />
        <Route path={routes.adminSubscriptions.pattern} element={<AdminASOneSubscriptionsPage />} />
        <Route path={routes.adminTransactions.pattern} element={<AdminTransactionsPage />} />
        <Route path={routes.adminSMSCountries.pattern} element={<AdminSMSCountriesList />} />
        <Route path={routes.adminSMSWhitelist.pattern} element={<AdminSMSWhitelist />} />
        <Route path={routes.adminSMSCountries.pattern} element={<AdminSMSCountriesList />} />
        <Route path={routes.adminSMSWhitelist.pattern} element={<AdminSMSWhitelist />} />
        <Route path={routes.adminTransactionCodesList.pattern} element={<AdminTransactionCodesPage />} />
        <Route path={routes.adminSMSCountries.pattern} element={<AdminSMSCountriesList />} />
        <Route path={routes.adminSMSWhitelist.pattern} element={<AdminSMSWhitelist />} />
        <Route path={routes.adminBillingAccountDetails.pattern} element={<AdminBillingAccountDetailsPage />} />
        <Route
          path={routes.adminAutoScriptOneSubscriptionDetails.pattern}
          element={<AdminASOneSubscriptionDetails />}
        />
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <Switch>
          {adminRoutes}
          <Route path={routes.notifications.pattern} element={<NotificationsPage />} />
          <Route path={routes.notificationSettings.pattern} element={<NotificationSettingsPage />} />

          {shouldShowAllJobs ? <Route path={routes.allJobs.pattern} element={<AllJobsPage />} /> : null}

          {shouldShowReports ? (
            <>
              <Route path={routes.jobsReport.pattern} element={<JobsReport />} />
              <Route path={routes.userJobsReport.pattern} element={<UserJobsReport />} />
            </>
          ) : null}

          {shouldShowUsers ? (
            <>
              <Route path={routes.organizationMembers.pattern} element={<MembersPage />} />
              <Route path={routes.organizationTeams.pattern} element={<TeamsPage />} />
              <Route path={routes.invitations.pattern} element={<InvitationsPage />} />
              {shouldShowReports ? (
                <>
                  <Route path={routes.organizationMemberReports.pattern} element={<MemberReportsPage />} />
                </>
              ) : null}
            </>
          ) : null}

          <Route path={routes.accountPage.pattern} element={<AccountPage />} />

          <Route path={routes.desktopAppHelp.pattern} element={<DesktopAppHelpPage />} />

          <Route path={routes.userDetails.pattern} element={<UserDetailsPage />} />

          <Route path={routes.dashboard.pattern} element={isOrgAdmin ? <DashboardPage /> : <ScriberDashboardPage />} />

          <Route path={routes.jobsList.pattern} element={<JobsListPage />} />

          <Route path={routes.jobDetails.pattern} element={<JobDetailsPage />} />

          <Route path={routes.adminJobDetails.pattern} element={<AdminJobDetailsPage />} />

          <Route path={routes.workspaceDetails.pattern} element={<WorkspaceDetailsPage />} />

          <Route path={routes.newJob.pattern} element={<NewJobPage />} />

          <Route path={routes.jobEdit.pattern} element={<NewJobPage />} />

          <Route path={routes.newRepository.pattern} element={<RepositoryPage />} />

          <Route path={routes.repositoryDetails.pattern} element={<RepositoryPage />} />

          <Route path={routes.billingAccount.pattern} element={<BillingAccountDetailsPage />} />

          <Route path={routes.autoScriptOneSubscriptionDetails.pattern} element={<ASOneSubscriptionDetails />} />

          <Route path={routes.requestAccess.pattern} element={<AccessRequestPage />} />

          <Route path={routes.organizationsList.pattern} element={<OrganizationsListPage />} />

          <Route path={routes.teamDetails.pattern} element={<TeamDetailsPage />} />

          <Route path={routes.organizationDetails.pattern} element={<OrganizationDetails />} />

          <Route path={routes.repositoriesList.pattern} element={<RepositoriesListPage />} />

          <Route path="*" element={<Navigate to={routes.dashboard.make(defaultOrg?.slug)} replace />} />
        </Switch>
      </Wrapper>
      <Sidebar />
    </>
  );
};
