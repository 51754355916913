import { client } from 'app/http';
import { TRole } from 'app/types/entities/TRole';

export const getTeams = async (orgId: string): Promise<TRole[]> => {
  try {
    const response = await client.get(`/organizations/${orgId}/teams`);
    return response.data;
  } catch (err) {
    console.log('getTeams request failed:', err);
    return [];
  }
};
