import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1 {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
  }
`;
