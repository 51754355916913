import React, { useState } from 'react';
import styled from 'styled-components';

import ErrorIcon from './icons/error.svg';
import { ClickAway } from '@components/ClickAway';

const StyledBox = styled.span`
  text-align: left;
  display: inline-flex;
  width: 100%;
  position: relative;

  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  .input {
    display: inline-flex;
    color: #858dbd;
    background: #f8fafb;
    border-radius: 5px;
    border: none;
    flex: 1 1 auto;
    max-width: 100%;
    height: 40px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    align-items: center;
    cursor: pointer;

    .placeholder,
    .value {
      color: #858dbd;
      opacity: 1;
      font-weight: 500;
      display: block;
      width: 55px;
    }

    &:hover,
    &.focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(202, 220, 248, 1);
    }

    &:focus {
      background: #ffffff;
      color: #40608f;
    }

    &.disabled {
      background: #f1f5fb !important;
      color: #b4b9d9 !important;
      outline: none !important;
    }

    &.with-icon {
      padding-right: 45px;
    }

    &.error {
      outline: none;
      box-shadow: 0 0 0 1px #ff2f2f !important;
    }
  }

  .icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    z-index: 0;
  }

  .modal {
    padding: 10px;
    position: absolute;
    top: 40px;
    background: #fff;
    box-shadow: #858dbd4d 0 3px 8px;
    z-index: 999;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border: 8px solid transparent;
      border-bottom-color: #fff;
      left: 15px;
      top: -16px;
      z-index: -1;
    }

    .inputs {
      display: flex;

      .hour,
      .minute,
      .daytime {
        cursor: pointer;
        padding: 5px;
        margin: 3px;
        display: block;

        &.selected {
          background: #d5def2;
          border-radius: 3px;
        }
      }

      .hours {
        display: block;
        border-right: 1px solid #858dbd4d;
        text-align: center;
      }

      .minutes {
        display: block;
        border-right: 1px solid #858dbd4d;
        text-align: center;
      }

      .daytimes {
        display: block;
        text-align: center;
      }
    }
  }
`;

type TProps = {
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  label: string;
  onChange: (value: string) => void;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
};

export const TimeInput = ({
  icon: Icon,
  label,
  onChange,
  value = '',
  required = false,
  disabled = false,
  error = false,
  className = 'form-time-input',
}: TProps) => {
  const safeValue = value ?? '12:00';
  const [open, setOpen] = useState(false);
  const title = `${label}${required ? ' *' : ''}`;

  const hours = safeValue.split(':')[0] ?? '12';
  const valueMinutes = safeValue.split(':')[1] ?? '00';
  /*
   0-11 = AM
   12-23 = PM
  */
  const valueDayTime = parseInt(hours, 10) > 11 ? 'PM' : 'AM';
  let valueHours = hours;
  if (parseInt(hours, 10) === 0) {
    valueHours = '12';
  } else if (parseInt(hours, 10) < 13) {
    valueHours = hours;
  } else {
    valueHours = (parseInt(hours, 10) - 12).toString();
    if (valueHours.length === 1) {
      valueHours = `0${valueHours}`;
    }
  }

  /*
   From 0:00 (midnight) to 0:59, add 12 hours and use am.
   From 1:00 to 11:59, just add am after the time.
   From 12:00 to 12:59, just add pm after the time.
   From 13:00 to 0:00, subtract 12 hours and use pm.
  */

  const hoursArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const minutesArray = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  const dayTimesArray = ['AM', 'PM'];

  const changeHours = (hours: string) => {
    const initialMinutes = safeValue.split(':')[1] ?? '00';
    /*
     From 0:00 (midnight) to 0:59, add 12 hours and use am.
     From 1:00 to 11:59, just add am after the time.
     From 12:00 to 12:59, just add pm after the time.
     From 13:00 to 0:00, subtract 12 hours and use pm.
    */
    let value = hours;
    if (hours === '12' && valueDayTime === 'AM') {
      value = '00';
    } else if (hours === '12' && valueDayTime === 'PM') {
      value = '12';
    } else if (valueDayTime === 'PM') {
      value = `${parseInt(hours, 10) + 12}`;
    }
    onChange(`${value}:${initialMinutes}`);
  };

  const changeMinutes = (minutes: string) => {
    const initialHours = safeValue.split(':')[0] ?? '00';
    onChange(`${initialHours}:${minutes}`);
  };

  const changeDayTime = (daytime: string) => {
    const initialMinutes = safeValue.split(':')[1] ?? '00';
    /*
     From 0:00 (midnight) to 0:59, add 12 hours and use am.
     From 1:00 to 11:59, just add am after the time.
     From 12:00 to 12:59, just add pm after the time.
     From 13:00 to 0:00, subtract 12 hours and use pm.
    */
    let value = valueHours;
    if (valueHours === '12' && daytime === 'AM') {
      value = '00';
    } else if (valueHours === '12' && daytime === 'PM') {
      value = '12';
    } else if (daytime === 'PM') {
      value = `${parseInt(valueHours, 10) + 12}`;
    }
    onChange(`${value}:${initialMinutes}`);
  };

  const renderIcon = () => {
    if (!Icon) {
      return null;
    }
    if (error) {
      return <ErrorIcon className="icon" />;
    }
    return <Icon className="icon" />;
  };

  const openModal = () => {
    if (!disabled && !open) {
      setOpen(true);
    }
  };

  const closeModal = () => {
    if (!disabled && open) {
      setOpen(false);
    }
  };

  const renderModal = () => {
    if (!open) {
      return null;
    }
    return (
      <ClickAway onClickAway={closeModal}>
        <span className="modal">
          <span className="inputs">
            <span className="hours">
              {hoursArray.map((hour) => (
                <span
                  className={`hour ${valueHours === hour ? 'selected' : ''}`}
                  key={`hour-${hour}`}
                  onClick={() => changeHours(hour)}
                >
                  {hour}
                </span>
              ))}
            </span>
            <span className="minutes">
              {minutesArray.map((minute) => (
                <span
                  className={`minute ${valueMinutes === minute ? 'selected' : ''}`}
                  key={`minute-${minute}`}
                  onClick={() => changeMinutes(minute)}
                >
                  {minute}
                </span>
              ))}
            </span>
            <span className="daytimes">
              {dayTimesArray.map((daytime) => (
                <span
                  className={`daytime ${valueDayTime === daytime ? 'selected' : ''}`}
                  key={`daytime-${daytime}`}
                  onClick={() => changeDayTime(daytime)}
                >
                  {daytime}
                </span>
              ))}
            </span>
          </span>
        </span>
      </ClickAway>
    );
  };

  return (
    <StyledBox className={className}>
      <span
        className={`input ${Icon || error ? 'with-icon' : ''} ${error ? 'error' : ''} ${disabled ? 'disabled' : ''} ${
          open ? 'focus' : ''
        }`}
        onClick={openModal}
      >
        {!value ? (
          <span className="placeholder">{title}</span>
        ) : (
          <span className="value">
            {valueHours}:{valueMinutes} {valueDayTime}
          </span>
        )}
      </span>
      {renderIcon()}
      {renderModal()}
    </StyledBox>
  );
};

export const SmallWhiteTimeInput = styled(TimeInput)`
  .input {
    background: #ffffff;
    padding: 0 10px 0 44px;
    height: 30px;
  }
`;
