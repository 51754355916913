import { useState } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import LeftIcon from './icons/left.svg';
import RightIcon from './icons/right.svg';

import { TProps } from './types/TProps';
import { monthsList } from './helpers/monthsList';

import { Wrapper } from './styles/Wrapper';
import { PickerHeader } from './styles/PickerHeader';
import { CurrentMonth } from './styles/CurrentMonth';
import { Icon } from './components/Icon';

export const TextDatePicker = ({
  label,
  onChange,
  onIconClick,
  value = null,
  minDate = new Date(0),
  maxDate = new Date(),
  required = false,
  disabled = false,
  error = false,
  showTimeSelect = false,
  className = 'form-input',
  dateFormat = 'MM/dd/yyyy',
}: TProps) => {
  const defaultTitle = `${label}${required ? ' *' : ''}`;
  const [title, setTitle] = useState(defaultTitle);

  let initialValue = value;
  if (typeof value === 'string' && value.includes('T')) {
    initialValue = value.split('T')[0];
  }
  const selectedValue = value ? new Date(`${initialValue}T00:00:00`) : null;

  const onFocus = () => {
    setTitle('mm/dd/yyyy');
  };

  const onBlur = () => {
    setTitle(defaultTitle);
  };

  const onInnerChange = (date: Date | null) => {
    if (!date) {
      onChange(null);
      return;
    }
    const yyyy = date.getFullYear();
    const mm: string = date.getMonth() > 8 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`;
    const dd: string = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`;
    onChange(`${yyyy}-${mm}-${dd}T00:00:00`);
  };

  return (
    <Wrapper className={className}>
      <Icon onIconClick={onIconClick} error={error} />

      <ReactDatePicker
        dateFormat={dateFormat}
        placeholderText={title}
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => (
          <PickerHeader>
            <LeftIcon onClick={decreaseMonth} />
            <CurrentMonth>{monthsList[date.getMonth()]}</CurrentMonth>
            <RightIcon onClick={increaseMonth} />
          </PickerHeader>
        )}
        selected={selectedValue}
        onChange={onInnerChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Wrapper>
  );
};
