import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { client } from '../../../../../../../http';
import { routes } from '@routes';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';

import { Edit as EditIcon } from '@components/icons/Edit';
import { Jobs as JobsIcon } from '@components/icons/Jobs';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { TrashBin } from '@components/icons/TrashBin';
import { DownloadJob } from '@components/icons/DownloadJob';
import { Share } from '@components/icons/ShareIcon';
import { Popover } from '@components/Popover';
import { ClickAway } from '@components/ClickAway';
import { TJob } from '../../../../../../../types/entities/TJob';
import { getLinkPrefix } from '@helpers/jobLinkHelpers';
import { jobIsEditable } from '@helpers/jobStatus/jobIsEditable';
import { jobCanBeShared } from '@helpers/jobStatus/jobCanBeShared';
import { jobCanBeResubmitted } from '@helpers/jobStatus/jobCanBeResubmitted';
import { jobCanBeDownloaded } from '@helpers/jobStatus/jobCanBeDownloaded';
import { JobsService } from '../../../../../../../API';
import { useAPI } from '@hooks/useAPI';

const StyledIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const StyledIconButtonStroke = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      stroke: #fff;
      color: #fff;
    }
  }
`;

const DeleteButton = styled(StyledIconButton)`
  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const NoResults = styled.div`
  padding: 10px;
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    width: max-content;
    margin: 0;

    li {
        padding: 10px;
        width: 100%;
    }

    .downloadLink {
        cursor: pointer;
        width: 100%;

        &:hover {
            background: #d2d2d2;
        }

    ,
    }
`;

const HiddenButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 5px;
  background: #fff;
  top: 45px;
  right: 0;
  padding: 5px;
  box-shadow: 0 0 5px #dddddd;
  border-radius: 5px;
  overflow: visible !important;
  z-index: 100;
`;

type TProps = {
  job: TJob;
  setHiddenButtonsVisible: (value: string | null) => void;
  reload: () => void;
};

export const HamburgerButtons = ({ job, reload, setHiddenButtonsVisible }: TProps) => {
  const { call } = useAPI();
  const { workspace, organization, me } = useAuthContext();
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const workspaceId = job.workspaceId ?? workspace?.id ?? '';
  const workspaceSlug = job.workspaceSlug ?? workspace?.slug ?? '';

  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;

  const handleCopyButtonClick = (link: string) => {
    navigator.clipboard.writeText(link);
  };

  const handleEditJob = (id: string) => {
    navigate(routes.jobEdit.make(organization?.slug ?? '', workspaceSlug || 'undefined', id));
  };
  const handleResubmitJob = () => {
    reload();
    closeModal();
  };

  const handleResubmitJobModalShow = (id: string) => {
    openModal(ModalNamesEnum.ResubmitJobModal, {
      onSuccess: handleResubmitJob,
      id,
    });
  };

  const downloadFile = async (jobId: string, fileName: string) => {
    await client
      .get(`${window.location.origin}/api/media/${jobId}/transcript/${fileName}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const handleRemoveJob = async (id: string) => {
    await call(JobsService.softDeleteWsJob({ wsid: workspaceId, id }));
    reload();
    closeModal();
  };

  const handleRemoveJobModalShow = (id: string) => {
    console.log('handleRemoveJobModalShow', id);
    openModal(ModalNamesEnum.DeleteJobModal, {
      onSuccess: () => handleRemoveJob(id),
    });
  };

  const showShareButton = jobCanBeShared(job.status);
  const showResubmitButton = isVsAdmin && jobCanBeResubmitted(job.status);
  const showEditButton = jobIsEditable(job.status);
  const showDownloadButton =
    (me?.id === job?.assigneeId || organization?.permissions?.viewJobs === 'all') && jobCanBeDownloaded(job.status);

  return (
    <>
      <ClickAway onClickAway={() => setHiddenButtonsVisible(null)}>
        <Popover
          open={expanded === job.id}
          onClose={() => {
            setAnchorEl(null);
            setExpanded(null);
          }}
          anchorEl={anchorEl}
        >
          {!job.results?.length ? (
            <NoResults>Error: no results available.</NoResults>
          ) : (
            <List>
              {job.results?.map((fileName) => {
                const ext = (fileName || '').split('.').pop() || '';
                return (
                  <li
                    data-testid={`jobDownloadItem${job.name}`}
                    key={fileName}
                    onClick={() => downloadFile(job.id, fileName)}
                    className="downloadLink"
                  >
                    {ext.toUpperCase()}
                  </li>
                );
              })}
            </List>
          )}
        </Popover>
        <HiddenButtonsWrapper>
          {showShareButton ? (
            <StyledIconButton
              title="Copy link to clipboard"
              onClick={() => {
                toast.success('The link has been copied to the clipboard', {
                  duration: 2000,
                });
                handleCopyButtonClick(`${getLinkPrefix()}${job.id}`);
              }}
            >
              <Share />
            </StyledIconButton>
          ) : null}

          {showResubmitButton ? (
            <StyledIconButton title="Resubmit" onClick={() => handleResubmitJobModalShow(job.id)}>
              <JobsIcon />
            </StyledIconButton>
          ) : null}

          {showEditButton ? (
            <StyledIconButton title="Edit" onClick={() => handleEditJob(job.id)}>
              <EditIcon />
            </StyledIconButton>
          ) : null}

          {showDownloadButton ? (
            <StyledIconButtonStroke
              title="Download"
              data-testid={`jobDownload${job.name}`}
              aria-describedby={job.id}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                setAnchorEl(event.currentTarget);
                setExpanded((prevState) => (prevState === job.id ? null : job.id));
              }}
            >
              <DownloadJob />
            </StyledIconButtonStroke>
          ) : null}

          {organization?.permissions?.editJobs ? (
            <DeleteButton title="Remove" onClick={() => handleRemoveJobModalShow(job.id)}>
              <TrashBin />
            </DeleteButton>
          ) : null}
        </HiddenButtonsWrapper>
      </ClickAway>
    </>
  );
};
