import * as React from 'react';

export const Folder = (props: React.SVGProps<SVGSVGElement>) => (
  <svg className="folder" xmlns="http://www.w3.org/2000/svg" width={20} height={18} fill="none" {...props}>
    <path
      stroke="#40608F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.8 16.3h14.4a1.8 1.8 0 0 0 1.8-1.8v-9a1.8 1.8 0 0 0-1.8-1.8h-7.137a1.8 1.8 0 0 1-1.494-.81l-.738-1.08A1.8 1.8 0 0 0 6.337 1H2.8A1.8 1.8 0 0 0 1 2.8v11.7c0 .99.81 1.8 1.8 1.8Z"
    />
  </svg>
);
