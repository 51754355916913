import React from 'react';

export const LockedJob = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 21" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.90476 20.5254C1.38095 20.5254 0.932698 20.339 0.56 19.9663C0.187301 19.5936 0.000634921 19.1451 0 18.6206V9.09682C0 8.57301 0.186666 8.12476 0.56 7.75206C0.933333 7.37936 1.38159 7.19269 1.90476 7.19206H2.85714V5.2873C2.85714 3.96983 3.32159 2.84698 4.25048 1.91872C5.17936 0.99047 6.30222 0.526026 7.61905 0.525391C8.93651 0.525391 10.0597 0.989835 10.9886 1.91872C11.9175 2.84761 12.3816 3.97047 12.381 5.2873V7.19206H13.3333C13.8571 7.19206 14.3057 7.37872 14.679 7.75206C15.0524 8.12539 15.2387 8.57364 15.2381 9.09682V18.6206C15.2381 19.1444 15.0517 19.593 14.679 19.9663C14.3063 20.3397 13.8578 20.526 13.3333 20.5254H1.90476ZM1.90476 18.6206H13.3333V9.09682H1.90476V18.6206ZM7.61905 15.7635C8.14286 15.7635 8.59143 15.5771 8.96476 15.2044C9.33809 14.8317 9.52444 14.3832 9.52381 13.8587C9.52381 13.3349 9.33746 12.8867 8.96476 12.514C8.59206 12.1413 8.14349 11.9546 7.61905 11.954C7.09524 11.954 6.64698 12.1406 6.27429 12.514C5.90159 12.8873 5.71492 13.3355 5.71428 13.8587C5.71428 14.3825 5.90095 14.8311 6.27429 15.2044C6.64762 15.5778 7.09587 15.7641 7.61905 15.7635ZM4.7619 7.19206H10.4762V5.2873C10.4762 4.49364 10.1984 3.81904 9.64286 3.26349C9.0873 2.70793 8.4127 2.43015 7.61905 2.43015C6.8254 2.43015 6.15079 2.70793 5.59524 3.26349C5.03968 3.81904 4.7619 4.49364 4.7619 5.2873V7.19206Z"
        fill="#858DBD"
      />
    </svg>
  );
};
