import { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  p {
    font-size: 16px;
  }
`;

type TProps = {
  isFailure: boolean;
};

export const PaymentResult = ({ isFailure }: TProps) => {
  useEffect(() => {
    window?.top?.postMessage('3DS-authentication-complete');
  }, []);

  return (
    <Wrapper>
      <h2>{isFailure ? ' Payment failure' : 'Payment successful'}</h2>
      <p>
        {isFailure ? (
          <>
            Oh no, Your payment failed
            <br />
            Please update payment information.
          </>
        ) : (
          <>Thank You for Your Payment</>
        )}
      </p>
    </Wrapper>
  );
};
