import { TJob } from 'app/types/entities/TJob';
import { TOrganization } from 'app/types/entities/TOrganization';
import { TMe } from 'app/types/entities/TMe';
import { jobIsEditable } from '@helpers/jobStatus/jobIsEditable';
import { jobCanBeShared } from '@helpers/jobStatus/jobCanBeShared';
import { jobCanBeDownloaded } from '@helpers/jobStatus/jobCanBeDownloaded';
import { jobCanBeResubmitted } from '@helpers/jobStatus/jobCanBeResubmitted';

export const canShowHamburger = (
  job: TJob,
  isVsAdmin: boolean,
  canManageWorkspaces: boolean,
  organization: TOrganization | undefined,
  me: TMe,
) => {
  const showShareButton = jobCanBeShared(job.status);
  const showResubmitButton = isVsAdmin && jobCanBeResubmitted(job.status);
  const showEditButton = jobIsEditable(job.status);
  const showDownloadButton =
    (me?.id === job?.assigneeId || organization?.permissions?.viewJobs === 'all') && jobCanBeDownloaded(job.status);

  if (canManageWorkspaces) {
    return true;
  }
  if (showDownloadButton) {
    return true;
  }
  if (showEditButton) {
    return true;
  }
  if (showResubmitButton) {
    return true;
  }
  return showShareButton;
};
