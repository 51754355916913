import styled from 'styled-components';

export const WorkspaceListItem = styled.li`
  position: relative;

  .meatballs {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    height: 20px;
    width: 20px;
    padding: 5px;
    margin: -10px 10px 0 0;
  }

  &:hover .meatballs {
    display: block;
  }

  .workspace-list-workspace-name {
    line-height: 30px;
    margin-left: 8px;
  }
`;
