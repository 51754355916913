export const GlobeIcon = ({ color = '#40608F' }: { color?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C20.9975 9.61382 20.0485 7.3261 18.3612 5.63882C16.6739 3.95154 14.3862 3.00252 12 3ZM9.71798 15.4615H14.282C13.8173 17.0487 13.0385 18.4791 12 19.6059C10.9615 18.4791 10.1827 17.0487 9.71798 15.4615ZM9.40385 14.0769C9.17424 12.7018 9.17424 11.2982 9.40385 9.92308H14.5962C14.8258 11.2982 14.8258 12.7018 14.5962 14.0769H9.40385ZM4.38462 12C4.38402 11.2977 4.481 10.5987 4.67279 9.92308H8.00106C7.79452 11.3 7.79452 12.7 8.00106 14.0769H4.67279C4.481 13.4013 4.38402 12.7023 4.38462 12ZM14.282 8.53846H9.71798C10.1827 6.95135 10.9615 5.52086 12 4.39413C13.0385 5.52086 13.8173 6.95135 14.282 8.53846ZM15.9989 9.92308H19.3272C19.7114 11.281 19.7114 12.719 19.3272 14.0769H15.9989C16.2055 12.7 16.2055 11.3 15.9989 9.92308ZM18.782 8.53846H15.716C15.3627 7.14823 14.7693 5.83036 13.9627 4.64423C14.9932 4.92117 15.9544 5.41072 16.7844 6.0814C17.6144 6.75208 18.2949 7.58904 18.782 8.53846ZM10.0373 4.64423C9.23068 5.83036 8.63735 7.14823 8.28404 8.53846H5.21798C5.70513 7.58904 6.38559 6.75208 7.21559 6.0814C8.04559 5.41072 9.00677 4.92117 10.0373 4.64423ZM5.21798 15.4615H8.28404C8.63735 16.8518 9.23068 18.1696 10.0373 19.3558C9.00677 19.0788 8.04559 18.5893 7.21559 17.9186C6.38559 17.2479 5.70513 16.411 5.21798 15.4615ZM13.9627 19.3558C14.7693 18.1696 15.3627 16.8518 15.716 15.4615H18.782C18.2949 16.411 17.6144 17.2479 16.7844 17.9186C15.9544 18.5893 14.9932 19.0788 13.9627 19.3558Z"
      fill={color}
      stroke={color}
      strokeWidth="0.2"
    />
  </svg>
);
