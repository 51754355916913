import { Logo } from '@components/icons/Logo';

import { LogoText } from './styles/LogoText';

export const VsLogo = () => (
  <>
    <Logo />
    <LogoText>Powered by VoiceScript Inc</LogoText>
  </>
);
