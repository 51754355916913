import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { TJob } from 'app/types/entities/TJob';
import { TOrganization } from 'app/types/entities/TOrganization';
import { JobsService } from 'app/API';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';
import { jobCanBeMarkedAsEdited } from '@helpers/jobStatus/jobCanBeMarkedAsEdited';
import { jobCanBeMarkedAsComplete } from '@helpers/jobStatus/jobCanBeMarkedAsComplete';
import { jobCanBeMarkedAsProofread } from '@helpers/jobStatus/jobCanBeMarkedAsProofread';
import { jobIsReadyForProofReading } from '@helpers/jobStatus/jobIsReadyForProofReading';
import { jobIsReadyForFinal } from '@helpers/jobStatus/jobIsReadyForFinal';
import { jobIsReadyForTranscribing } from '@helpers/jobStatus/jobIsReadyForTranscribing';

export const showClaimButton = (job?: TJob, organization?: TOrganization) =>
  job &&
  !job.assignee &&
  !job.asOneLocked &&
  ((organization?.permissions?.editJobTranscript && jobIsReadyForTranscribing(job.status)) ||
    (organization?.permissions?.proofreadJobTranscript && jobIsReadyForProofReading(job.status)) ||
    (organization?.permissions?.finalizeJobTranscript && jobIsReadyForFinal(job.status)));

export const showMarkAsCompleteButton = (job?: TJob, organization?: TOrganization) =>
  job && organization?.permissions?.markJobComplete && jobCanBeMarkedAsComplete(job.status);

export const showMarkAsEditedButton = (job?: TJob, organization?: TOrganization) =>
  job && organization?.permissions?.editJobTranscript && jobCanBeMarkedAsEdited(job.status);

export const showMarkAsProofreadButton = (job?: TJob, organization?: TOrganization) =>
  job && organization?.permissions?.proofreadJobTranscript && jobCanBeMarkedAsProofread(job.status);

type TProps = {
  job: TJob;
  reload: () => void;
};

export const JobStatusActionsButton = ({ job, reload }: TProps) => {
  const { call } = useAPI();
  const { openModal } = useModalContext();
  const { workspace, organization } = useAuthContext();

  const handleClaimJob = async (job: TJob) => {
    const result = await call(JobsService.claimJob({ wsid: workspace?.id || '', id: job.id }));
    if (result?.code === 10) {
      openModal(ModalNamesEnum.GenericErrorModal, {
        title: 'You’ve reached your job limit',
        message: 'To continue you need to finish jobs you already claimed.',
      });
      return;
    } else if (!result?.success && result?.message) {
      openModal(ModalNamesEnum.GenericErrorModal, {
        message: result.message,
      });
      return;
    }
    reload();
  };

  const handleMarkJobAsComplete = async (jobId: string) => {
    await call(JobsService.markJobAsComplete({ wsid: workspace?.id || '', id: jobId }));
    reload();
  };

  const handleMarkJobAsReadyForProofreading = async (jobId: string) => {
    await call(JobsService.markJobAsReadyForProofreading({ wsid: workspace?.id || '', id: jobId }));
    reload();
  };

  const handleMarkJobAsReadyForFinal = async (jobId: string) => {
    await call(JobsService.markJobAsReadyForFinal({ wsid: workspace?.id || '', id: jobId }));
    reload();
  };

  if (showClaimButton(job, organization)) {
    return (
      <Button onClick={() => handleClaimJob(job)} id="claim_job_button">
        Claim Job
      </Button>
    );
  }

  if (showMarkAsCompleteButton(job, organization)) {
    return (
      <Button onClick={() => handleMarkJobAsComplete(job.id)} id="mark_as_complete">
        Mark As Complete
      </Button>
    );
  }

  if (showMarkAsEditedButton(job, organization)) {
    return (
      <Button onClick={() => handleMarkJobAsReadyForProofreading(job.id)} id="mark_as_edited_button">
        Mark as Edited
      </Button>
    );
  }

  if (showMarkAsProofreadButton(job, organization)) {
    return (
      <Button onClick={() => handleMarkJobAsReadyForFinal(job.id)} id="mark_as_proofread">
        Mark as Proofread
      </Button>
    );
  }

  return null;
};
