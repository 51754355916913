import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { SortableHead } from '@components/Table/SortableHead';
import { SortingDirection } from '@components/Table/SortingDirection';
import { CustomTable } from '@components/Table';

import { routes } from '@routes';
import { TrashBin } from '@components/icons/TrashBin';
import { TOrganization } from 'app/types/entities/TOrganization';
import { TTeamMemberItem } from 'app/types/entities/TRole';
import { useAuthContext } from '@providers/AuthProvider';
import { useAPI } from '@hooks/useAPI';
import { TeamsService } from 'app/API';

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }

  table {
    width: 100%;

    thead tr td,
    thead tr th {
      height: 50px;
    }
  }
`;

const LastCellContent = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 6px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;

    :hover {
      background: #ff9191;

      path {
        fill: #fff;
        color: #fff;
      }
    }
  }
`;
const CellContent = styled.div`
  margin: 0;
  padding: 15px 0;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

type Team = {
  id: string;
  default: boolean;
  Members: TTeamMemberItem[];
  Organization: TOrganization;
};

type TProps = {
  team: Team;
  setSortingField: Dispatch<SetStateAction<string>>;
  setSortingReverse: Dispatch<SetStateAction<boolean>>;
  sortingField: string;
  sortingReverse: boolean;
  setReloadPageTrigger: Dispatch<SetStateAction<boolean>>;
};

export const TeamMembersTable = ({
  team,
  setSortingField,
  setSortingReverse,
  sortingField,
  sortingReverse,
  setReloadPageTrigger,
}: TProps) => {
  const { call } = useAPI();
  const { me } = useAuthContext();
  const members = team.Members ?? [];
  const organization = team.Organization;

  const toggleSort = (field: string) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse((prevState: boolean) => !prevState);
    }
  };

  const handleDeleteTeamMember = async (id: string) => {
    await call(TeamsService.removeMember({ id: team.id, memberId: id }));
    setReloadPageTrigger((v) => !v);
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '1%' }}>#</td>,
      render: (data: TTeamMemberItem, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('name')}>
          Name
          {sortingField === 'name' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TTeamMemberItem) => (
        <CellContent>
          <Link to={routes.userDetails.make(organization?.slug, data.User.id)}>
            {data.User.name} {data.User.lastname}
          </Link>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contact</td>,
      render: (data: TTeamMemberItem) => (
        <CellContent>
          <span style={{ display: 'block' }}>{data.User.email}</span>
          <span style={{ display: 'block' }}>{data.User.phone}</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TTeamMemberItem) => <CellContent>{getAmericanTime((data.User ?? {}).createdAt ?? 0)}</CellContent>,
    },
    {
      headComponent: () => <td></td>,
      render: (data: TTeamMemberItem) => (
        <LastCellContent>
          {(data.User ?? {}).id !== me.id || !team.default ? (
            <TrashBin onClick={() => handleDeleteTeamMember(data.id)} cursor="pointer" />
          ) : null}
        </LastCellContent>
      ),
    },
  ];

  return (
    <>
      <Wrapper>
        <CustomTable head={tableHead} data={members} />
      </Wrapper>
    </>
  );
};
