import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { SoundWave } from '@components/icons/SoundWave';

import { Footer } from '../Footer';

type TProps = {
  orgName: string;
  id: string;
  email: string | null;
};

export const NoUserContent = ({ orgName, id, email }: TProps) => {
  const navigate = useNavigate();

  const onSignInClick = () => {
    navigate(
      `${routes.signin.pattern}?goto=${routes.acceptInvitation.make(id)}&email=${encodeURIComponent(email ?? '')}`,
    );
  };
  const onSignUpClick = () => {
    navigate(
      `${routes.signup.pattern}?goto=${routes.acceptInvitation.make(id)}&email=${encodeURIComponent(email ?? '')}`,
    );
  };

  return (
    <>
      <Helmet>
        <title>Request access - AutoScript</title>
      </Helmet>
      <div className="content">
        <p>
          Sign in or sign up to accept invitation to <b>{orgName}</b> organization
        </p>
        <div className="text"></div>

        <SoundWave />
        <div className="signInButton">
          <p className="signLink" onClick={onSignInClick}>
            Sign in
          </p>
          &nbsp;
          <p> or </p> &nbsp;
          <p className="signLink" onClick={onSignUpClick}>
            Sign up
          </p>
          &nbsp;to accept the invitation
        </div>

        <Footer />
      </div>
    </>
  );
};
