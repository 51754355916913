import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { routes } from '@routes';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';
import { OrganizationsService, WorkspacesService } from 'app/API';
import { TRole } from 'app/types/entities/TRole';

import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { CustomTable } from '@components/Table';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { AddBlue } from '@components/icons/AddBlue';
import { Tag } from '@components/Tag';
import { TableStyledLink } from '@components/Table/StyledLink';

import { FlexCellContent } from './styles/FlexCellContent';
import { MainContent } from './styles/MainContent';

export const TeamsPage = () => {
  const { call } = useAPI();
  const { organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();

  const [teams, setTeams] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    if (!organization?.id) {
      return;
    }
    setIsLoading(true);
    const data = await call(OrganizationsService.getOrgTeams({ oid: organization?.id ?? '' }));
    setTeams(data);
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [organization?.id]);

  const teamsFiltered = teams.filter((team: TRole) => team.name.toLowerCase().includes(search.toLowerCase()));

  const editMembers = (teamId: string) => {
    openModal(ModalNamesEnum.EditTeamMembersModal, {
      teamId,
      onSuccess: async () => {
        load();
        closeModal();
      },
    });
  };

  const addWorkspace = (teamId: string, workspaceIds: string[]) => {
    openModal(ModalNamesEnum.AddWSToTeamModal, {
      teamId,
      workspaceIds,
      onSuccess: async () => {
        load();
        closeModal();
      },
    });
  };

  const deleteWorkspace = (id: string, wsSlug: string) => {
    openModal(ModalNamesEnum.CustomConfirmationModal, {
      description: 'Are you sure you want to disconnect this workspace?',
      buttonText: 'Disconnect',
      onSuccess: async () => {
        await call(
          WorkspacesService.updateWorkspaceByRemovingTeam({
            orgSlug: organization?.slug ?? '',
            wsSlug: wsSlug,
            teamId: id,
          }),
        );
        load();
        closeModal();
      },
    });
  };

  const createNewTeam = () => {
    openModal(ModalNamesEnum.NewTeamModal, {
      organizationId: organization?.id,
      onSuccess: () => {
        load();
        closeModal();
      },
    });
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '20%' }}>Team</td>,
      render: (data: TRole) => (
        <FlexCellContent>
          <TableStyledLink
            to={routes.teamDetails.make(organization?.slug ?? '', data.slug)}
            state={{ from: routes.organizationTeams.make(organization?.slug ?? '') }}
          >
            {data.name}
          </TableStyledLink>
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '10%' }}>Workspaces</td>,
      render: (data: TRole) => (
        <FlexCellContent className="flex-wrap gap-2">
          {(data.Workspaces || []).map((ws) => (
            <Tag
              key={ws.id}
              text={ws.name}
              onDelete={() => deleteWorkspace(data.id, ws.slug)}
              link={routes.workspaceDetails.make(organization?.slug ?? '', ws.slug)}
            />
          ))}
          <AddBlue
            className="add-blue"
            onClick={() =>
              addWorkspace(
                data.id,
                (data.Workspaces || []).map((ws) => ws.id),
              )
            }
          />
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Members</td>,
      render: (data: TRole) => (
        <FlexCellContent>
          {data.members || 0}
          <button className="edit-members" type="button" onClick={() => editMembers(data.id)}>
            <PencilIcon />
          </button>
        </FlexCellContent>
      ),
    },
    // {
    //   headComponent: () => <td style={{ width: '1%' }}>Active jobs</td>,
    //   render: (data: TRole) => <FlexCellContent>{getAmericanTime(data.createdAt)}</FlexCellContent>,
    // },
  ];

  return (
    <>
      <Helmet>
        <title>Teams - AutoScript</title>
      </Helmet>
      <Header
        title="Teams"
        setSearch={setSearch}
        search={search}
        buttonLabel={'Create New Team'}
        buttonOnClick={() => createNewTeam()}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <CustomTable head={tableHead} data={teamsFiltered} />
      </MainContent>
    </>
  );
};
