import { useNavigate } from 'react-router';

import { acceptInvitation } from '@pages/Anonymous/AcceptInvitationPage/queries/acceptInvitation';

import { Button } from '@components/form-elements/buttons/Button';
import { SoundWave } from '@components/icons/SoundWave';

import { Footer } from '../Footer';

type TProps = {
  id: string;
  orgName: string;
  setIsLoading: (v: boolean) => void;
};

export const Content = ({ id, orgName, setIsLoading }: TProps) => {
  const navigate = useNavigate();

  const onAcceptInvitation = async () => {
    setIsLoading(true);
    await acceptInvitation(id).then(async () => {
      navigate('/');
    });
  };

  return (
    <div className="content">
      <p>
        You are about to join the organization <b>{orgName}</b>
      </p>
      <Button className="button" onClick={onAcceptInvitation}>
        Accept
      </Button>
      <div className="text"></div>

      <SoundWave />

      <Footer />
    </div>
  );
};
