import { CheckEmail } from '@components/icons/CheckEmail';

import { StyledIconWrapper } from './styles/StyledIconWrapper';

export const RegistrationPageSuccessStage = () => {
  return (
    <div className="root">
      <StyledIconWrapper>
        <CheckEmail className="checkEmailIcon" />
      </StyledIconWrapper>
      <h1 className="completeCheckYourEmail">Check Your Email</h1>
      <h2 className="completeWeSentAConfirmationEmail">
        We sent a confirmation email.
        <br />
        Click the link in the email to confirm your email address.
        <br />
        If you do not see it in your inbox, then check your spam folder and add AutoScript to your safe sender list.
      </h2>
    </div>
  );
};
