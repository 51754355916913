import { useState } from 'react';
import styled from 'styled-components';

import { DisclosureSection } from '@components/DisclosureSection';
import { BillingAccount } from './BillingAccountCard';
import { TOrganization } from 'app/types/entities/TOrganization';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

type TProps = {
  organization: TOrganization;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export const BillingAccountList = ({ organization }: TProps) => {
  const [billingAccountsDisclosureOpen, setBillingAccountsDisclosureOpen] = useState<boolean>(true);

  const billingAccountSectionTitle = 'Organization Billing Account';

  return (
    <Wrapper className="flex flex-col">
      <div>
        <DisclosureSection
          title={billingAccountSectionTitle}
          open={billingAccountsDisclosureOpen}
          setOpenState={setBillingAccountsDisclosureOpen}
          loading={false}
        >
          <BillingAccount billingAccount={organization.billingAccount} organizationId={organization.id} />
        </DisclosureSection>
      </div>
    </Wrapper>
  );
};
