import styled from 'styled-components';

import { CheckEmail } from '@components/icons/CheckEmail';
import { Wrapper } from './styles';

const StyledIconWrapper = styled.div`
  width: 60px;
  margin: 0 auto;
`;

export const RegistrationTokenExpiredPage = () => {
  return (
    <Wrapper>
      <StyledIconWrapper>
        <CheckEmail className="checkEmailIcon" />
      </StyledIconWrapper>
      <h1 className="completeCheckYourEmail">Verification token has expired</h1>
      <h2 className="completeWeSentAConfirmationEmail">
        We resent you the verification code.
        <br />
        Click the link in the email to confirm your email address.
      </h2>
    </Wrapper>
  );
};
