import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';

import { Modal } from './Modal';

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #858dbd;
  margin: 0 0 10px;
  text-align: center;
`;

const Wrapper = styled.div`
  background: #ffffff;
  width: 660px;
  height: 500px;
  padding: 30px;
`;

type TProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DesktopTutorialVideo = ({ isOpen, onClose }: TProps) => {
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Wrapper>
        <Title style={{ margin: '0 0 25px', textAlign: 'center' }}>AutoScript Desktop User Guide</Title>
        <video controls width="100%" poster="/video/Desktop.jpg">
          <source src="/video/Desktop.mp4" type="video/mp4" />
          <p>
            Your browser {"doesn't"} support HTML video. Here is a <a href="/video/Desktop.mp4">link to the video</a>{' '}
            instead.
          </p>
        </video>
        <div style={{ marginTop: '20px' }}>
          <Button onClick={onClose}>Got it!</Button>
        </div>
      </Wrapper>
    </Modal>
  );
};
