import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from '@routes';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';

import { Block } from '@components/Block';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { TrashBin } from '@components/icons/TrashBin';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';
import { TUser } from 'app/types/entities/TUser';
import { TJob } from 'app/types/entities/TJob';

const StyledBlock = styled(Block)`
  .add-assignee {
    padding: 5px 0 10px;
    border: none;
    background: none;
    color: #40608f;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-decoration-line: underline;

    &:hover {
      color: #858dbd;
    }
  }

  .assignee-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .assignee {
      text-overflow: ellipsis;
      overflow: hidden;

      .assignName {
        color: #b4b9d9;
        text-decoration: underline;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin: 0;
      }

      .assignEmail {
        color: #313131;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 100%;
        margin: 0;
      }
    }

    .edit-assignee {
      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;

        path {
          fill: #d5def2;
        }

        :hover {
          path {
            fill: #858dbd;
          }
        }
      }
    }

    .remove-assignee {
      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
        padding: 5px;
        border-radius: 3px;

        path {
          fill: #d5def2;
        }

        :hover {
          background: #ff9191;

          path {
            fill: #fff;
            color: #fff;
          }
        }
      }
    }
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: none;

  &:hover {
    color: #40608f;
  }
`;

export const AssigneeBlock = () => {
  const { organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();
  const { form, setForm } = usePageContext();

  const setAssignee = (assignee: TUser | null, assigneeComment: string) => {
    setForm({
      ...form,
      item: {
        ...form.item,
        assignee: assignee ?? undefined,
        assigneeComment,
        assigneeId: assignee?.id ?? undefined,
        draftAssignee: undefined,
        draftAssigneeId: undefined,
      } as TJob,
    });
    closeModal();
  };

  const editAssignee = () => {
    openModal(ModalNamesEnum.GetAssigneeModal, {
      initialValue: form.item?.assignee || form.item?.draftAssignee,
      onSuccess: (assignee: TUser | null, comment: string) => setAssignee(assignee, comment),
    });
  };

  const description = <p>Assignments will trigger when the job has completed processing</p>;

  const assignee = form.item?.assignee || form.item?.draftAssignee;

  return (
    <StyledBlock title="Assignee" description={description}>
      <>
        {assignee ? (
          <div className="assignee-wrapper">
            <div className="assignee">
              <StyledLink to={routes.userDetails.make(organization?.slug ?? '', assignee?.id)}>
                <p className="assignName">
                  {assignee?.name} {assignee?.lastname}
                </p>
                <p className="assignEmail">{assignee?.email || ''}</p>
              </StyledLink>
            </div>
            <button className="edit-assignee" type="button" onClick={() => editAssignee()}>
              <PencilIcon />
            </button>
            <button className="remove-assignee" type="button" onClick={() => setAssignee(null, '')}>
              <TrashBin />
            </button>
          </div>
        ) : null}
      </>
      <>
        {!assignee ? (
          <button className="add-assignee" type="button" onClick={() => editAssignee()}>
            + Add Assignee
          </button>
        ) : null}
      </>
    </StyledBlock>
  );
};
