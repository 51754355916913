import styled from 'styled-components';
import { OrganizationImageViewOnly } from './OrganizationImage';
import { Input } from '@components/form-elements/Input';
import { TOrganization } from 'app/types/entities/TOrganization';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div``;

const RightSideColumns = styled.div`
  display: flex;
  gap: 10px;
`;

const InputBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 0;
  width: 270px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const InputWrapper = styled.div`
  margin-right: 30px;
`;

type TProps = {
  organization: TOrganization;
};

export const NameSectionViewOnly = ({ organization }: TProps) => {
  return (
    <div>
      <Wrapper>
        <LeftSide>
          <h3>General</h3>
        </LeftSide>

        <RightSide>
          <OrganizationImageViewOnly org={organization} />
          <RightSideColumns>
            <InputBlockWrapper>
              <InputWrapper>
                <label htmlFor="name">Organization name</label>
                <Input
                  className="whiteInput text-xs w-64 block"
                  disabled={true}
                  onChange={() => {}}
                  name="slug"
                  value={organization.name}
                  label="Organization name"
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="slug">Organization URL</label>
                <Input
                  className="whiteInput text-xs w-64 block"
                  disabled={true}
                  onChange={() => {}}
                  name="slug"
                  value={organization.slug}
                  label="Organization URL"
                />
              </InputWrapper>
            </InputBlockWrapper>
          </RightSideColumns>
        </RightSide>
      </Wrapper>
    </div>
  );
};
