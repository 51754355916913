import { useEffect, useState } from 'react';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { formatCentsPrice } from '@helpers/formatCentsPrice';

import { GearSvg } from '@components/icons/Gear';

import { Wrapper } from './styles/Wrapper';
import { BalanceLink } from './styles/BalanceLink';
import { TopUpButton } from './components/TopUpButton';

export const UserPaymentModelStatus = () => {
  const { organization } = useAuthContext();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setBalance(organization?.billingAccount?.currentBalance || 0);
  }, [organization]);

  const canViewBilling = organization?.billingAccount && organization?.permissions?.viewBilling;
  if (!canViewBilling) {
    return null;
  }

  return (
    <Wrapper>
      <div className="content">
        <div className="title">Credit Balance</div>
        <div className="flex">
          <BalanceLink
            className="no-underline hover:no-underline"
            to={routes.billingAccount.make(organization?.slug ?? '')}
          >
            {formatCentsPrice(balance)}
            <GearSvg style={{ width: '14px', height: '18px', marginLeft: '10px' }} />
          </BalanceLink>
          <TopUpButton />
        </div>
      </div>
    </Wrapper>
  );
};
