import styled from 'styled-components';

export const FlexCellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  min-height: 88px;
  display: flex;
  align-items: center;

  .arrow-icon {
    transform: rotate(180deg);
    margin-left: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease-in;

    &.active {
      transform: rotate(0deg);
    }
  }
`;
