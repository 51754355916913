import { useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { UploadObjectType } from '@constants/enums/uploadObjectType';

import { FileUpload } from '@components/FileUpload';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';
import { Block } from '@components/Block';
import { Switch } from '@components/form-elements/Switch';

import { TStatusValue } from '@components/FileUpload/components/dropzone/types/TStatusValue';
import { TAudioFile } from 'app/types/entities/TAudioFile';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { mapAttachmentsToTFileWithMeta } from '@pages/User/NewJobPage/helpers/mapAttachmentsToTFileWithMeta';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';
import { secondsToHHMMSS } from '@helpers/secondsToHHMMSS';
import { EACCEPTED_AUDIO_FORMATS } from 'app/types/enums/EACCEPTED_AUDIO_FORMATS';
import { MediaService } from '../../../../../../API';
import { useAPI } from '@hooks/useAPI';

const StyledLink = styled.a`
  color: #858dbd;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    color: #40608f;
    cursor: pointer;
  }
`;

const StyledBlock = styled(Block)`
  padding-bottom: 20px;

  .leftSide {
    padding-right: 25px;
  }

  .rightSide {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .rightSide > * {
    margin-bottom: 0 !important;
  }
`;

type TProps = {
  allFiles: TFileWithMeta[];
  setAllFiles: (files: TFileWithMeta[]) => void;
  newFiles: TFileWithMeta[];
  setNewFiles: (files: TFileWithMeta[]) => void;
  handleChangeStatus: (file: TFileWithMeta, status: TStatusValue) => Promise<void>;
  onNewFiles: (files: TFileWithMeta[]) => Promise<void>;
};

export const NameUploadBlock = ({
  allFiles,
  setAllFiles,
  handleChangeStatus,
  onNewFiles,
  newFiles,
  setNewFiles,
}: TProps) => {
  const { call } = useAPI();
  const { jobId, form, isLoading, setFormItemValue, isFilesLoading, onRemoveFile } = usePageContext();

  const multipleFilesOptions = [
    { value: 'concat', label: 'Concatenate' },
    { value: 'mixdown', label: 'Mixdown' },
  ];

  const [repositoryFiles, setRepositoryFiles] = useState<unknown[]>([]);
  const [isSubmittingRepoFiles, setIsSubmittingRepoFiles] = useState(false);

  const { openModal, closeModal } = useModalContext();
  const { workspace } = useAuthContext();

  const openCaseFilesModal = () => {
    if (!workspace?.id) {
      return;
    }

    openModal(ModalNamesEnum.SelectCaseFiles, {
      repositoryId: form.item?.repositoryId,
      selectedFiles: repositoryFiles,
      setSelectedFiles: setRepositoryFiles,
      loading: isSubmittingRepoFiles,
      onSuccess: (selectedFiles: TAudioFile[]) => {
        setIsSubmittingRepoFiles(true);
        call(
          MediaService.copyRepositoryAttachmentsAsMedia({
            requestBody: {
              files: selectedFiles,
              jobId,
              workspaceId: workspace.id,
              repositoryId: form.item?.repositoryId ?? '',
            },
          }),
        ).then((files) => {
          const mappedFiles = mapAttachmentsToTFileWithMeta(files);

          setNewFiles([...new Set([...newFiles, ...(mappedFiles as unknown as TFileWithMeta[])])]);
          setIsSubmittingRepoFiles(false);

          closeModal();
        });
      },
      onCancel: closeModal,
    });
  };

  const handleSelectChange = async (name: string, value: string) => {
    setFormItemValue(name, value);
    setRepositoryFiles([]);
  };

  const handleChange = async (name: string, value: string | boolean) => {
    setFormItemValue(name, value);
  };

  const renderMultipleFilesConcatChoices = () => {
    const isTrm = allFiles.find((file) => file.meta && file.meta.name && file.meta.name.toLowerCase().includes('.trm'));
    if (allFiles.length < 2 || isTrm) {
      return <></>;
    }
    const currentValue = form.item?.multipleFilesHandling ?? 'concat';
    const currentOptionDescription =
      currentValue === 'concat'
        ? 'Files are combined sequentially, based on the order they were uploaded, from top to bottom. Simply drag and drop to rearrange the files in the correct order.'
        : 'Files are combined in parallel. Select this option when each file is a separate recording of one of the participants.';
    return (
      <div className="multipleFilesHandling">
        <SmallWhiteSelect
          name="multipleFilesHandling"
          label="Files handling"
          value={currentValue}
          disabled={isLoading}
          onChange={handleSelectChange}
          options={multipleFilesOptions}
          required={true}
        />
        <div className="description">{currentOptionDescription}</div>
      </div>
    );
  };

  const dualChannelDescription = (
    <p>
      Enable this option if your phone call recording uses two channels <br />
      (e.g., one for each speaker).
    </p>
  );

  return (
    <div>
      <Block title="Audio Files">
        <FileUpload
          objectId={jobId}
          objectType={UploadObjectType.JOB}
          files={allFiles}
          multiple={true}
          setFiles={setAllFiles}
          onChangeStatus={handleChangeStatus}
          onNewFiles={onNewFiles}
          isFilesLoading={isFilesLoading}
          accept={EACCEPTED_AUDIO_FORMATS.join(',')}
          timeout={600000}
          onRemoveFile={onRemoveFile}
        />
        <>
          {allFiles.length &&
          !allFiles.find((file) => !file.meta.duration) &&
          !allFiles.find((file) => file.meta.status !== 'done') &&
          form.item?.multipleFilesHandling !== 'mixdown' ? (
            <div className="uploadDuration">
              Total audio duration:{' '}
              <span>{secondsToHHMMSS(allFiles.reduce((acc, cur) => acc + (cur.meta?.duration ?? 0), 0))}</span>
            </div>
          ) : null}
        </>
        <div className="uploadDescription">
          <ul>
            <li>Zoom multi-channel audio, For The Record (FTR), MP3, WAV and other audio formats</li>
            {form.item?.repositoryId && !allFiles.length ? (
              <li>
                <StyledLink onClick={openCaseFilesModal}>Or choose audio files from the Case</StyledLink>
              </li>
            ) : null}
          </ul>
        </div>
        {renderMultipleFilesConcatChoices()}
      </Block>
      <StyledBlock title="Dual-Channel Call Processing" description={dualChannelDescription}>
        <Switch
          checked={!!form.item?.dualChannel}
          onChange={() => handleChange('dualChannel', !form.item?.dualChannel)}
        />
      </StyledBlock>
    </div>
  );
};
