import React, { useState } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';
import { INVITATION_STATUSES } from '@constants/enums/invitationStatuses';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { SortingDirection } from '@components/Table/SortingDirection';
import { CustomTable } from '@components/Table';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { TrashBin } from '@components/icons/TrashBin';
import { StatusText } from '@components/Table/StatusText';
import { SortableHead } from '@components/Table/SortableHead';
import { TInvitation } from 'app/types/entities/TInvitation';
import { Button } from '@components/form-elements/buttons/Button';
import { Loader } from '@components/FileUpload/components/Loader';
import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { Input } from '@components/form-elements/Input';
import { Pencil } from '@components/icons/Pencil';
import { CheckOneIcon } from '@components/icons/CheckOne';
import { EErrorMessages } from '@constants/errorMessages';

const CellContent = styled.div`
  margin: 0;
  height: 60px;
  display: flex;
  align-items: center;
`;

const DeleteButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const RolesCell = styled.div`
  /* height: 22px; */
  padding: 6px;
  background: white;
  color: #858dbd;
  font-weight: 500;
  font-size: 12px;
  line-height: 90%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  margin: 10px 10px 10px 0;

  svg {
    cursor: pointer;
  }
`;

type TProps = {
  invitations: TInvitation[];
  setReloadPageTrigger?: React.Dispatch<React.SetStateAction<boolean>>;
  openInviteUserModalOnClose?: boolean;
};

export const InvitationPage = ({ invitations, setReloadPageTrigger, openInviteUserModalOnClose = false }: TProps) => {
  const [sortingField, setSortingField] = useState('created');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [isLoadingId, setIsLoadingId] = useState('');
  const { call } = useAPI();
  const [isEditingId, setIsEditingId] = useState('');
  const [newEmailValue, setNewEmailValue] = useState('');

  const { openModal, closeModal } = useModalContext();
  const { organization, me } = useAuthContext();

  const toggleSort = (field: string) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse((prevState) => !prevState);
    }
  };

  const openDeleteInvitationModal = (id: string) => {
    const organizationId = organization?.id || '';
    openModal(ModalNamesEnum.DeleteInvitationModal, {
      invitationId: id,
      organizationId,
      onSuccess: () => {
        closeModal();
        setReloadPageTrigger?.((prevState) => !prevState);
      },
      onCancel: () => closeModal(),
      openInviteUserModalOnClose,
    });
  };

  const resendInvitation = async (data: TInvitation) => {
    setIsLoadingId(data.id);
    await call(AdminService.resendInvitation({ id: data.id }), {
      onError: (error) => {
        let errorMessage: string = EErrorMessages.DEFAULT;
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 403) {
          errorMessage = 'Could not resend email for this invitation status';
        }
        toast.error(errorMessage);
        return;
      },
      onSuccess: () => {
        toast.success('Invite has been resent');
      },
    });
    setIsLoadingId('');
  };

  const updateInvitationEmail = async (data: TInvitation) => {
    if (!newEmailValue.includes('@') || !newEmailValue.includes('.')) {
      toast.error('Email value should be valid');
      return;
    }

    if (data.email === newEmailValue) {
      setNewEmailValue('');
      setIsEditingId('');
      return;
    }

    await call(AdminService.editInvitation({ id: data.id, requestBody: { email: newEmailValue } }), {
      onError: () => {
        toast.error(EErrorMessages.DEFAULT);
      },
      onSuccess: () => {
        setNewEmailValue('');
        setIsEditingId('');
        toast.success('Successfully updated');
        openModal(ModalNamesEnum.ResendInvitationModal, {
          email: newEmailValue,
          onSuccess: () => {
            resendInvitation(data);
          },
        });
        if (setReloadPageTrigger) {
          setReloadPageTrigger(true);
        }
      },
    });
  };

  const tableHead = [
    {
      render: (data: TInvitation) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
      headComponent: () => (
        <SortableHead onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
    },
    {
      headComponent: () => (
        <SortableHead onClick={() => toggleSort('name')}>
          Email Address
          {sortingField === 'email' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TInvitation) => (
        <CellContent>
          {isEditingId === data.id ? (
            <Input
              label="email"
              value={newEmailValue}
              disabled={isEditingId !== data.id}
              onChange={(e) => setNewEmailValue(e.target.value)}
            />
          ) : (
            data.email
          )}

          {me.roles.ROLE_VS_ADMIN ? (
            <div className=" mx-2">
              {isEditingId !== data.id ? (
                <Pencil
                  onClick={() => {
                    setIsEditingId(data.id);
                    setNewEmailValue(data.email);
                  }}
                />
              ) : (
                <CheckOneIcon onClick={async () => await updateInvitationEmail(data)} />
              )}
            </div>
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Teams</td>,
      render: (data: TInvitation) => (
        <CellContent>
          {(data?.Teams || []).map((team) => (
            <RolesCell key={team.id}>{team.name}</RolesCell>
          ))}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Status</td>,
      render: (data: TInvitation) => (
        <CellContent>
          <StatusText status={INVITATION_STATUSES[data.status as keyof typeof INVITATION_STATUSES]} />
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>EXPIRY DATE</td>,
      render: (data: TInvitation) => <CellContent>{getAmericanTime(data.expiry)}</CellContent>,
    },
    {
      headComponent: () => <td></td>,
      render: (data: TInvitation) =>
        me.roles.ROLE_ORG_ADMIN ? (
          <CellContent>
            {isLoadingId === data.id ? (
              <Loader size={10} />
            ) : data.status === 'CREATED' ? (
              <Button variant="outlined" disabled={isLoadingId === data.id} onClick={() => resendInvitation(data)}>
                Resend
              </Button>
            ) : null}
          </CellContent>
        ) : null,
    },
    {
      headComponent: () => <td></td>,
      render: (data: TInvitation) => (
        <CellContent>
          <DeleteButton title="Remove" onClick={() => openDeleteInvitationModal(data.id)}>
            <TrashBin />
          </DeleteButton>
        </CellContent>
      ),
    },
  ];

  return <CustomTable head={tableHead} data={invitations} />;
};
