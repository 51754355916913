import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { Header } from '@components/Header';
import { OrganizationCards } from '@components/todo/OrganizationCards';
import { useAuthContext } from '@providers/AuthProvider';
import { routes } from '@routes';

const Content = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);
`;

export const OrganizationsListPage = () => {
  const { me } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (me?.id && !me.organizations.length) {
      navigate(routes.noOrgs.make());
    }
  }, [me, navigate]);

  const organizationSectionTitle =
    'My Organizations' + (me.organizations.length > 0 ? ` (${me.organizations.length})` : '');

  return (
    <>
      <Helmet>
        <title>My Organizations - AutoScript</title>
      </Helmet>
      <Header title="My Organizations" />

      <Content>
        <div>
          <h4>{organizationSectionTitle}</h4>
          <OrganizationCards />
        </div>
      </Content>
    </>
  );
};
