export const TeamsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 6.6C5.27739 6.6 5.73523 6.41036 6.07279 6.07279C6.41036 5.73523 6.6 5.27739 6.6 4.8C6.6 4.32261 6.41036 3.86477 6.07279 3.52721C5.73523 3.18964 5.27739 3 4.8 3C4.32261 3 3.86477 3.18964 3.52721 3.52721C3.18964 3.86477 3 4.32261 3 4.8C3 5.27739 3.18964 5.73523 3.52721 6.07279C3.86477 6.41036 4.32261 6.6 4.8 6.6ZM5.7 20.1C6.41608 20.1 7.10284 19.8155 7.60919 19.3092C8.11554 18.8028 8.4 18.1161 8.4 17.4C8.4 16.6839 8.11554 15.9972 7.60919 15.4908C7.10284 14.9845 6.41608 14.7 5.7 14.7C4.98392 14.7 4.29716 14.9845 3.79081 15.4908C3.28446 15.9972 3 16.6839 3 17.4C3 18.1161 3.28446 18.8028 3.79081 19.3092C4.29716 19.8155 4.98392 20.1 5.7 20.1ZM18.3 21C19.0161 21 19.7028 20.7155 20.2092 20.2092C20.7155 19.7028 21 19.0161 21 18.3C21 17.5839 20.7155 16.8972 20.2092 16.3908C19.7028 15.8845 19.0161 15.6 18.3 15.6C17.5839 15.6 16.8972 15.8845 16.3908 16.3908C15.8845 16.8972 15.6 17.5839 15.6 18.3C15.6 19.0161 15.8845 19.7028 16.3908 20.2092C16.8972 20.7155 17.5839 21 18.3 21ZM11.1 13.8C12.0548 13.8 12.9705 13.4207 13.6456 12.7456C14.3207 12.0705 14.7 11.1548 14.7 10.2C14.7 9.24522 14.3207 8.32955 13.6456 7.65442C12.9705 6.97928 12.0548 6.6 11.1 6.6C10.1452 6.6 9.22955 6.97928 8.55442 7.65442C7.87928 8.32955 7.5 9.24522 7.5 10.2C7.5 11.1548 7.87928 12.0705 8.55442 12.7456C9.22955 13.4207 10.1452 13.8 11.1 13.8ZM16.5 6.6C16.9774 6.6 17.4352 6.41036 17.7728 6.07279C18.1104 5.73523 18.3 5.27739 18.3 4.8C18.3 4.32261 18.1104 3.86477 17.7728 3.52721C17.4352 3.18964 16.9774 3 16.5 3C16.0226 3 15.5648 3.18964 15.2272 3.52721C14.8896 3.86477 14.7 4.32261 14.7 4.8C14.7 5.27739 14.8896 5.73523 15.2272 6.07279C15.5648 6.41036 16.0226 6.6 16.5 6.6Z"
        fill={props.color || '#40608F'}
        stroke={props.color || '#40608F'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.14999 6.15039L7.94999 7.95039L6.14999 6.15039ZM14.7 6.60039L13.8 7.50039L14.7 6.60039ZM16.5 16.2754L13.8 12.9004L16.5 16.2754ZM7.49999 15.1504L9.29999 13.3504L7.49999 15.1504Z"
        fill={props.color || '#40608F'}
      />
      <path
        d="M6.14999 6.15039L7.94999 7.95039M14.7 6.60039L13.8 7.50039M16.5 16.2754L13.8 12.9004M7.49999 15.1504L9.29999 13.3504"
        stroke={props.color || '#40608F'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
