import styled from 'styled-components';

export const FilterTitle = styled.h3`
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 0 15px;
  color: #858dbd;
  border-bottom: 1px solid #e1e1e1;
`;
