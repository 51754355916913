import styled from 'styled-components';

export const Wrapper = styled.div`
  .container {
    background-color: #ffffff;
    width: 240px;
    box-shadow: 5px 0 15px rgba(64, 96, 143, 0.06);
    padding: 20px 10px 10px;
    overflow: hidden auto;
    align-items: center;
  }

  .grid {
    height: 100%;
    flex-wrap: nowrap;
  }

  .noScroll {
    overflow: hidden;
    padding-top: 10px;
  }
`;
