import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;

  & .left-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  & .review-activity-wrapper {
    min-width: 600px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    & .card {
      width: 100%;
    }
  }

  & .line {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  & .card {
    min-width: 280px;
    width: 100%;
    height: 170px;
    background: #ffffff;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px;
    position: relative;

    &.card-deadline {
      height: auto;
      max-height: 670px;
      width: 280px;
    }

    &.card-leaderboard {
      height: auto;
      max-height: 490px;
    }

    &.card-daily {
      height: auto;
    }

    &.card-performance {
      height: auto;
    }

    & .title {
      color: #858dbd;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .superTitle {
      color: #40608f;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .subTitle {
      color: #7cb1fe;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 47px;
      margin-top: -2px;
    }

    & .description {
      color: #858dbd;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
    }

    & .two-columns-description {
      display: flex;

      & .description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .left-side {
        border-right: 1px solid #858dbd;
        margin: 0 10px 0 0;
        padding: 0 10px 0 0;
      }

      & .left-side,
      & .right-side {
        width: calc(50% - 5px);
      }
    }
  }
`;
