import styled from 'styled-components';

export const StyledAnchor = styled.a`
  color: #858dbd;
  border-radius: 5px;
  display: flex;
  padding: 10px 0.5rem;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #f0f9ff;
    color: #858dbd;
    text-decoration: none;
  }

  &.active {
    background: #cadcf8;
    color: #40608f;
    text-decoration: none;
  }
`;
