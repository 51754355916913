import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 10000;
  background: #f4f4f4;

  .header-wrapper {
    width: 100% !important;
  }

  .content {
    width: 540px;
    height: calc(100% - 96px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    grid-gap: 40px;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #000000;

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      text-transform: capitalize;
      color: #858dbd;
      margin: 0;
    }

    .button {
      width: 150px;
    }

    .text {
      max-width: 350px;
    }

    .signInButton {
      display: flex;
      align-items: center;

      .signLink {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #858dbd;
      }
    }

    .positive-icon {
      width: 100px;
      height: 100px;

      path {
        fill: #7cb1fe;
      }
    }

    .positive-icon__external-circle {
      fill-opacity: 0.1;
    }

    .positive-icon__internal-circle {
      fill-opacity: 0.2;
    }
  }
`;
