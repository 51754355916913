import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { Header } from '@components/Header';
import { Button } from '@components/form-elements/buttons/Button';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

import { PageProvider } from './providers/PageProvider';
import { Content } from './components/Content';

export const NotificationSettingsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Notification Settings - AutoScript</title>
      </Helmet>
      <Header
        title="Notification Settings"
        leftSideContent={[
          <HeaderSwitchWrapper key="tabSwitcher">
            <Button
              onClick={() => {
                navigate(routes.notifications.make());
              }}
              className="button"
            >
              Notifications
            </Button>
            <Button
              onClick={() => {
                navigate(routes.notificationSettings.make());
              }}
              className="button active"
            >
              Settings
            </Button>
          </HeaderSwitchWrapper>,
        ]}
      />

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
