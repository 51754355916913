import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 0 15px;

  .content {
    padding: 8px 0 0;

    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #313131;
      padding: 0 0 8px;
    }
  }
`;
