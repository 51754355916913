import { useAuthContext } from '@providers/AuthProvider';

import { Wrapper } from './styles/Wrapper';

export const UserCard = () => {
  const { me } = useAuthContext();

  const welcome =
    new Date(me.createdAt).getTime() < Date.now() - 24 * 60 * 60 * 1000
      ? `Welcome back, ${me?.name}!`
      : `Welcome ${me?.name}!`;
  return (
    <Wrapper>
      <div>
        <div className="UserCard-title">{welcome}</div>
      </div>
    </Wrapper>
  );
};
