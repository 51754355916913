import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';
import { Header } from '@components/Header';
import { DisclosureSection } from '@components/DisclosureSection';
import { OrganizationCards } from '@components/todo/OrganizationCards';

import { WorkspacesCards } from './components/WorkspacesCards';
import { UserDetails } from './components/UserDetails';

const Content = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);
`;

export const AccountPage = () => {
  const [workspacesDisclosureOpen, setWorkspacesDisclosureOpen] = useState<boolean>(true);
  const [organizationsDisclosureOpen, setOrganizationsDisclosureOpen] = useState<boolean>(true);

  const { me } = useAuthContext();

  const workspaceSectionTitle = 'My Workspaces' + (me.workspaces.length > 0 ? ` (${me.workspaces.length})` : '');
  const organizationSectionTitle =
    'My Organizations' + (me.organizations.length > 0 ? ` (${me.organizations.length})` : '');

  return (
    <>
      <Helmet>
        <title>My Account - AutoScript</title>
      </Helmet>
      <Header title="My Account" />

      <Content>
        <div>
          <UserDetails />
        </div>
        <div>
          <DisclosureSection
            title={organizationSectionTitle}
            open={organizationsDisclosureOpen}
            setOpenState={setOrganizationsDisclosureOpen}
            loading={false}
          >
            <OrganizationCards />
          </DisclosureSection>
        </div>
        <div>
          <DisclosureSection
            title={workspaceSectionTitle}
            open={workspacesDisclosureOpen}
            setOpenState={setWorkspacesDisclosureOpen}
            loading={false}
          >
            <WorkspacesCards workspaces={me.workspaces} />
          </DisclosureSection>
        </div>
      </Content>
    </>
  );
};
