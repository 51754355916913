import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 100px;
  width: 100%;
  max-width: 66%;
  .content {
    margin: auto;
  }
  .positiveIcon {
    margin: 0 0 30px;
  }
  .great {
    margin-bottom: 30px;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #40608f;
    margin: 0 0 30px;
  }
  .accountCreated {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #858dbd;
    margin: 0 0 30px;
  }
`;
