import { client } from 'app/http';
import { TLicense } from 'app/types/entities/TLicense';

export const getLicenses = async (baid: string, asOneId: string): Promise<TLicense[]> => {
  if (!baid || !asOneId) {
    console.error('[getLicenses] baid or asOneId was not provided');
    return [];
  }
  const { data } = await client.get(`/billing-account/${baid}/subscription/${asOneId}/licenses`);
  return data;
};
