import { PageProvider } from './providers/PageProvider';
import { PasswordRecoveryPageContent } from './components/Content';

export const PasswordRecoveryPage = () => {
  return (
    <PageProvider>
      <PasswordRecoveryPageContent />
    </PageProvider>
  );
};
