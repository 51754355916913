import { client } from 'app/http';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TJob } from 'app/types/entities/TJob';

export const getAssignedToMeWorkspaceJobs = async (
  page = 1,
  pageSize = 25,
  orderBy = 'id',
  orderType = 'asc',
  search = '',
  workspaceId = '',
  statusFilters: string[] = [],
  dateFilter: {
    from?: Date | null;
    to?: Date | null;
  },
  signal: AbortSignal,
): Promise<TPaginatedResponse<TJob>> => {
  if (!workspaceId) {
    return {
      data: [],
      count: 0,
    };
  }
  const fromParam = Math.floor(Number(dateFilter.from) / 1000) || '';
  const toParam = Math.floor(Number(dateFilter.to) / 1000) || '';
  const response = await client.request({
    method: 'get',
    url: `/${workspaceId}/jobs/my?from=${fromParam}&to=${toParam}&pageNo=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${encodeURIComponent(
      search,
    )}&status=${statusFilters.join(',')}`,
    signal,
  });
  return response?.data || { data: [], count: 0, aborted: true };
};
