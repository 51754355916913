import { PageProvider } from '@pages/Anonymous/PasswordResetPage/providers/PageProvider';
import { PasswordResetPageContent } from '@pages/Anonymous/PasswordResetPage/components/Content';

export const PasswordResetPage = () => {
  return (
    <PageProvider>
      <PasswordResetPageContent />
    </PageProvider>
  );
};
