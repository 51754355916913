import React from 'react';

export const EyeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8383 6.21891C17.7425 2.34453 14.0672 0 10 0C5.93284 0 2.25124 2.34453 0.174129 6.21891L0 6.51119L0.161692 6.8097C2.25746 10.6841 5.93284 13.0286 10 13.0286C14.0672 13.0286 17.7488 10.7152 19.8383 6.8097L20 6.51119L19.8383 6.21891ZM10 11.7537C6.49876 11.7537 3.28358 9.80099 1.41791 6.51119C3.28358 3.22139 6.49876 1.26866 10 1.26866C13.5012 1.26866 16.6791 3.22761 18.5759 6.51119C16.6791 9.80099 13.495 11.7537 10 11.7537Z"
        fill="#858DBD"
      />
      <path
        d="M10.1803 2.26388C9.33714 2.27003 8.51466 2.52593 7.8168 2.99925C7.11894 3.47258 6.577 4.14209 6.25943 4.92323C5.94186 5.70438 5.86289 6.56212 6.03252 7.38811C6.20214 8.21411 6.61274 8.97131 7.21246 9.56409C7.81218 10.1569 8.57411 10.5586 9.40202 10.7186C10.2299 10.8786 11.0867 10.7897 11.8641 10.463C12.6415 10.1364 13.3046 9.58666 13.7698 8.88334C14.235 8.18002 14.4813 7.35462 14.4776 6.51139C14.4752 5.9501 14.362 5.3948 14.1446 4.87732C13.9271 4.35985 13.6097 3.89037 13.2105 3.49579C12.8113 3.1012 12.3382 2.78928 11.8182 2.57788C11.2982 2.36649 10.7416 2.25978 10.1803 2.26388ZM10.1803 9.55244C9.58473 9.5463 9.00421 9.3643 8.5117 9.02931C8.01918 8.69432 7.63663 8.22126 7.41209 7.66956C7.18755 7.11786 7.13103 6.51212 7.24963 5.9284C7.36824 5.34468 7.65667 4.80902 8.07871 4.3887C8.50076 3.96838 9.03759 3.68213 9.62178 3.56591C10.206 3.44969 10.8115 3.50868 11.3623 3.73547C11.9131 3.96226 12.3845 4.34674 12.7175 4.84062C13.0505 5.3345 13.2301 5.91576 13.2338 6.51139C13.2355 6.91226 13.1575 7.30947 13.0045 7.67999C12.8515 8.05051 12.6265 8.38697 12.3424 8.66985C12.0584 8.95273 11.721 9.17641 11.3499 9.32791C10.9787 9.47942 10.5812 9.55573 10.1803 9.55244Z"
        fill="#858DBD"
      />
    </svg>
  );
};
