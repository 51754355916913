import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '@routes';
import { useAPI } from '@hooks/useAPI';
import { NotificationsService } from 'app/API';
import { useAuthContext } from '@providers/AuthProvider';
import { getShortName } from '@helpers/getShortName';

import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TickingDateAgo } from '@components/Date/TickingDateAgo';
import { Button } from '@components/form-elements/buttons/Button';

import { MainContent } from './styles/MainContent';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';
import { CrossIcon } from '@components/Tag/icons/CrossIcon';

export const NotificationsPage = () => {
  const { call } = useAPI();
  const { updateMe } = useAuthContext();
  const { notificationsReceiveDate } = useAuthContext();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    setIsLoading(true);
    const data = await call(NotificationsService.getAllNotifications());
    setNotifications(data);
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [notificationsReceiveDate]);

  let content: React.ReactElement = (
    <>
      <div className="no-notifications">
        {"You'll"} get notified here when someone mentions you, your job status updates, and more
      </div>
    </>
  );
  if (notifications.length) {
    content = (
      <div className="notifications-list">
        {notifications.map((n: any) => {
          const shortName = getShortName(n.Organization?.name || 'Auto Script');
          return (
            <div className="notification" key={n.id}>
              <div className="entity-name">
                {n.Organization?.image ? <img alt="" src={n.Organization?.image} /> : shortName}
              </div>
              <div className="content">
                {!n.seen ? <div className="unseen-dot"></div> : null}
                <Button
                  className="delete-button"
                  variant="outlined"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await call(NotificationsService.deleteNotification({ id: n.id }));
                    updateMe();
                  }}
                >
                  <CrossIcon />
                </Button>
                <p className="org-name">{n.Organization?.name || 'AutoScript'}</p>
                <p className="message">{n.message}</p>
                {n.url ? (
                  <RouterLink
                    to={n.url}
                    className="link"
                    onClick={() => {
                      call(NotificationsService.setNotificationRead({ id: n.id })).then(() => {
                        updateMe();
                      });
                    }}
                  >
                    {n.url.split('/').slice(3).join('/')}
                  </RouterLink>
                ) : null}
                <p className="created">
                  <span className="comment-date">
                    <TickingDateAgo date={n.createdAt} />
                  </span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Notifications - AutoScript</title>
      </Helmet>
      <Header
        title="Notifications"
        leftSideContent={[
          <HeaderSwitchWrapper key="tabSwitcher">
            <Button
              onClick={() => {
                navigate(routes.notifications.make());
              }}
              className="button active"
            >
              Notifications
            </Button>
            <Button
              onClick={() => {
                navigate(routes.notificationSettings.make());
              }}
              className="button"
            >
              Settings
            </Button>
          </HeaderSwitchWrapper>,
        ]}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>{!isLoading ? content : null}</MainContent>
    </>
  );
};
