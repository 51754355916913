import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 72px;
  height: 72px;
  border: 1px dashed #cadcf8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;

    path {
      stroke: #cadcf8;
      fill: transparent;
    }
  }

  &:hover {
    background: #f1f5fb;
  }
`;
