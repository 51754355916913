import React from 'react';

export const Meatballs = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    // eslint-disable-next-line react/prop-types
    className={props.className || 'meatballs'}
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.25" cy="1.75" r="1.75" transform="rotate(90 12.25 1.75)" fill="#40608F" />
    <circle cx="7" cy="1.75" r="1.75" transform="rotate(90 7 1.75)" fill="#40608F" />
    <circle cx="1.75" cy="1.75" r="1.75" transform="rotate(90 1.75 1.75)" fill="#40608F" />
  </svg>
);
