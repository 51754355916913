export const JobReportsIcon = ({ color = '#858DBD' }: { color?: string }) => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.638 9.98613H3.12067V11.7587H8.638V9.98613Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1345 6.86547H3.12067V8.638H11.1345V6.86547Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.7656 13.1068H3.12067V14.8793H6.7656V13.1068Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7448 1.8724H11.1345V1.5104C11.1345 1.10982 10.9754 0.725642 10.6921 0.442387C10.4089 0.159131 10.0247 0 9.62413 0H4.63107C4.23048 0 3.84631 0.159131 3.56305 0.442387C3.2798 0.725642 3.12067 1.10982 3.12067 1.5104V1.8724H1.5104C1.10982 1.8724 0.725642 2.03153 0.442387 2.31479C0.159131 2.59804 0 2.98222 0 3.3828V16.4896C0 16.8902 0.159131 17.2744 0.442387 17.5576C0.725642 17.8409 1.10982 18 1.5104 18H12.7448C13.1454 18 13.5296 17.8409 13.8128 17.5576C14.0961 17.2744 14.2552 16.8902 14.2552 16.4896V3.3828C14.2552 2.98222 14.0961 2.59804 13.8128 2.31479C13.5296 2.03153 13.1454 1.8724 12.7448 1.8724ZM11.1345 5.51734V3.64494H12.4827V16.2275H1.77254V3.64494H3.12067V5.51734H11.1345ZM4.8932 1.77254H9.362V3.7448H4.8932V1.77254ZM13.9931 3.38284C13.9931 3.38286 13.9931 3.38283 13.9931 3.38284V16.4896C13.9931 16.4896 13.9931 16.4897 13.9931 16.4896V3.38284Z"
      fill={color}
    />
  </svg>
);
