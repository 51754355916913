import React from 'react';

import { SmallWhiteTextarea } from '@components/form-elements/Textarea/variations/SmallWhiteTextarea';

import { Block } from '@components/Block';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';

export const NotesBlock = () => {
  const { form, setFormItemValue } = usePageContext();

  const handleInputChange = async (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    setFormItemValue(name, value);
  };

  const notesDescription = <p>You can provide additional information</p>;

  return (
    <Block title="Notes" description={notesDescription}>
      <SmallWhiteTextarea
        onChange={handleInputChange}
        name="notes"
        label="Type your notes here"
        value={form.item?.notes ?? ''}
      />
    </Block>
  );
};
