export const AdminsIcon = ({ color = '#40608F' }: { color?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 5C3.80589 5 3 5.80589 3 6.8V17.6C3 18.5941 3.80589 19.4 4.8 19.4H19.2C20.1941 19.4 21 18.5941 21 17.6V6.8C21 5.80589 20.1941 5 19.2 5H4.8ZM19.2 11.4929H14.16V12.9071H19.2V11.4929ZM19.2 14.3214H14.88V15.7357H19.2V14.3214ZM19.2 8.66429H13.44V10.0786H19.2V8.66429ZM13.44 16.4429V17.15H4.8V16.4429C4.8 14.4933 6.41496 12.9071 8.4 12.9071H9.84C11.825 12.9071 13.44 14.4933 13.44 16.4429ZM11.64 9.725C11.64 11.1358 10.5564 12.2 9.12 12.2C7.6836 12.2 6.6 11.1358 6.6 9.725C6.6 8.31425 7.6836 7.25 9.12 7.25C10.5564 7.25 11.64 8.31425 11.64 9.725Z"
      fill={color}
    />
  </svg>
);
