import styled from 'styled-components';

export const Wrapper = styled.footer`
  position: absolute;
  bottom: 20px;
  color: #858dbd;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  a {
    color: #858dbd;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
  }
`;
