import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

type TProps = {
  currentTab: string;
  setCurrentTab: (value: 'All Jobs' | 'Assigned To Me') => void;
};

export const TabSwitcher = ({ currentTab, setCurrentTab }: TProps) => {
  const { organization } = useAuthContext();

  if (!['all', 'assignedAndUnassigned'].includes(organization?.permissions?.viewJobs)) {
    return null;
  }
  return (
    <HeaderSwitchWrapper key="tabSwitcher">
      <Button
        onClick={() => {
          setCurrentTab('All Jobs');
        }}
        className={`button ${currentTab === 'All Jobs' ? 'active' : ''}`}
      >
        All Jobs
      </Button>
      <Button
        onClick={() => {
          setCurrentTab('Assigned To Me');
        }}
        className={`button ${currentTab === 'Assigned To Me' ? 'active' : ''}`}
      >
        Assigned To Me
      </Button>
    </HeaderSwitchWrapper>
  );
};
