import styled from 'styled-components';
import { TUser } from 'app/types/entities/TUser';
import { useAuthContext } from '@providers/AuthProvider';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { OrganizationsService } from 'app/API';
import { Switch } from '@components/form-elements/Switch';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div``;

const RightSideColumns = styled.div`
  display: flex;
  gap: 10px;
`;

const InputBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 650px;
  flex-wrap: wrap;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }

  .value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #00122d;
    display: flex;
    gap: 10px;

    .pencil {
      cursor: pointer;

      &:hover {
        path {
          fill: #40608f;
        }
      }
    }
  }
`;

const AvatarBlock = styled.div`
  padding: 0 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
`;
const Avatar = styled.img`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
`;
const AvatarName = styled.div`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  text-transform: capitalize;
`;

type TProps = {
  user: TUser;
  reload: () => void;
};

export const UserDetails = ({ user, reload }: TProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const { organization } = useAuthContext();
  const userHasManageMembersPermission = organization?.permissions?.manageMembers;

  if (!user) {
    return null;
  }

  const orgRelation = (user.orgRelations ?? []).find((rel) => rel.slug === organization?.slug);

  const renderAvatar = () => {
    if (!user) {
      return '';
    }
    const avatarName = `${user?.name[0]}${user?.lastname[0]}`;

    return (
      <AvatarBlock>
        {user?.avatar ? <Avatar src={user.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
      </AvatarBlock>
    );
  };

  const editConcurrentLimit = () => {
    openModal(ModalNamesEnum.EditConcurrentJobsLimit, {
      onSuccess: () => {
        reload();
        closeModal();
      },
      uid: user?.id,
      concurrentJobsLimit: user?.concurrentJobsLimit ?? 0,
    });
  };

  const changeCanManageLayouts = async () => {
    await call(
      OrganizationsService.setMemberCanManageLayoutFlag({
        oid: organization?.id || '',
        memberId: orgRelation?.membershipId ?? '',
        requestBody: {
          value: !orgRelation?.canManageLayouts,
        },
      }),
    );
    reload();
  };

  return (
    <>
      <Wrapper>
        <LeftSide>
          <h3>General</h3>
        </LeftSide>

        <RightSide>
          {renderAvatar()}
          <RightSideColumns>
            <InputBlockWrapper>
              <InputWrapper>
                <div className="label">Name</div>
                <div className="value">
                  {user.name} {user.lastname}
                </div>
              </InputWrapper>
              <InputWrapper>
                <div className="label">Email</div>
                <div className="value">{user.email}</div>
              </InputWrapper>
              <InputWrapper>
                <div className="label">Phone</div>
                <div className="value">{user.phone}</div>
              </InputWrapper>
              {userHasManageMembersPermission ? (
                <>
                  <InputWrapper>
                    <div className="label">Concurrent jobs limit</div>
                    <div className="value">
                      {user.concurrentJobsLimit || 'Not set'}
                      {userHasManageMembersPermission ? (
                        <PencilIcon className="pencil" onClick={() => editConcurrentLimit()} />
                      ) : null}
                    </div>
                  </InputWrapper>

                  <InputWrapper>
                    <div className="label">Can manage cloud layouts</div>
                    <div className="value">
                      <Switch
                        checked={orgRelation?.canManageLayouts}
                        onChange={changeCanManageLayouts}
                        disabled={!organization?.permissions?.manageOrganization}
                      />
                    </div>
                  </InputWrapper>
                </>
              ) : null}
            </InputBlockWrapper>
          </RightSideColumns>
        </RightSide>
      </Wrapper>
    </>
  );
};
