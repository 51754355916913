import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAPI } from '@hooks/useAPI';
import { ApiKeysService } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { TOrganization } from 'app/types/entities/TOrganization';

import { CustomTable } from '@components/Table';
import { Button } from '@components/form-elements/buttons/Button';
import { DisclosureSection } from '@components/DisclosureSection';
import { TrashBin } from '@components/icons/TrashBin';
import { TAPIKey } from '../../../types/entities/TAPIKey';
import { dateToInX } from '@helpers/dateToInX';

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }

  table {
    width: 100%;

    .theadTr {
      border-bottom: none;
    }

    thead tr td,
    thead tr th {
      height: 50px;
    }
  }
`;

const LastCellContent = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 6px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;

    :hover {
      background: #ff9191;

      path {
        fill: #fff;
        color: #fff;
      }
    }
  }
`;
const CellContent = styled.div`
  margin: 0;
  padding: 15px 0;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

type TProps = {
  org: TOrganization;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export const ApiKeysList = ({ org, loading, setLoading }: TProps) => {
  const { call } = useAPI();
  const [keys, setKeys] = useState<TWorkspace[]>([]);
  const [disclosureOpen, setDisclosureOpen] = useState<boolean>(true);
  const { openModal, closeModal } = useModalContext();
  const { organization } = useAuthContext();
  const orgId = org.id;

  const sectionTitle = 'API Keys' + (keys?.length > 0 ? ` (${keys?.length})` : '');

  const load = () => {
    setLoading(true);
    call(ApiKeysService.findAll({ oid: organization?.id ?? '' })).then((keys) => {
      setLoading(false);
      setKeys(keys);
    });
  };

  useEffect(() => {
    load();
  }, [org, orgId]);

  const createAPIKey = () => {
    openModal(ModalNamesEnum.CreateAPIKey, {
      onSuccess: () => {
        closeModal();
        load();
      },
    });
  };

  const deleteAPIKey = (data: TAPIKey) => {
    openModal(ModalNamesEnum.DeleteAPIKey, {
      id: data.id,
      organizationId: data.organizationId,
      onSuccess: () => {
        closeModal();
        load();
      },
    });
  };

  const tableHead = [
    {
      headComponent: () => <th />,
      render: (data: TAPIKey) => <CellContent style={{ width: '350px' }}>{data.id}</CellContent>,
    },
    {
      headComponent: () => <th />,
      render: (data: TAPIKey) => (
        <CellContent>
          <span style={{ display: 'block' }}>
            {data.expirationDate
              ? new Date(data.expirationDate) > new Date()
                ? `Expires: ${dateToInX(data.expirationDate, false)}`
                : 'Expired'
              : 'Never expires'}
          </span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }} />,
      render: (data: TAPIKey) => (
        <LastCellContent>
          {organization?.permissions?.manageOrganization && <TrashBin onClick={() => deleteAPIKey(data)} />}
        </LastCellContent>
      ),
    },
  ];

  return (
    <Wrapper className="flex flex-col">
      <div>
        <DisclosureSection
          title={sectionTitle}
          open={disclosureOpen}
          setOpenState={setDisclosureOpen}
          loading={loading}
        >
          <CustomTable head={tableHead} data={keys} noHead={true} />
          {keys.length < 5 ? (
            <div className="grid place-items-end">
              <div className="w-36">
                <Button className="my-2" disabled={false} variant="outlined" onClick={createAPIKey}>
                  Create new API Key
                </Button>
              </div>
            </div>
          ) : null}
        </DisclosureSection>
      </div>
    </Wrapper>
  );
};
