import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 100px;
  width: 100%;
  max-width: 66%;

  .welcomeHeader {
    color: #40608f;
    margin: 0 0 30px;
    font-size: 42px;
    line-height: 130%;
    font-weight: 600;
  }
  .signInToYourAccount {
    text-align: center;
    margin: 0 0 50px;
    color: #40608f;
    line-height: 130%;
    font-size: 17px;
    font-weight: 600;
  }
  .form {
    text-align: initial;
  }
  content {
    margin: auto;
  }
  .forgotPassword {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline;
    margin: 0 0 30px;
    color: #858dbd;
    &:hover {
      color: #858dbd;
      text-decoration: none;
    }
  }
  .dontHaveAnAccount {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #40608f;
    text-align: center;
    margin: 0 0 30px;
  }
  .signUp {
    color: #40608f;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #40608f;
      text-decoration: none;
    }
  }
  .formError {
    margin-bottom: 30px;
  }
  .margin30 {
    margin-bottom: 30px;
  }
`;
