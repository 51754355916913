import { useAuthContext } from '@providers/AuthProvider';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Wrapper } from './styles/Wrapper';

export const TopUpButton = () => {
  const { openModal } = useModalContext();
  const { organization } = useAuthContext();

  if (
    !organization?.billingAccount ||
    !organization?.permissions?.viewBilling ||
    !organization?.permissions?.manageBilling
  ) {
    return null;
  }

  const openTopUpModal = () => {
    if (!organization?.permissions?.viewBilling || !organization?.permissions?.manageBilling) {
      return;
    }
    openModal(ModalNamesEnum.TopUpCreditBalance, {
      billingAccountId: organization?.billingAccount?.id,
    });
  };

  return (
    <Wrapper>
      <button onClick={openTopUpModal}>
        <div className="inner">Top up</div>
      </button>
    </Wrapper>
  );
};
