import styled from 'styled-components';

import { DatePicker } from '@components/form-elements/DatePicker';

import { Block } from '@components/Block';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';

const DatePickerWrapper = styled.div`
  padding: 0 0 12px;

  input {
    background: #ffffff;
    padding: 6px 10px 6px 40px;
  }

  .icon {
    right: auto;
    left: 15px;
  }
`;

export const DeadlineBlock = () => {
  const { form, setFormItemValue } = usePageContext();
  const handleDeadlineChange = async (deadline: Date | null) => {
    setFormItemValue('deadline', deadline);
  };

  return (
    <Block title="Deadline">
      <DatePickerWrapper>
        <DatePicker
          label="Deadline"
          dateFormat="MM/dd/yyyy"
          minDate={new Date()}
          maxDate={new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)}
          value={form.item?.deadline ? new Date(form.item?.deadline) : null}
          onChange={handleDeadlineChange}
        />
      </DatePickerWrapper>
    </Block>
  );
};
