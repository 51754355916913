import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { Header } from '@components/Header';
import { VsLogo } from '@components/Logo';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { getInvitation, TResult } from './queries/getInvitation';
import { Wrapper } from './styles/Wrapper';
import { Content } from './components/Content';
import { NoUserContent } from './components/NoUserContent';

export const AcceptInvitationPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{
    id: string;
  }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const email = urlSearchParams.get('email');
  const [invitation, setInvitation] = useState<TResult>();

  const [isLoading, setIsLoading] = useState(true);
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    (async () => {
      const invitation = await getInvitation(id ?? '');
      setInvitation(invitation);
      if (!invitation || invitation.status !== 'CREATED') {
        return navigate('/');
      }
      // todo: check if logged in if this is the same user or not
      // setOrgSlug(invitation.Organization?.slug);
      setOrgName(invitation.organizationName ?? '');
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <Helmet>
          <title>Request access - AutoScript</title>
        </Helmet>
        <LinearProgressLoader active={isLoading} />
      </Wrapper>
    );
  }

  // todo: when there's no userId in the invitation we should create user in the process of accepting it or ask user to sign up
  if (!invitation?.userId) {
    return (
      <Wrapper>
        <Helmet>
          <title>Request access - AutoScript</title>
        </Helmet>
        <NoUserContent id={id ?? ''} orgName={orgName} email={email} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Request access - AutoScript</title>
      </Helmet>
      <Header title={<VsLogo />} />
      <Content id={id ?? ''} orgName={orgName} setIsLoading={setIsLoading} />
    </Wrapper>
  );
};
