import { client } from 'app/http';

export type TResult = {
  userId?: string;
  organizationName: string;
  status: string;
};

export const getInvitation = async (id: string): Promise<TResult | undefined> => {
  const { data } = await client.get(`/invitations/${id}`);
  return data.result ?? {};
};
