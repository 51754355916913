import styled from 'styled-components';

const Box = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

type TProps = {
  paymentIntent: { url: string };
};

export const PaymentIntentBox = ({ paymentIntent }: TProps) => {
  if (!paymentIntent?.url) {
    return null;
  }
  return (
    <Box>
      <iframe src={paymentIntent.url} width={600} height={600} title="frame"></iframe>
    </Box>
  );
};
