import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthContext } from '@providers/AuthProvider';
import { updateUserDisabled } from '@queries/organizations/updateUserDisabled';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { routes } from '@routes';

import { Desktop } from '@components/icons/Desktop';
import { Done } from '@components/icons/Done';
import { DoubleDone } from '@components/icons/DoubleDone';
import { Switch } from '@components/form-elements/Switch';
import { SortableHead } from '@components/Table/SortableHead';
import { DiagonalCross } from '@components/icons/DiagonalCross';
import { SortingDirection } from '@components/Table/SortingDirection';
import { CustomTable } from '@components/Table';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { TrashBin } from '@components/icons/TrashBin';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { TRole } from 'app/types/entities/TRole';
import { TUser } from 'app/types/entities/TUser';
import { TeamsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const CellContent = styled.div`
  margin: 0;
`;

const FlexCellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  min-height: 88px;
  display: flex;
  align-items: center;
`;
const CellNoEllipsisContent = styled.div`
  margin: 0;
  overflow: visible !important;
  display: flex;
  gap: 10px;
`;
const SwitchWrapper = styled.span`
  display: block;
  margin: 0;
  text-align: center;
`;

type TeamsCellProps = {
  color?: string;
};

const TeamsCell = styled.div<TeamsCellProps>`
  /* height: 22px; */
  padding: 6px;
  background-color: ${(props) => props.color || 'white'};
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 90%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  text-wrap: nowrap !important;
  white-space: nowrap !important;

  svg {
    cursor: pointer;

    path {
      stroke: #ffffff;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const DeleteButton = styled(StyledIconButton)`
  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
const Avatar = styled.img`
  width: 30px;
  height: 30px;
  background: #cadcf8;
  border-radius: 5px;
`;
const AvatarName = styled.div`
  width: 30px;
  height: 30px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
`;

type TProps = {
  setIsLoading: (isLoading: boolean) => void;
  users: any;
  setUsers: (users: any) => void;
  sortingField: string;
  setSortingField: (sortingField: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (arg: any) => void;
  reload: () => void;
};

export const UsersTable = ({
  setIsLoading,
  users,
  setUsers,
  sortingField,
  setSortingField,
  sortingReverse,
  setSortingReverse,
  reload,
}: TProps) => {
  const { call } = useAPI();
  const { me, organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();

  const handleDeleteUserTeam = async (team: TRole, userId: string) => {
    await call(TeamsService.removeTeamFromUser({ oid: organization?.id ?? '', userId, id: team.id }));
    reload();
  };

  const onDeleteMemberClick = (userId: string) => {
    // console.log('onDeleteMemberClick', userId);
    openModal(ModalNamesEnum.DeleteMemberModal, {
      userId,
      organizationId: organization?.id,
      onSuccess: () => {
        reload();
        closeModal();
      },
    });
  };

  const toggleSort = (field: string) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse((prevState: boolean) => !prevState);
    }
  };

  const handleEnabledChange = async (user: TUser) => {
    setIsLoading(true);
    const userIndex = users.data.findIndex((item: TUser) => item === user);
    const newData = Object.assign({}, users);
    const newDisabledState = !user.disabledInOrg;
    await updateUserDisabled(user.id, organization?.id || '', newDisabledState);
    newData.data[userIndex].disabledInOrg = newDisabledState;
    setUsers(newData);
    setIsLoading(false);
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TUser) => {
        const avatarName = `${data?.name[0]}${data?.lastname[0]}`;
        return (
          <FlexCellContent>
            {data?.avatar ? <Avatar src={data.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('name')}>
          Name
          {sortingField === 'name' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => (
        <FlexCellContent>
          <StyledLink to={routes.userDetails.make(organization?.slug ?? '', data.id)}>
            {data.name} {data.lastname}
          </StyledLink>
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contacts</td>,
      render: (data: TUser) => (
        <FlexCellContent>
          <CellContent>
            <span style={{ display: 'block' }}>{data.email}</span>
            <span style={{ display: 'block' }}>{data.phone}</span>
          </CellContent>
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Enabled</td>,
      render: (data: TUser) => {
        let onChange = () => handleEnabledChange(data);
        if (me.id === data.id) {
          onChange = async () => {};
        }
        return (
          <FlexCellContent>
            <SwitchWrapper>
              <Switch checked={!data.disabledInOrg} onChange={onChange} disabled={me.id === data.id} />
            </SwitchWrapper>
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Teams</td>,
      render: (data: TUser) => {
        const teams = (data.teams ?? []).filter((team) => team.organizationId === organization?.id);
        return (
          <FlexCellContent>
            <CellNoEllipsisContent>
              {(teams || []).map((item: TRole) => (
                <TeamsCell key={item.name} color={item.colour}>
                  {item.name}
                  {teams.length > 1 && <DiagonalCross onClick={() => handleDeleteUserTeam(item, data.id)} />}
                </TeamsCell>
              ))}
            </CellNoEllipsisContent>
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => <FlexCellContent>{getAmericanTime(data.createdAt)}</FlexCellContent>,
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          CLAIMED JOB LIMIT
        </SortableHead>
      ),
      render: (data: TUser) => (
        <FlexCellContent className="flex justify-center">{data.concurrentJobsLimit}</FlexCellContent>
      ),
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          MANAGE CLOUD LAYOUTS
        </SortableHead>
      ),
      render: (data: TUser) => {
        return (
          <FlexCellContent className="flex justify-center">
            {data.canManageLayouts ? <Done width="16px" /> : null}
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => (
        <td style={{ width: '3%', paddingTop: '6px' }} title="Downloaded & Signed in with Desktop App">
          <Desktop width="16px" />
        </td>
      ),
      render: (data: TUser) => (
        <FlexCellContent>
          {data.signedInWithDesktop ? <DoubleDone width="24px" /> : data.downloadedDesktop ? <Done width="16px" /> : ''}
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%', paddingTop: '6px' }} title=""></td>,
      render: (data: TUser) => (
        <FlexCellContent>
          {organization?.permissions?.manageMembers && data.id !== me.id ? (
            <DeleteButton title="Delete this user from the Organization" onClick={() => onDeleteMemberClick(data.id)}>
              <TrashBin />
            </DeleteButton>
          ) : null}
        </FlexCellContent>
      ),
    },
  ];

  return <CustomTable head={tableHead} data={users.data} />;
};
