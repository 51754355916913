import styled from 'styled-components';

import { routes } from '@routes';
import { TProps } from './types/TProps';

import { TCheckoutEvent } from 'app/types/entities/TCheckoutEvent';
import { useAuthContext } from '@providers/AuthProvider';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';

import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { CustomTable } from '@components/Table';
import { StatusText } from '@components/Table/StatusText';

import { CellContent } from '../../styles/CellContent';
import { StyledLink } from '../../styles/StyledLink';

const StatusCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 80px;

  &.with-history {
    padding-right: 28px;
  }
`;

const JobTitleCell = styled.div`
  padding: 8px 0;
`;

export const InlineJobsTable = ({ data }: TProps) => {
  const { organization } = useAuthContext();
  const colSpan = 9;

  if (!data.checkoutEvents?.length) {
    return (
      <tr>
        <td colSpan={colSpan} style={{ height: '100px' }}>
          <div className="flex items-center justify-center" style={{ height: '100px', position: 'relative' }}>
            <div>No data available for this member</div>
          </div>
        </td>
      </tr>
    );
  }

  const generateJobLink = (jobId: string, workspaceSlug: string) => {
    return routes.jobDetails.make(organization?.slug ?? '', workspaceSlug ?? '', jobId);
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '30%' }}>Job Title</td>,
      render: (data: TCheckoutEvent) => (
        <JobTitleCell>
          <div>
            {data.Job?.removed ? (
              data.Job?.name
            ) : (
              <StyledLink to={generateJobLink(data.Job?.id ?? '', data.Job?.Workspace?.slug ?? '')}>
                {data.Job?.name}
              </StyledLink>
            )}
          </div>
          <div style={{ fontSize: '10px', lineHeight: '16px', color: '#B4B9D9' }}>{data.Job?.id}</div>
        </JobTitleCell>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>STT Pages Count</td>,
      render: (data: TCheckoutEvent) => <CellContent>{data.FinalVersion?.Metrics?.pagesCount ?? 0}</CellContent>,
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>CheckOut Date</td>,
      render: (data: TCheckoutEvent) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>CheckIn Date</td>,
      render: (data: TCheckoutEvent) => (
        <CellContent>{data.finalizedAt ? getAmericanTime(data.finalizedAt) : '-'}</CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Status</td>,
      render: (data: TCheckoutEvent) => {
        return (
          <StatusCell>
            <StatusText status={JOB_STATUSES_ENUM[data.jobStatus]} />
          </StatusCell>
        );
      },
    },
  ];

  return (
    <tr>
      <td colSpan={colSpan}>
        <CustomTable head={tableHead} data={data.checkoutEvents} fullPage={false} />
      </td>
    </tr>
  );
};
