import { PageProvider } from '@pages/Anonymous/LoginPage/providers/PageProvider';
import { LoginPageContent } from '@pages/Anonymous/LoginPage/components/Content';

export const LoginPage = () => {
  return (
    <PageProvider>
      <LoginPageContent />
    </PageProvider>
  );
};
