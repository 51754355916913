export function msToDaysHoursMinutes(ms: number): string {
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));
  const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));

  const daysStr = days > 0 ? `${days} days ` : '';
  const hoursStr = hours > 0 ? `${hours} hours ` : '';
  const minutesStr = minutes > 0 ? `${minutes} minutes` : '';

  return `${daysStr}${hoursStr}${minutesStr}`.trim() || '< 1 minute';
}
