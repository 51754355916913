import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(RouterLink)`
  color: #858dbd;
  border-radius: 5px;
  display: flex;
  padding: 10px;
  font-size: 16px;
  width: 220px;
  position: relative;
  margin: 0 0 1px;
  cursor: pointer;
  text-decoration: none;

  span {
    color: #858dbd;

    svg.folder {
      path {
        stroke: #858dbd;
      }
    }
  }

  &:hover {
    background: #f0f9ff;
    text-decoration: none;

    span {
      color: #858dbd;

      svg.folder {
        path {
          stroke: #858dbd;
        }
      }
    }
  }

  &.active {
    background: #cadcf8;

    span {
      color: #40608f;

      svg {
        path {
          fill: #40608f;
        }
      }

      svg.folder {
        path {
          fill: none;
          stroke: #40608f;
        }
      }
    }
  }
`;
