import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TOrganization } from 'app/types/entities/TOrganization';

import { DisclosureSection } from '@components/DisclosureSection';
import { TeamCards } from '@components/todo/TeamCards';
import { getTeams } from '@queries/organizations/getTeams';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { TRole } from 'app/types/entities/TRole';

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }
`;

type TProps = {
  organization: TOrganization;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export const TeamsList = ({ organization, loading, setLoading }: TProps) => {
  const { openModal, closeModal } = useModalContext();
  const [teams, setTeams] = useState<TRole[]>([]);
  const [teamsDisclosureOpen, setTeamsDisclosureOpen] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getTeams(organization.id).then((teams) => {
      setTeams((teams || []).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
      setLoading(false);
    });
  }, [organization]);

  const handleRemoval = (teamId: string) => {
    openModal(ModalNamesEnum.DeleteTeamModal, {
      teamId,
      onSuccess: () => {
        setTeams(teams.filter((team) => team.id !== teamId));
        closeModal();
      },
    });
  };

  const handleAddTeam = () => {
    openModal(ModalNamesEnum.NewTeamModal, {
      organizationId: organization.id,
      onSuccess: (team: TRole) => {
        setTeams([...teams, team]);
        closeModal();
      },
    });
  };

  const teamsTitle = 'Teams' + (teams.length > 0 ? ` (${teams.length})` : '');

  return (
    <Wrapper className="flex flex-col">
      <div>
        <DisclosureSection
          title={teamsTitle}
          open={teamsDisclosureOpen}
          setOpenState={setTeamsDisclosureOpen}
          loading={loading}
        >
          <TeamCards teams={teams} handleRemoval={(teamId) => handleRemoval(teamId)} addTeam={handleAddTeam} />
        </DisclosureSection>
      </div>
    </Wrapper>
  );
};
