import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 15px;

  .UserCard-title {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #40608f;
  }
`;
