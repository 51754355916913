import styled from 'styled-components';

export const Wrapper = styled.div`
  display: contents;

  .root {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
    width: 100%;
    max-width: 66%;

    .header {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      margin: 0 0 30px;
      color: #40608f;
    }
  }

  .form {
    text-align: initial;
  }

  .createNewAccount {
    text-align: center;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 50px;
    color: #40608f;
  }

  .content {
    margin: auto;
  }

  .haveAnAccount {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #40608f;
    text-align: center;
    margin: 0 0 30px;
  }

  .signIn {
    color: #40608f;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: #40608f;
      text-decoration: none;
    }
  }

  .formError {
    margin-bottom: 30px;
  }

  .margin30 {
    margin-bottom: 30px;
  }

  .margin20 {
    margin-bottom: 20px;
  }

  .positiveicon {
    margin: 0 0 30px;
  }

  .checkEmailIcon {
    margin: 0 0 30px;
  }

  .completeCheckYourEmail {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #40608f;
    margin: 0 0 30px;
  }

  .completeWeSentAConfirmationEmail {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #858dbd;
    margin: 0 0 30px;
  }

  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #40608f;
    margin: 0 0 30px;
  }

  .enterPhoneNumber {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    margin: 0 0 30px;
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    color: #858dbd;
    white-space: nowrap;

    & a {
      text-decoration: underline;
      cursor: pointer;
      padding-left: 2px;
      padding-right: 2px;
      color: #40608f;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .flexCode {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 20px;

    input {
      width: 10px;
      text-align: center;
      padding: 0;
    }
  }

  .closeButton {
    width: 100px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    margin-top: 20px;
  }
`;
