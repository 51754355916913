import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { Main } from '@wrappers/Main';
import { InitProvider } from '@providers/InitProvider';

export function App() {
  useEffect(() => {
    const isLocationSaved = window.localStorage.getItem('redirectUrl');

    if (!isLocationSaved) {
      window.localStorage.setItem('redirectUrl', window.location.href);
    }
  }, []);

  return (
    <HelmetProvider>
      <InitProvider>
        <Main />
      </InitProvider>
    </HelmetProvider>
  );
}
