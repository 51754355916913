import styled from 'styled-components';
import { Hammer } from '@components/icons/Hammer';

export const HammerBackgroundIcon = styled(Hammer)`
  position: absolute;
  width: 124px;
  height: 124px;
  bottom: 22px;
  left: 145px;
  z-index: 1;
  transition: all 0.1s ease-in-out;
`;
