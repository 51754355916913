import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAPI } from '@hooks/useAPI';

import { JobsService, UserService } from 'app/API';
import { getDownloadDesktopAppLink } from '@helpers/getDownloadDesktopAppLink';
import { Button } from '@components/form-elements/buttons/Button';

import Wave from './wave.svg';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { getASDProtocolLink } from '@helpers/jobLinkHelpers';

const Root = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 130px;
  position: relative;
  text-align: center;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
`;

const ClickOpenBelow = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding: 40px;

  p {
    margin: 0;
  }
`;
const LaunchButtonWrapper = styled.div`
  padding: 0 40px 40px;
`;
const WaveWrapper = styled.div`
  padding: 0 40px 40px;

  svg {
    margin: 0 auto;
  }
`;
const LaunchButton = styled(Button)`
  width: 190px;
`;
const DontHaveAutoScriptInstalled = styled.div`
  padding: 0 40px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;
const DownloadNow = styled.a`
  display: block;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  padding: 0 40px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #858dbd;
`;

const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 0 50px;
  color: #858dbd;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  a {
    color: #858dbd;
  }
`;
const TermsPrivacy = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  padding: 20px 40px 10px;
`;
const CopyRight = styled.div`
  padding: 0 40px;
`;

export const ShareJobPage = () => {
  const { call } = useAPI();

  const { jobId } = useParams<{ jobId: string }>();
  const [searchParams] = useSearchParams();
  const [isLocked, setIsLocked] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const launchHandler = () => {
    jobId && window.open(new URL(getASDProtocolLink(jobId, searchParams.get('version') ?? undefined)), '_self');
  };

  useEffect(() => {
    jobId &&
      call(JobsService.getJobLockedStatus({ id: jobId }))
        .then((isLockedResult) => {
          // console.log('isLockedResult', isLockedResult);
          setIsLocked(isLockedResult.result);
          setIsLoading(false);
          if (!isLockedResult.result) {
            setTimeout(launchHandler, 1000);
          }
        })
        .catch(() => {
          // FIXME: The error is because the `isLockedResult` is undefined when the request sends an error status
          setIsLoading(false);
          setNotFound(true);
        });
  }, [jobId]);

  if (isLoading) {
    return (
      <Root>
        <AbsoluteSpinner />
      </Root>
    );
  }

  if (isLocked) {
    return (
      <Root>
        <ClickOpenBelow>
          <p>
            This job is <b>locked</b>. In order to unlock this job you need to <b>pay the remaining sum</b>
          </p>
        </ClickOpenBelow>

        <Footer>
          <TermsPrivacy>
            <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </TermsPrivacy>
          <CopyRight>&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</CopyRight>
        </Footer>
      </Root>
    );
  }

  if (notFound) {
    return (
      <Root>
        <ClickOpenBelow>
          <p>
            Sorry, this job was <b>not found</b>.
          </p>
        </ClickOpenBelow>

        <Footer>
          <TermsPrivacy>
            <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </TermsPrivacy>
          <CopyRight>&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</CopyRight>
        </Footer>
      </Root>
    );
  }

  return (
    <Root>
      <ClickOpenBelow>
        <p>
          Click <b>Open AutoScript Desktop App</b> on the dialog shown by your browser.
        </p>
        <p>
          If you don’t see a dialog, click <b>Launch App</b> below.
        </p>
      </ClickOpenBelow>
      <LaunchButtonWrapper>
        <LaunchButton size="small" onClick={launchHandler}>
          Launch AutoScript App
        </LaunchButton>
      </LaunchButtonWrapper>
      <WaveWrapper>
        <Wave />
      </WaveWrapper>
      <DontHaveAutoScriptInstalled>Don’t have AutoScript Desktop App installed?</DontHaveAutoScriptInstalled>
      <DownloadNow
        href={getDownloadDesktopAppLink()}
        target="_blank"
        onClick={() => call(UserService.downloadedClient())}
      >
        Download Now
      </DownloadNow>

      <Footer>
        <TermsPrivacy>
          <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          |{' '}
          <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </TermsPrivacy>
        <CopyRight>&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</CopyRight>
      </Footer>
    </Root>
  );
};
