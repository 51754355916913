import { Button } from '@components/form-elements/buttons/Button';
import { Modal } from '@components/Modal/Modal';

import { ModalWrapper } from './styles/ModalWrapper';
import { TProps } from './types/TProps';

export const AgreementModal = ({ isOpen, setIsOpen }: TProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalWrapper>
        <iframe src="/agreement.html" title="agreement" width="100%" height="100%" frameBorder={0} />
        <Button className="closeButton" size="small" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </ModalWrapper>
    </Modal>
  );
};
