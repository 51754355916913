import styled from 'styled-components';

export const Close = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
    fill: #858dbd;
  }
`;
