import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { routes } from '@routes';

import { LoginLayout } from '@layouts/login';

import { LoginPage } from 'app/pages/Anonymous/LoginPage';
import { RegistrationPage } from 'app/pages/Anonymous/RegistrationPage';
import { PasswordResetPage } from 'app/pages/Anonymous/PasswordResetPage';
import { PasswordRecoveryPage } from 'app/pages/Anonymous/PasswordRecoveryPage';
import { PaymentResult } from 'app/pages/Anonymous/PaymentResult';
import { RegistrationSuccessPage } from 'app/pages/Anonymous/RegistrationSuccessPage';
import { RegistrationTokenExpiredPage } from 'app/pages/Anonymous/RegistrationTokenExpiredPage';
import { AccountDisabledPage } from 'app/pages/Anonymous/AccountDisabled';
import { SSOLogin } from 'app/pages/Anonymous/SSOLogin';

export const UnauthenticatedRoutes = () => {
  return (
    <Switch>
      <Route
        path={routes.accountDisabled.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Account Disabled - AutoScript</title>
            </Helmet>
            <AccountDisabledPage />
          </LoginLayout>
        }
      />

      <Route path={routes.paymentSuccess.pattern} element={<PaymentResult isFailure={false} />} />

      <Route path={routes.paymentFailure.pattern} element={<PaymentResult isFailure={true} />} />

      <Route
        path={routes.signin.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Login - AutoScript</title>
            </Helmet>
            <LoginPage />
          </LoginLayout>
        }
      />

      <Route
        path={routes.signup.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Sign Up - AutoScript</title>
            </Helmet>
            <RegistrationPage />
          </LoginLayout>
        }
      />

      <Route
        path={routes.passwordRecovery.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Password Recovery - AutoScript</title>
            </Helmet>
            <PasswordRecoveryPage />
          </LoginLayout>
        }
      />

      <Route
        path={routes.passwordReset.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Password Recovery - AutoScript</title>
            </Helmet>
            <PasswordResetPage />
          </LoginLayout>
        }
      />

      <Route
        path={routes.registerSuccess.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Registration - AutoScript</title>
            </Helmet>
            <RegistrationSuccessPage />
          </LoginLayout>
        }
      />

      <Route
        path={routes.registerTokenExpired.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Registration - AutoScript</title>
            </Helmet>
            <RegistrationTokenExpiredPage />
          </LoginLayout>
        }
      />

      <Route
        path={routes.ssoSignin.pattern}
        element={
          <LoginLayout>
            <Helmet>
              <title>Login - AutoScript</title>
            </Helmet>
            <SSOLogin />
          </LoginLayout>
        }
      />

      <Route path="*" element={<Navigate to={routes.signin.pattern} replace />} />
    </Switch>
  );
};
