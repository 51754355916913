export const AddressIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6984 12.3756C11.1682 12.6896 11.7206 12.8571 12.2857 12.8571C13.0432 12.8562 13.7694 12.5549 14.305 12.0193C14.8406 11.4837 15.142 10.7575 15.1429 10C15.1429 9.43491 14.9753 8.88251 14.6613 8.41265C14.3474 7.9428 13.9012 7.57659 13.3791 7.36034C12.857 7.14409 12.2825 7.08751 11.7283 7.19775C11.1741 7.308 10.665 7.58011 10.2654 7.97969C9.86583 8.37927 9.59372 8.88836 9.48347 9.4426C9.37323 9.99683 9.42981 10.5713 9.64606 11.0934C9.86231 11.6155 10.2285 12.0617 10.6984 12.3756ZM11.3333 8.57462C11.6152 8.38625 11.9467 8.28571 12.2857 8.28571C12.7402 8.28617 13.176 8.46692 13.4974 8.78832C13.8188 9.10971 13.9995 9.54548 14 10C14 10.3391 13.8995 10.6705 13.7111 10.9524C13.5227 11.2343 13.255 11.454 12.9417 11.5838C12.6285 11.7135 12.2838 11.7475 11.9513 11.6813C11.6187 11.6152 11.3133 11.4519 11.0735 11.2122C10.8338 10.9724 10.6705 10.667 10.6044 10.3344C10.5382 10.0019 10.5722 9.65721 10.7019 9.34397C10.8317 9.03072 11.0514 8.76299 11.3333 8.57462Z"
      fill="#40608F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46515 14.3149L12.2857 20L17.108 14.3126C17.1337 14.2823 17.3052 14.0571 17.3052 14.0571L17.3057 14.0554C18.1288 12.9712 18.5734 11.6469 18.5714 10.2857C18.5714 8.61864 17.9092 7.01984 16.7304 5.84104C15.5516 4.66224 13.9528 4 12.2857 4C10.6186 4 9.01985 4.66224 7.84105 5.84104C6.66225 7.01984 6.00001 8.61864 6.00001 10.2857C5.99801 11.6476 6.44285 12.9724 7.26629 14.0571C7.33188 14.1436 7.39817 14.2295 7.46515 14.3149ZM8.24033 13.4494C8.20703 13.4056 8.17829 13.3686 8.17829 13.3686C7.50502 12.4819 7.14166 11.399 7.14286 10.2857C7.14286 8.92174 7.6847 7.61364 8.64917 6.64917C9.61364 5.68469 10.9218 5.14286 12.2857 5.14286C13.6497 5.14286 14.9578 5.68469 15.9223 6.64917C16.8867 7.61364 17.4286 8.92174 17.4286 10.2857C17.4299 11.3983 17.0664 12.4806 16.3937 13.3669C16.3937 13.3669 16.3856 13.3776 16.3732 13.394C16.3345 13.445 16.2533 13.5522 16.2343 13.576L12.2857 18.2331L8.34229 13.5823C8.32591 13.5619 8.27958 13.501 8.24033 13.4494Z"
      fill="#40608F"
    />
  </svg>
);
