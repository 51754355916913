import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Link, useNavigate } from 'react-router-dom';

import { CustomTable } from '@components/Table';
import { routes } from '@routes';
import { DisclosureSection } from '@components/DisclosureSection';
import { getRepositoriesWithStatuses } from '@queries/organizations/getRepositoriesWithStatuses';
import { Button } from '@components/form-elements/buttons/Button';
import { TrashBin } from '@components/icons/TrashBin';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TOrganization } from 'app/types/entities/TOrganization';
import { TRepositoryStatus } from 'app/types/entities/TRepositoryStatus';

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }

  table {
    width: 100%;

    .theadTr {
      border-bottom: none;
    }

    thead tr td,
    thead th {
      height: 50px;
    }
  }
`;

const LastCellContent = styled.div`
  padding: 13px 0;
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 6px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;

    :hover {
      background: #ff9191;

      path {
        fill: #fff;
        color: #fff;
      }
    }
  }
`;
const CellContent = styled.div`
  margin: 0;
  padding: 15px 0;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

type TProps = {
  org: TOrganization;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export const RepositoriesTable = ({ org, setLoading }: TProps) => {
  const navigate = useNavigate();

  const { openModal, closeModal } = useModalContext();

  const orgId = org.id;
  const [repositories, setRepositories] = useState<TRepositoryStatus[]>([]);
  const [repositoriesDisclosureOpen, setRepositoriesDisclosureOpen] = useState<boolean>(true);

  const repositoriesSectionTitle = 'Cases ' + (repositories?.length > 0 ? ` (${repositories?.length})` : '');

  useEffect(() => {
    setLoading(true);
    getRepositoriesWithStatuses(orgId, 1, 10000, '', {}).then((v) => {
      setRepositories(v.data);
      setLoading(false);
    });
  }, [org, orgId]);

  const handleDeleteRepository = (repository: TRepositoryStatus) => {
    openModal(ModalNamesEnum.DeleteRepositoryModal, {
      repository,
      onSuccess: () => {
        closeModal();
        setRepositories(repositories.filter((r) => r.id !== repository.id));
      },
    });
  };

  const tableHead = [
    {
      headComponent: () => <th />,
      render: (data: TRepositoryStatus) => (
        <CellContent style={{ width: '250px' }}>
          <Link
            to={{
              pathname: routes.repositoryDetails.make(org.slug, data.slug),
            }}
            state={{
              from: routes.organizationDetails.make(org.slug),
            }}
          >
            {data.name}
          </Link>
        </CellContent>
      ),
    },
    {
      headComponent: () => <th />,
      render: (data: TRepositoryStatus) => (
        <CellContent>
          <span style={{ display: 'block' }}>{data.jobsProcessing} jobs processing</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <th />,
      render: (data: TRepositoryStatus) => (
        <CellContent>
          <span style={{ display: 'block' }}>{data.jobsReadyForTranscribing} jobs ready for editing</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }} />,
      render: (data: TRepositoryStatus) => (
        <CellContent>
          <span style={{ display: 'block' }}>{data.jobsComplete} completed jobs</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }} />,
      render: (data: TRepositoryStatus) => (
        <LastCellContent>
          <TrashBin onClick={() => handleDeleteRepository(data)} className="cursor-pointer" />
        </LastCellContent>
      ),
    },
  ];

  return (
    <Wrapper className="flex flex-col">
      <div>
        <DisclosureSection
          title={repositoriesSectionTitle}
          open={repositoriesDisclosureOpen}
          setOpenState={setRepositoriesDisclosureOpen}
          loading={false}
        >
          <CustomTable head={tableHead} data={repositories} noHead={true} />
          <div className="grid place-items-end">
            <div className="w-36">
              <Button
                className="my-2"
                disabled={false}
                variant="outlined"
                onClick={() =>
                  navigate(
                    {
                      pathname: routes.newRepository.make(org.slug),
                    },
                    {
                      state: {
                        from: routes.organizationDetails.make(org.slug),
                      },
                    },
                  )
                }
              >
                Create a new Case
              </Button>
            </div>
          </div>
        </DisclosureSection>
      </div>
    </Wrapper>
  );
};
