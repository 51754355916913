import { useState } from 'react';
import { DatePicker } from '@components/form-elements/DatePicker';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { CloseIcon } from '@components/icons/Close';
import { getStartOfCurrentDay } from '@components/todo/ReportFilter/helpers/getStartOfCurrentDay';
import { getSelectedDateFilterValue } from '@components/todo/ReportFilter/helpers/getSelectedDateFilterValue';
import { getFirstDayOfCurrentWeek } from '@components/todo/ReportFilter/helpers/getFirstDayOfCurrentWeek';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';
import { TUserFilter } from '@components/todo/ReportFilter/types/TUserFilter';

const Close = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
    fill: #858dbd;
  }
`;

const Wrapper = styled.div`
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: default;
`;

const FilterTitle = styled.h3`
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 0 15px;
  color: #858dbd;
  border-bottom: 1px solid #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`;

const SuggestionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
`;

const UsersCreatedWrapper = styled.div`
  margin-top: 20px;
`;

const FormItemLabel = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 15px;
  color: #858dbd;
`;

const DatePickerWrapper = styled.div`
  padding: 0 0 12px;
`;

const BottomButton = styled(Button)`
  width: 25%;
  margin-right: 10px;
`;

type TProps = {
  userFilter?: TUserFilter;
  userFilterOnChange?: (v: any) => void;
  jobsFilter: TJobsFilter;
  jobsFilterOnChange: (v: any) => void;
  handleSubmit: () => void;
  handleClose: () => void;
};

const getFirstDayOfCurrentMonth = () => {
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDate() + 1);
  firstDayOfMonth.setHours(0, 0, 0, 0);
  return firstDayOfMonth;
};

export const ReportFilter = ({
  userFilter,
  userFilterOnChange,
  jobsFilter,
  jobsFilterOnChange,
  handleSubmit,
  handleClose,
}: TProps) => {
  const [tempDateFilterSelected, setTempDateFilterSelected] = useState(getSelectedDateFilterValue(jobsFilter));
  const [tempJobsFilter, setTempJobsFilter] = useState(jobsFilter);
  const [tempUserFilter, setTempUserFilter] = useState(userFilter);
  const tempSubmit = () => {
    if (userFilterOnChange) {
      userFilterOnChange(tempUserFilter ?? {});
    }
    if (jobsFilterOnChange) {
      jobsFilterOnChange(tempJobsFilter);
    }
    handleSubmit();
    handleClose();
  };
  const tempClose = () => {
    if (userFilterOnChange) {
      userFilterOnChange(userFilter ?? {});
    }
    if (jobsFilterOnChange) {
      jobsFilterOnChange(jobsFilter);
    }
    handleClose();
  };

  return (
    <Wrapper>
      <FilterTitle>Filters</FilterTitle>
      <Close onClick={tempClose}>
        <CloseIcon />
      </Close>

      <FormItemLabel>Jobs submitted</FormItemLabel>
      <DatePickerWrapper>
        <DatePicker
          label="From"
          minDate={new Date(0)}
          maxDate={tempJobsFilter.to || new Date()}
          value={tempJobsFilter.from}
          onChange={(date: Date | null) =>
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = date ?? undefined;
              return newVal;
            })
          }
        />
      </DatePickerWrapper>

      <DatePickerWrapper>
        <DatePicker
          label="To"
          minDate={tempJobsFilter.from}
          maxDate={new Date()}
          value={tempJobsFilter.to}
          onChange={(date: Date | null) =>
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              let newDate = null;
              if (date) {
                const dateObj = new Date(date);
                dateObj.setHours(23);
                dateObj.setMinutes(59);
                newDate = dateObj;
              }
              newVal.to = newDate ?? undefined;
              return newVal;
            })
          }
        />
      </DatePickerWrapper>

      <SuggestionButtonsWrapper>
        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'today'}
          onClick={() => {
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = getStartOfCurrentDay();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('today');
          }}
        >
          Today
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'week'}
          onClick={() => {
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = getFirstDayOfCurrentWeek();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('week');
          }}
        >
          This week
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'month'}
          onClick={() => {
            setTempJobsFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = getFirstDayOfCurrentMonth();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('month');
          }}
        >
          This month
        </Button>
      </SuggestionButtonsWrapper>

      {userFilter && userFilterOnChange ? (
        <UsersCreatedWrapper>
          <FormItemLabel>Users created</FormItemLabel>
          <DatePickerWrapper>
            <DatePicker
              minDate={new Date(0)}
              maxDate={tempUserFilter?.userTo || new Date()}
              value={tempUserFilter?.userFrom}
              onChange={(date: Date | null) =>
                setTempUserFilter((v) => {
                  const newVal = Object.assign({}, v);
                  newVal.userFrom = date;
                  return newVal;
                })
              }
              label="From"
            />
          </DatePickerWrapper>
          <DatePickerWrapper>
            <DatePicker
              minDate={tempUserFilter?.userFrom ?? undefined}
              maxDate={new Date()}
              value={tempUserFilter?.userTo}
              onChange={(date: Date | null) =>
                setTempUserFilter((v) => {
                  const newVal: TUserFilter = Object.assign({}, v);
                  let newDate = null;
                  if (date) {
                    const dateObj = new Date(date);
                    dateObj.setHours(23);
                    dateObj.setMinutes(59);
                    newDate = dateObj;
                  }
                  newVal.userTo = newDate;
                  return newVal;
                })
              }
              label="To"
            />
          </DatePickerWrapper>
        </UsersCreatedWrapper>
      ) : null}

      <ButtonsWrapper>
        <BottomButton
          variant="outlined"
          onClick={() => {
            if (userFilterOnChange) {
              setTempUserFilter((v) => {
                const newVal = Object.assign({}, v);
                newVal.userFrom = undefined;
                newVal.userTo = undefined;
                return newVal;
              });
            }
            setTempJobsFilter((v) => {
              const newVal = Object.assign({}, v);
              newVal.from = undefined;
              newVal.to = undefined;
              return newVal;
            });
            setTempDateFilterSelected('');
            if (userFilterOnChange) {
              userFilterOnChange((v: TUserFilter) => {
                const newVal = Object.assign({}, v);
                newVal.userFrom = undefined;
                newVal.userTo = undefined;
                return newVal;
              });
            }
            if (jobsFilterOnChange) {
              jobsFilterOnChange((v: TJobsFilter) => {
                const newVal = Object.assign({}, v);
                newVal.from = undefined;
                newVal.to = undefined;
                return newVal;
              });
            }
            handleSubmit();
            handleClose();
          }}
        >
          Reset
        </BottomButton>
        <BottomButton onClick={tempSubmit}>Apply</BottomButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};
