import { AddBlue } from '@components/icons/AddBlue';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const AddPlusButton = ({ onClick }: TProps) => {
  return (
    <li key="create-new" className="flex">
      <Wrapper onClick={onClick}>
        <AddBlue className="" onClick={onClick} />
      </Wrapper>
    </li>
  );
};
