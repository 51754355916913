import { createContext, useContext, useState } from 'react';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { useAPI } from '@hooks/useAPI';

import { AuthService } from 'app/API';

type TState = {
  isComplete: boolean;
  isLoading: boolean;
  email: string;
  setEmail: (v: string) => void;
  error: string;
  setError: (v: string) => void;
  recovery: () => void;
  resetForm: () => void;
};

const PageContext = createContext<TState>({
  isComplete: false,
  isLoading: false,
  email: '',
  setEmail: () => null,
  error: '',
  setError: () => null,
  recovery: () => null,
  resetForm: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const recovery = async () => {
    setIsLoading(true);
    await call(AuthService.recover({ requestBody: { email } }));
    setError('');
    setIsLoading(false);
    setIsComplete(true);
  };

  const resetForm = async () => {
    setIsLoading(false);
    setIsComplete(false);
    setError('');
  };

  return (
    <PageContext.Provider
      value={{
        isComplete,
        email,
        setEmail,
        setError,
        isLoading,
        error,
        recovery,
        resetForm,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
