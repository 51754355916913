import { client } from 'app/http';

export const getRepositoriesWithStatuses = async (
  orgId: string,
  page: number,
  pageSize: number,
  search: string,
  filter: {
    from?: Date | null;
    to?: Date | null;
  },
) => {
  const fromParam = Math.floor(Number(filter.from) / 1000) || '';
  const toParam = Math.floor(Number(filter.to) / 1000) || '';
  try {
    const response = await client.get(
      `/organizations/${orgId}/repositories/statuses?pageNo=${page}&pageSize=${pageSize}&from=${fromParam}&to=${toParam}&search=${encodeURIComponent(
        search,
      )}`,
    );
    return response.data;
  } catch (err) {
    console.log('getRepositoriesWithStatuses request failed:', err);
    return [];
  }
};
