import styled from 'styled-components';

export const EllipsisLink = styled.span`
  line-height: 30px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px 0 8px;
  margin: 0;
`;
