import { useAuthContext } from '@providers/AuthProvider';

import { Wrapper } from './styles/Wrapper';

export const AdminCard = () => {
  const { me } = useAuthContext();
  return (
    <Wrapper>
      <div>
        <div className="title">Welcome to the Admin Panel!</div>
        <div className="user-email">{me?.email}</div>
      </div>
    </Wrapper>
  );
};
