import { useAuthContext } from '@providers/AuthProvider';
import { useLocation } from 'react-router-dom';

import { NavigationBlock } from './styles/NavigationBlock';
import { Welcome } from './components/Welcome';
import { WelcomeAdmin } from './components/WelcomeAdmin';
import { Menu } from './components/Menu';
import { AdminMenu } from './components/AdminMenu';

export const WelcomeAndMenu = () => {
  const location = useLocation();
  const { me } = useAuthContext();
  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;
  const isVsAdminSection = location.pathname.includes('/admin/');

  if (isVsAdminSection && isVsAdmin) {
    return (
      <>
        <WelcomeAdmin />
        <NavigationBlock>
          <AdminMenu />
        </NavigationBlock>
      </>
    );
  }
  return (
    <>
      <Welcome />
      <NavigationBlock>
        <Menu />
      </NavigationBlock>
    </>
  );
};
