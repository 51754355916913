import { getAmericanTime } from '@helpers/getAmericanTimezone';

export const dateToInX = (date: string | number | Date, showHHMM = true) => {
  const now = Date.now();
  const diff = Math.floor((new Date(date).getTime() - now) / 1000);
  // More than in 7 days
  if (diff > 7 * 24 * 60 * 60) {
    return getAmericanTime(date, showHHMM);
  }
  // More than in 1 day
  if (diff > 24 * 60 * 60) {
    const daysAgo = Math.floor(diff / (24 * 60 * 60));
    return `in ${daysAgo} ${daysAgo === 1 ? 'day' : 'days'}`;
  }
  // More than in a hour
  if (diff > 60 * 60) {
    const hoursAgo = Math.floor(diff / (60 * 60));
    return `in ${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'}`;
  }
  // More than in a minute
  if (diff > 60) {
    const minutesAgo = Math.floor(diff / 60);
    return `in ${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'}`;
  }
  return `in ${diff} ${diff === 1 ? 'second' : 'seconds'}`;
};
