import React, { useState } from 'react';
import { Button } from '@components/form-elements/buttons/Button';
import { usePageContext } from '../../../../providers/PageProvider';
import { checkPhoneNumber } from '@queries/registration/checkPhoneNumber';
import { sendOTPCode } from '@queries/registration/sendOTPCode';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { PhoneInput } from '@components/form-elements/PhoneInput';
import { PhoneIcon } from '@components/icons/Phone';
import { Error } from '@components/Error';

export const RegistrationPagePhoneStage = () => {
  const { setStage, setCodeSentDate, phone, setPhone, goToLogin, register } = usePageContext();
  const isTestEnvironment = window.location.hostname === 'localhost';
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleFormSubmit = async (evt: React.FormEvent) => {
    setError(undefined);
    evt.preventDefault();

    setIsLoading(true);
    if (isTestEnvironment) {
      await register();
      return;
    }
    const isPhoneValid = await checkPhoneNumber(phone);
    if (!isPhoneValid) {
      setIsLoading(false);
      setError('Phone number is not valid or already in use');
      return;
    }
    await sendOTPCode(phone);
    setCodeSentDate(Date.now());
    setIsLoading(false);
    setStage('signupCode');
  };

  const isButtonDisabled = !phone?.length || !!error;

  return (
    <div className="root">
      <h1 className="header">Verify Your Phone Number</h1>

      <form onSubmit={handleFormSubmit}>
        {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
        <div className="margin20">
          <PhoneInput
            onChange={(value) => {
              setError('');
              setPhone(value);
            }}
            defaultCountry="US"
            name="phone"
            placeholder="Phone number (e.g. +12223334455)"
            value={phone || ''}
            required={true}
            icon={PhoneIcon}
          />
        </div>

        <Button size="medium" disabled={isButtonDisabled} className="margin30" type="submit">
          Next
        </Button>

        {error ? (
          <div className="formError" data-testid="errorMessage">
            <Error error={error} />
          </div>
        ) : null}
      </form>

      <p className="haveAnAccount">
        Have an account?&nbsp;&nbsp;
        <span onClick={goToLogin} className="signIn">
          Sign In
        </span>
      </p>
    </div>
  );
};
