export const ChevronLeftIcon = ({ color = '#858DBD' }: { color?: string }) => (
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8254 0.476971C12.3468 1.06356 12.294 1.96176 11.7074 2.48317L4.37594 9L11.7074 15.5168C12.294 16.0382 12.3468 16.9364 11.8254 17.523C11.304 18.1096 10.4058 18.1624 9.81919 17.641L1.29287 10.0621C0.989496 9.79244 0.815918 9.40591 0.815918 9C0.815918 8.59409 0.989496 8.20756 1.29287 7.93789L9.81919 0.358959C10.4058 -0.16245 11.304 -0.109614 11.8254 0.476971Z"
      fill={color}
    />
  </svg>
);
