import { PageProvider } from './providers/PageProvider';
import { RegistrationPageContent } from './components/Content';

export const RegistrationPage = () => {
  return (
    <>
      <PageProvider>
        <RegistrationPageContent />
      </PageProvider>
    </>
  );
};
