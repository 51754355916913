import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 11px;

  .title {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #40608f;
    width: 200px;
  }

  .user-email {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #313131;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 7px 0 0;
  }
`;
