export const DashboardIcon = ({ color = '#858DBD' }: { color?: string }) => (
  <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V17C19 18.1046 18.1046 19 17 19H3C1.89543 19 1 18.1046 1 17V3ZM2.5 2.75V17C2.5 17.2761 2.72386 17.5 3 17.5H7.25V2.75H2.5ZM17 17.5H8.75V7.75H17.5V17C17.5 17.2761 17.2761 17.5 17 17.5ZM17.5 6.25H8.75V2.75H17.5V6.25Z"
      fill={color}
    />
    <path
      d="M2.5 2.75V2.7H2.45V2.75H2.5ZM7.25 17.5V17.55H7.3V17.5H7.25ZM7.25 2.75H7.3V2.7H7.25V2.75ZM8.75 17.5H8.7V17.55H8.75V17.5ZM8.75 7.75V7.7H8.7V7.75H8.75ZM17.5 7.75H17.55V7.7H17.5V7.75ZM8.75 6.25H8.7V6.3H8.75V6.25ZM17.5 6.25V6.3H17.55V6.25H17.5ZM8.75 2.75V2.7H8.7V2.75H8.75ZM17.5 2.75H17.55V2.7H17.5V2.75ZM3 0.95C1.86782 0.95 0.95 1.86782 0.95 3H1.05C1.05 1.92304 1.92304 1.05 3 1.05V0.95ZM17 0.95H3V1.05H17V0.95ZM19.05 3C19.05 1.86782 18.1322 0.95 17 0.95V1.05C18.077 1.05 18.95 1.92304 18.95 3H19.05ZM19.05 17V3H18.95V17H19.05ZM17 19.05C18.1322 19.05 19.05 18.1322 19.05 17H18.95C18.95 18.077 18.077 18.95 17 18.95V19.05ZM3 19.05H17V18.95H3V19.05ZM0.95 17C0.95 18.1322 1.86782 19.05 3 19.05V18.95C1.92304 18.95 1.05 18.077 1.05 17H0.95ZM0.95 3V17H1.05V3H0.95ZM2.55 17V2.75H2.45V17H2.55ZM3 17.45C2.75147 17.45 2.55 17.2485 2.55 17H2.45C2.45 17.3038 2.69624 17.55 3 17.55V17.45ZM7.25 17.45H3V17.55H7.25V17.45ZM7.2 2.75V17.5H7.3V2.75H7.2ZM2.5 2.8H7.25V2.7H2.5V2.8ZM8.75 17.55H17V17.45H8.75V17.55ZM8.7 7.75V17.5H8.8V7.75H8.7ZM17.5 7.7H8.75V7.8H17.5V7.7ZM17.55 17V7.75H17.45V17H17.55ZM17 17.55C17.3038 17.55 17.55 17.3038 17.55 17H17.45C17.45 17.2485 17.2485 17.45 17 17.45V17.55ZM8.75 6.3H17.5V6.2H8.75V6.3ZM8.7 2.75V6.25H8.8V2.75H8.7ZM17.5 2.7H8.75V2.8H17.5V2.7ZM17.55 6.25V2.75H17.45V6.25H17.55Z"
      fill={color}
    />
  </svg>
);
