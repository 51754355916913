import { ReactElement } from 'react';

import { Wrapper } from './styles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import autoscript from './autoscript.png';

type TProps = {
  children: ReactElement | ReactElement[];
};

export const LoginLayout = ({ children }: TProps) => {
  return (
    <Wrapper>
      <div className="leftSide">
        {children}
        <div className="leftSideFooter">&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</div>
      </div>

      <div className="rightSide">
        <div className="autoscript">
          <img src={autoscript} alt="" />
        </div>
        <div className="poweredBy">
          Powered by{' '}
          <a href="https://voicescript.ai/" target="_blank" rel="noreferrer" className="link">
            VoiceScript Inc.
          </a>
        </div>
        <div className="convertZoom">
          Convert Zoom, FTR, Reporter Studio PRO, MP3 and other formats into an AI-powered draft transcript
        </div>
      </div>
    </Wrapper>
  );
};
