import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-left: 240px;
  min-height: 100%;
  min-width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;
