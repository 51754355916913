type TProps = {
  width?: string;
};

export const CrossIcon = ({ width = '8' }: TProps) => (
  <svg width={width} height={width} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.05029 1.05029L1.05029 7.05029M1.05029 1.05029L7.05029 7.05029"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
