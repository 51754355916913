import { Button } from '@components/form-elements/buttons/Button';

import { Wrapper } from './styles/Wrapper';

type TProps = {
  error: Error;
  componentStack: string | null;
};

export const ErrorBoundaryComponent = ({ error, componentStack }: TProps) => {
  const isLocalHost = window.location.hostname === 'localhost';

  const onClick = () => {
    window.location.href = '/';
  };

  return (
    <Wrapper>
      <h2>Something went wrong</h2>
      <p>Error: {error.message}</p>

      {isLocalHost && <p>{componentStack}</p>}

      <div className="buttonWrapper">
        <Button onClick={onClick}>Go to main page</Button>
      </div>
    </Wrapper>
  );
};
