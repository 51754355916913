import styled from 'styled-components';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { ActiveCard } from '@pages/User/BillingAccountDetailsPage/ASOne/Cards/ActiveCard';
import { TrialingCard } from '@pages/User/BillingAccountDetailsPage/ASOne/Cards/TrialingCard';
import { CanceledCard } from '@pages/User/BillingAccountDetailsPage/ASOne/Cards/CanceledCard';
import { PaymentFailedCard } from '@pages/User/BillingAccountDetailsPage/ASOne/Cards/PaymentFailedCard';
import { useAuthContext } from '@providers/AuthProvider';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { TSubscription } from 'app/types/entities/TSubscription';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';

const Wrapper = styled.div`
  width: 210px;
  height: 200px;
  display: flex;
  background: transparent;
  cursor: pointer;

  .divider {
    margin: 10px 0;
  }

  h2.title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #878787;
  }
`;

type TProps = {
  billingAccount?: TBillingAccount;
  subscription: TSubscription;
  plan: TSubscriptionProduct;
  onChange?: () => void;
};

export const SubscriptionCard = ({ billingAccount, subscription, plan, onChange }: TProps) => {
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();
  const { organization } = useAuthContext();

  const openSubscriptionPage = () => {
    navigate(routes.autoScriptOneSubscriptionDetails.make(organization?.slug ?? '', subscription.id));
  };

  const onChooseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    openModal(ModalNamesEnum.ChooseASOneSubscriptionModal, {
      billingAccountId: billingAccount?.id,
      onSuccess: () => {
        closeModal();
        if (onChange) {
          onChange();
        }
      },
    });
  };

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    openModal(ModalNamesEnum.EditASOneSubscriptionModal, {
      billingAccountId: billingAccount?.id,
      subscription,
      onSuccess: () => {
        closeModal();
        if (onChange) {
          onChange();
        }
      },
    });
  };

  const reload = () => {
    closeModal();
    if (onChange) {
      onChange();
    }
  };

  // Todo: we need to do something here. Coz this is not a failed state
  if (subscription.status === 'incomplete' || subscription.status === 'incomplete_expired') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <PaymentFailedCard plan={plan} onButtonClick={onEditClick} />
      </Wrapper>
    );
  }

  if (subscription.status === 'canceled') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <CanceledCard plan={plan} onButtonClick={onChooseClick} />
      </Wrapper>
    );
  }

  if (subscription.status === 'past_due' || subscription.status === 'unpaid') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <PaymentFailedCard plan={plan} onButtonClick={onEditClick} />
      </Wrapper>
    );
  }

  if (subscription.status === 'trialing') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <TrialingCard
          onButtonClick={onEditClick}
          plan={plan}
          subscription={subscription}
          billingAccount={billingAccount}
          reload={reload}
        />
      </Wrapper>
    );
  }

  // Default is Active
  return (
    <Wrapper onClick={openSubscriptionPage}>
      <ActiveCard
        plan={plan}
        subscription={subscription}
        billingAccount={billingAccount}
        onButtonClick={onEditClick}
        reload={reload}
      />
    </Wrapper>
  );
};
