import { SVGProps } from 'react';

export const Briefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={19} fill="none" {...props}>
    <path
      fill="#858DBD"
      fillRule="evenodd"
      d="M12.5.4c.443 0 .813.153 1.13.47.317.317.47.687.47 1.13v2c0 .22.18.4.4.4h4c.443 0 .813.153 1.13.47.317.317.47.687.47 1.13v11c0 .443-.153.812-.47 1.13a1.53 1.53 0 0 1-1.13.47h-16c-.443 0-.812-.153-1.13-.47A1.527 1.527 0 0 1 .9 17V6c0-.443.154-.813.471-1.13.317-.317.686-.47 1.129-.47h4a.4.4 0 0 0 .4-.4V2c0-.443.153-.813.47-1.13C7.689.553 8.058.4 8.5.4h4Zm-4 14.2a.566.566 0 0 1-.429-.17v-.002A.566.566 0 0 1 7.9 14v-1a.4.4 0 0 0-.4-.4h-5a.4.4 0 0 0-.4.4v4c0 .22.18.4.4.4h16a.4.4 0 0 0 .4-.4v-4a.4.4 0 0 0-.4-.4h-5a.4.4 0 0 0-.4.4v1a.568.568 0 0 1-.17.429.57.57 0 0 1-.43.17h-4ZM8.1 4c0 .22.18.4.4.4h4a.4.4 0 0 0 .4-.4V2a.4.4 0 0 0-.4-.4h-4a.4.4 0 0 0-.4.4v2Zm1 9c0 .22.18.4.4.4h2a.4.4 0 0 0 .4-.4v-2a.4.4 0 0 0-.4-.4h-2a.4.4 0 0 0-.4.4v2Zm-7-2c0 .22.18.4.4.4h5a.4.4 0 0 0 .4-.4v-1a.57.57 0 0 1 .17-.43.568.568 0 0 1 .43-.17h4c.183 0 .317.057.43.17.113.113.17.247.17.43v1c0 .22.18.4.4.4h5a.4.4 0 0 0 .4-.4V6a.4.4 0 0 0-.4-.4h-16a.4.4 0 0 0-.4.4v5Z"
      clipRule="evenodd"
    />
  </svg>
);
