import { Helmet } from 'react-helmet-async';

import { Header } from '@components/Header';

import { PageProvider } from './providers/PageProvider';
import { Content } from './components/Content';

export const NewJobPage = () => {
  return (
    <PageProvider>
      <Helmet>
        <title>Create Job - AutoScript</title>
      </Helmet>
      <Header title="Create Job" />

      <Content />
    </PageProvider>
  );
};
