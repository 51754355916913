import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const OrgLink = styled(Link)`
  color: #858dbd;
  border-radius: 5px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  position: relative;
  cursor: pointer;
  text-decoration: none;
`;
