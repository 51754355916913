import styled from 'styled-components';

export const DetailsWrapper = styled.span`
  display: flex;
  flex: 0 0 auto;
  position: relative;
  margin: -2px 0 0;

  .details-span {
    display: inline-block;
    height: 16px;
    line-height: 20px;
    margin-right: 4px;
    font-size: 12px;
  }
`;
