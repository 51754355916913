import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  margin: -10px;
  position: relative;
  width: 240px;

  .wrapper {
    height: 170px;
    background: linear-gradient(90deg, #adc5e8 0%, #40608f 83.65%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    width: 100%;

    .noOverflow {
      overflow: hidden;
      position: relative;
      padding: 0 25px;
      height: 100%;
    }

    .cross-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .title {
      font-family: 'General Sans', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 25px;
      width: 180px;
    }

    .description {
      font-family: 'General Sans', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #ffffff;
      margin-top: 10px;
      text-align: left;
    }

    .download-as {
      margin: 10px auto 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;
      width: 170px;
      height: 30px;
      color: #40608f;
      border-radius: 5px;
      text-decoration: none;
      position: relative;
      z-index: 2;
      box-shadow: 0 8px 8px rgba(0, 35, 67, 0.5);

      &:hover {
        background: #cadcf8;
      }

      &:hover + .hammer {
        transform: scale(1.3);
      }
    }
  }
`;
