import { CrossIcon } from './icons/CrossIcon';
import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';
import { TableStyledLink } from '@components/Table/StyledLink';

export const Tag = ({ text, link, onDelete }: TProps) => {
  return (
    <>
      <Wrapper>
        {link ? <TableStyledLink to={link}>{text}</TableStyledLink> : text}
        {onDelete ? <CrossIcon onClick={() => onDelete()} /> : null}
      </Wrapper>
    </>
  );
};
