import { useAuthContext } from '@providers/AuthProvider';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import BG from './images/bg.png';
import { Wrapper } from './styles/Wrapper';

export const YearEndSpecial = () => {
  const { openModal } = useModalContext();
  const { organization } = useAuthContext();

  const canTopUp =
    organization?.billingAccount && organization?.permissions?.viewBilling && organization?.permissions?.manageBilling;

  const openTopUpModal = () => {
    if (!canTopUp) {
      return;
    }
    openModal(ModalNamesEnum.TopUpCreditBalance, {
      billingAccountId: organization?.billingAccount?.id,
    });
  };

  return (
    <Wrapper className={canTopUp ? 'clickable' : ''} onClick={openTopUpModal}>
      <img src={BG} width="auto" height="auto" alt="" />
      <div className="title">Year-End Special</div>
      <div className="subtitle">
        Lower AutoScript rates & more! <br />
        Nov 1st- Dec 31st
      </div>
      <div className="third"></div>
      {canTopUp ? <button>Top Up Now</button> : null}
    </Wrapper>
  );
};
