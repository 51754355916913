import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '@routes';

import { Divider } from '@components/Divider';
import { FullWidthSectionRight } from '@pages/Admin/JobDetailsPage/components/Content/components/MainContent/styles/FullWidthSectionRight';
import { JobSection } from '@pages/Admin/JobDetailsPage/components/Content/components/MainContent/styles/JobSection';
import { JobSectionLeft } from '@pages/Admin/JobDetailsPage/components/Content/components/MainContent/styles/JobSectionLeft';
import { renderAvatar } from './CommentsList';
import { useAuthContext } from '@providers/AuthProvider';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { get, isNaN } from 'lodash-es';
import { Tooltip } from '@components/Tooltip';
import { QuestionIcon } from '@components/icons/QuestionIcon';
import { msToDaysHoursMinutes } from '@helpers/msToDaysHoursMinutes';

import { TCheckoutEvent } from 'app/types/entities/TCheckoutEvent';
import { TJob } from 'app/types/entities/TJob';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { EPermission } from 'app/types/enums/EPermission';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { TJobFilesVersion } from 'app/types/entities/TJobFilesVersion';

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const Wrapper = styled.div`
  .metric {
    padding: 20px 0 0;
    display: flex;
    gap: 10px;

    .metric-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      padding: 0 0 5px;
    }

    .metric-username a {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      text-decoration: underline;
      margin: 0 10px 0 0;
    }

    .metric-date {
      font-weight: 400;
    }

    .metric-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
    }
  }
`;

type TSingleMetricProps = {
  checkoutEvent: TCheckoutEvent & { initialVersion?: any; finalVersion?: any };
  versions: TJobFilesVersion[];
};

const SingleMetric = ({ checkoutEvent, versions }: TSingleMetricProps) => {
  const { me, organization } = useAuthContext();
  const user = checkoutEvent?.user;
  const startedEditingTime = new Date(checkoutEvent?.initialVersion?.Metrics?.startedEditingAt).getTime();
  const finishedEditingTime = new Date(checkoutEvent.finalVersion?.Metrics?.finishedEditingAt).getTime();
  const timeSpentValue = isNaN(finishedEditingTime - startedEditingTime)
    ? '-'
    : msToDaysHoursMinutes(finishedEditingTime - startedEditingTime);
  // const averageNumberOfEditsPerPageValue =
  //   checkoutEvent.finalVersion?.Metrics?.editsCount / checkoutEvent.finalVersion?.Metrics?.pagesCount || '-';
  const numberOfEditsPerCheckoutEvent = versions
    .filter((v) => v.jobFilesCheckoutEventId === checkoutEvent.id)
    .reduce((acc, curr) => acc + (curr.metrics?.editsCount || 0), 0);
  const averageNumberOfEditsPerPageValue =
    numberOfEditsPerCheckoutEvent && checkoutEvent.finalVersion?.Metrics?.pagesCount
      ? Math.round(numberOfEditsPerCheckoutEvent / checkoutEvent.finalVersion?.Metrics?.pagesCount)
      : '-';

  const pageAmountValue = checkoutEvent.finalVersion?.Metrics?.pagesCount || '-';

  return (
    <Wrapper>
      <div className="metric w-full">
        {renderAvatar(user)}

        <div className="metric-title w-full">
          <span className="metric-username">
            {user.id === me.id ? (
              <StyledLink to={routes.accountPage.make()}>
                {user.name} {user.lastname}
              </StyledLink>
            ) : (
              <StyledLink to={routes.userDetails.make(organization?.slug ?? '', user.id)}>
                {user.name} {user.lastname}
              </StyledLink>
            )}
          </span>

          <span className="metric-date">
            {checkoutEvent.initialVersion?.Metrics?.startedEditingAt
              ? getAmericanTime(checkoutEvent.initialVersion?.Metrics?.startedEditingAt)
              : '-'}
          </span>

          <div className="flex justify-between w-full my-2 ">
            <div className="w-full">
              <div>Checkout date</div>
              <div className="metric-text">
                {checkoutEvent.initialVersion?.Metrics?.startedEditingAt
                  ? getAmericanTime(checkoutEvent.initialVersion?.Metrics?.startedEditingAt)
                  : '-'}
              </div>
            </div>
            <div className="w-full">
              <div>Last version</div>
              <div className="metric-text">
                {checkoutEvent.finalVersion?.Metrics?.finishedEditingAt
                  ? getAmericanTime(checkoutEvent.finalVersion?.Metrics?.finishedEditingAt)
                  : '-'}
              </div>
            </div>
            <div className="w-full">
              <div className="flex items-center">
                <div className="mr-2">Time spent</div>
                <Tooltip content="Time since user checked out the job and uploaded their latest version">
                  <QuestionIcon />
                </Tooltip>
              </div>

              <div className="metric-text">{timeSpentValue}</div>
            </div>
          </div>

          <div className="flex w-full">
            <div className="w-full">
              <div>Page amount</div>
              <div className="metric-text">{pageAmountValue}</div>
            </div>
            <div className="w-full">
              <div>Average number of edits per page</div>
              <div className="metric-text">{averageNumberOfEditsPerPageValue}</div>
            </div>
            <div className="w-full"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

type TProps = { job: TJob };

export default function MetricsList({ job }: TProps) {
  const finalizingEvents: TCheckoutEvent[] = [];
  const proofreadingEvents: TCheckoutEvent[] = [];
  const editingEvents: TCheckoutEvent[] = [];

  if (!job?.checkoutEvents) {
    return null;
  }

  (job.checkoutEvents || []).forEach((event) => {
    const currentEventStatus = get(JOB_STATUSES_ENUM, event.jobStatus);

    if (currentEventStatus === JOB_STATUSES_ENUM.JOB_STATUS_FINALIZING) {
      finalizingEvents.push(event);
    }

    if (currentEventStatus === JOB_STATUSES_ENUM.JOB_STATUS_PROOFING) {
      proofreadingEvents.push(event);
    }

    if (currentEventStatus === JOB_STATUSES_ENUM.JOB_STATUS_TRANSCRIBING) {
      editingEvents.push(event);
    }
  });

  const isVisible = !!finalizingEvents.length || !!proofreadingEvents.length || !!editingEvents.length;

  if (!isVisible) {
    return null;
  }

  return (
    <Restricted to={EPermission.viewReports}>
      <JobSection className="metrics-review-section">
        <JobSectionLeft>Metrics review</JobSectionLeft>
        <FullWidthSectionRight>
          {!!finalizingEvents.length && (
            <div className="metrics-finalizing-section">
              <JobSectionLeft>Finalizing</JobSectionLeft>
              {finalizingEvents.map((event) => (
                <SingleMetric checkoutEvent={event} key={event.id} versions={job.versions || []} />
              ))}
              <Divider />
            </div>
          )}
          {!!proofreadingEvents.length && (
            <div className="metrics-proofreading-section">
              <JobSectionLeft>Proofreading</JobSectionLeft>
              {proofreadingEvents.map((event) => (
                <SingleMetric checkoutEvent={event} key={event.id} versions={job.versions || []} />
              ))}
              <Divider />
            </div>
          )}
          {!!editingEvents.length && (
            <div className="metrics-editing-section">
              <JobSectionLeft className="my-4">Editing</JobSectionLeft>
              {editingEvents.map((event) => (
                <SingleMetric checkoutEvent={event} key={event.id} versions={job.versions || []} />
              ))}
            </div>
          )}
        </FullWidthSectionRight>
      </JobSection>
    </Restricted>
  );
}
