import { Helmet } from 'react-helmet-async';

import { useAuthContext } from '@providers/AuthProvider';

import { PageProvider } from './providers/PageProvider';
import { Content } from './components/Content';

export const JobsListPage = () => {
  const { organization, workspace } = useAuthContext();

  if (!organization || !workspace) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Jobs List - AutoScript</title>
      </Helmet>

      <PageProvider workspaceId={workspace.id}>
        <Content />
      </PageProvider>
    </>
  );
};
