import { routes } from '@routes';

import { WorkspaceIcon } from '@components/icons/sidebar/admin/Workspaces';
import { BillingAccountsIcon } from '@components/icons/sidebar/admin/BillingAccountsIcon';
import { TransactionsIcon } from '@components/icons/sidebar/admin/TransactionsIcon';
import { Briefcase } from '@components/icons/sidebar/admin/Briefcase';
import { OrganizationsIcon } from '@components/icons/sidebar/admin/Organizations';
import { SubscriptionsIcon } from '@components/icons/sidebar/admin/Subscriptions';
import { UsersIcon } from '@components/icons/sidebar/admin/Users/icon';
import { JobReportsIcon } from '@components/icons/sidebar/admin/JobReports/icon';
import { AdminsIcon } from '@components/icons/sidebar/admin/Admins';
import { DashboardIcon } from '@components/icons/sidebar/user/Dashboard/icon';

import { SidebarLink } from '../SidebarLink';
import { SMSWhitelistIcon } from '@wrappers/routes/AuthenticatedRoutes/components/Routes/components/Sidebar/icons/SMSWhitelist';
import { SettingsIcon } from '@components/icons/sidebar/admin/Settings';
import { useAuthContext } from '@providers/AuthProvider';
import { Divider } from '@components/Divider';

export const AdminMenu = () => {
  const { me } = useAuthContext();
  const isSuperAdmin = me?.roles?.ROLE_SUPER_ADMIN;
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  return (
    <>
      <SidebarLink
        to={routes.adminDashboard.make()}
        text="Dashboard"
        Icon={DashboardIcon}
        iconOffset={3}
        activeRoutes={[routes.adminDashboard.make()]}
      />
      <SidebarLink
        to={routes.adminOrganizationsList.make()}
        text="Organizations"
        Icon={OrganizationsIcon}
        iconOffset={2}
        activeRoutes={[routes.adminOrganizationsList.make()]}
      />
      <SidebarLink
        to={routes.adminWorkspacesList.make()}
        text="Workspaces"
        Icon={WorkspaceIcon}
        iconOffset={2}
        activeRoutes={[routes.adminWorkspacesList.make()]}
      />
      <SidebarLink
        to={routes.adminUsersList.make()}
        text="Users"
        Icon={UsersIcon}
        iconOffset={2}
        activeRoutes={[routes.adminUsersList.make()]}
      />
      <SidebarLink
        to={routes.adminCanvasCoursesList.make()}
        text="Canvas"
        Icon={UsersIcon}
        iconOffset={2}
        activeRoutes={[routes.adminCanvasCoursesList.make()]}
      />

      {isVsBilling ? (
        <>
          <Divider className="divider" />
          <SidebarLink
            to={routes.adminSubscriptions.make()}
            text="Subscriptions"
            Icon={SubscriptionsIcon}
            iconOffset={1}
            activeRoutes={[routes.adminSubscriptions.make()]}
          />
          <SidebarLink
            to={routes.adminBillingAccountsList.make()}
            text="Billing Accounts"
            Icon={BillingAccountsIcon}
            iconOffset={0}
            activeRoutes={[routes.adminBillingAccountsList.make()]}
          />
          <SidebarLink
            to={routes.adminTransactions.make()}
            text="Transactions"
            Icon={TransactionsIcon}
            iconOffset={3}
            activeRoutes={[routes.adminTransactions.make(), routes.adminTransactionCodesList.make()]}
          />
          <SidebarLink
            to={routes.adminJobsReport.make()}
            text="Jobs Report"
            Icon={JobReportsIcon}
            iconOffset={4}
            activeRoutes={[routes.adminJobsReport.make()]}
          />
        </>
      ) : null}

      {isSuperAdmin ? (
        <>
          <Divider className="divider" />
          <SidebarLink
            to={routes.adminAdminsList.make()}
            text="Admin Management"
            Icon={AdminsIcon}
            iconOffset={0}
            activeRoutes={[routes.adminAdminsList.make()]}
          />
        </>
      ) : null}

      <Divider className="divider" />
      <SidebarLink
        to={routes.adminDiff.make()}
        text="Diff Tool"
        Icon={AdminsIcon}
        iconOffset={0}
        activeRoutes={[routes.adminDiff.make()]}
      />
      <Divider className="divider" />
      <SidebarLink
        to={routes.adminSMSCountries.make()}
        text="SMS"
        Icon={SMSWhitelistIcon}
        iconOffset={0}
        activeRoutes={[routes.adminSMSCountries.make(), routes.adminSMSWhitelist.make()]}
      />
      <SidebarLink
        to={routes.adminSSOProvidersList.make()}
        text="SSO Providers"
        Icon={Briefcase}
        iconOffset={1}
        activeRoutes={[routes.adminSSOProvidersList.make()]}
      />
      <SidebarLink
        to={routes.adminSettings.make()}
        text="Settings"
        Icon={SettingsIcon}
        iconOffset={0}
        activeRoutes={[routes.adminSettings.make()]}
      />
    </>
  );
};
