import { Routes } from './components/Routes';
import { useAuthContext } from '@providers/AuthProvider';

export const AuthenticatedRoutes = () => {
  const { organization } = useAuthContext();
  if (!organization) {
    return null;
  }
  return <Routes />;
};
