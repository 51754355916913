import React from 'react';
import styled from 'styled-components';

import { Block } from '@components/Block';
import { Input } from '@components/form-elements/Input';
import { CrossIcon } from './icons/CrossIcon';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tag {
    position: relative;
    padding: 4px 25px 4px 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #858dbd;
    background: #f1f5fb;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;

    svg {
      position: absolute;
      right: 8px;
      top: 5px;
    }
  }
`;
const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px;
    height: 30px;
  }
`;

export const TagsBlock = () => {
  const { form, setFormItemValue, isLoading } = usePageContext();

  const onChange = async (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    await setFormItemValue('tags', value.split(','));
  };
  const deleteTag = async (tag: string) => {
    await setFormItemValue(
      'tags',
      (form.item?.tags ?? []).filter((t: string) => t !== tag),
    );
  };

  const tagsValue = (form.item?.tags ?? []).join(',');
  const tagsArray = (form.item?.tags ?? []).filter((tag: string) => !!tag);

  return (
    <Block title="Tags" description={<p>Comma separated</p>}>
      <SmallWhiteInput
        name="tags"
        label="Add tags..."
        value={tagsValue}
        disabled={isLoading}
        onChange={onChange}
        required={false}
      />
      <Tags>
        {tagsArray.map((tag: string, index: number) => (
          <button className="tag" key={index} onClick={() => deleteTag(tag)}>
            {tag}
            <CrossIcon />
          </button>
        ))}
      </Tags>
    </Block>
  );
};
