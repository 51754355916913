import { useState } from 'react';
import styled from 'styled-components';
import { BarElement, CategoryScale, Chart as ChartJS, Filler, LinearScale, LineElement, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { Button } from '@components/form-elements/buttons/Button';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

ChartJS.register(CategoryScale, PointElement, LineElement, LinearScale, BarElement, Filler);
ChartJS.defaults.color = '#858dbd';

const Wrapper = styled.div`
  .periods {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 200px;
  }

  .totalJobs {
    padding: 2px 0 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #858dbd;

    .value {
      font-weight: 600;
    }
  }
`;
const ChartWrapper = styled.div`
  height: 214px;
  padding: 10px 0 0;
`;

type DataItem = {
  date: string;
  value: number;
};

type TProps = {
  data: {
    week?: DataItem[];
    month?: DataItem[];
    totalJobs: number;
  };
};

export const PerformanceReview = ({ data }: TProps) => {
  const [period, setPeriod] = useState<'7days' | '30days'>('7days');

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderRadius: 2,
    borderWidth: 4,
    lineTension: 0.4,
    borderColor: '#7CB1FE',
    pointHoverRadius: 8,

    pointBorderWidth: 3,
    pointHoverBorderWidth: 3,

    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',

    pointBorderColor: 'rgba(124, 177, 254, 1)',
    pointHoverBorderColor: '#7CB1FE',
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: '#d5def2',
          tickBorderDash: [2, 4],
        },
        border: {
          display: false,
          dash: [2, 4],
        },
      },
    },
  };

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const periodData = period === '7days' ? data.week || [] : data.month || [];
  const labels = periodData.map((item: { date: string }) => weekDays[new Date(item.date).getDay()]);

  const chartData = {
    labels,
    datasets: [
      {
        data: periodData.map((item: { value: number }) => item.value),
        fill: true,
        backgroundColor: 'rgba(124, 177, 254, 0.1)',
        pointRadius: 4,
      },
    ],
  };

  return (
    <>
      <Wrapper>
        <div className="periods">
          <HeaderSwitchWrapper key="tabSwitcher">
            <Button onClick={() => setPeriod('7days')} className={`button ${period === '7days' ? 'active' : ''}`}>
              Last 7 days
            </Button>
            <Button onClick={() => setPeriod('30days')} className={`button ${period === '30days' ? 'active' : ''}`}>
              Last 30 days
            </Button>
          </HeaderSwitchWrapper>
        </div>
        <div className="totalJobs">
          Total jobs: <span className="value">{data.totalJobs || 0}</span>
        </div>
        <ChartWrapper>
          <Line options={options} data={chartData} />
        </ChartWrapper>
      </Wrapper>
    </>
  );
};
