export const getASOneDownloadLink = () => {
  const location = window.location.hostname;

  if (location === 'www.autoscript.ai') {
    return 'https://autoscript-one.s3.amazonaws.com/AutoScriptOne-win-Setup.exe';
  }
  if (location === 'staging.autoscript.ai') {
    return 'https://autoscript-one-develop.s3.amazonaws.com/AutoScriptOneDevelop-win-Setup.exe';
  }

  if (location === 'demo.autoscript.ai') {
    return 'https://autoscript-one-demo.s3.amazonaws.com/AutoScriptOneDemo-win-Setup.exe';
  }

  return 'https://autoscript-one-develop.s3.amazonaws.com/AutoScriptOneDevelop-win-Setup.exe';
};
