import styled from 'styled-components';

import { FileUpload } from '@components/FileUpload';
import { Input } from '@components/form-elements/Input';
import { SmallWhiteTextarea } from '@components/form-elements/Textarea/variations/SmallWhiteTextarea';

import { Block } from '@components/Block';
import { UploadObjectType } from '@constants/enums/uploadObjectType';
import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { Link as RouterLink } from 'react-router-dom';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { checkSlugIsAvailable } from '@queries/repositories/checkSlugIsAvailable';
import { TUser } from 'app/types/entities/TUser';
import { TStatusValue } from '@components/FileUpload/components/dropzone/types/TStatusValue';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { EPermission } from 'app/types/enums/EPermission';
import { EACCEPTED_AUDIO_FORMATS } from 'app/types/enums/EACCEPTED_AUDIO_FORMATS';
import { EACCEPTED_DOCUMENT_FORMATS } from 'app/types/enums/EACCEPTED_DOCUMENT_FORMATS';

const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px;
    height: 30px;
  }
`;

const InfoBlockContent = styled.div`
  color: #00122d;
`;

const AssigneeBlockContent = styled(InfoBlockContent)`
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin: 0;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      margin: 0;
    }
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const slugify = (text: string) => {
  return text
    .replace(/[^a-z0-9]/gi, '-')
    .replace(/-+/g, '-')
    .toLowerCase();
};

export type TForm = {
  name: string;
  slug: string;
  notes: string;
  assignee: TUser | null;
  audioFiles: string[];
  documentFiles: string[];
};

type TProps = {
  repositoryId: string;
  audioFiles: TFileWithMeta[];
  setAudioFiles: (files: TFileWithMeta[]) => void;
  audioFilesFromServer: TFileWithMeta[];
  setAudioFilesFromServer: (files: TFileWithMeta[]) => void;
  newAudioFiles: TFileWithMeta[];
  setNewAudioFiles: (files: TFileWithMeta[]) => void;
  documentFiles: TFileWithMeta[];
  setDocumentFiles: (files: TFileWithMeta[]) => void;
  documentFilesFromServer: TFileWithMeta[];
  setDocumentFilesFromServer: (files: TFileWithMeta[]) => void;
  newDocumentFiles: TFileWithMeta[];
  setNewDocumentFiles: (files: TFileWithMeta[]) => void;
  handleChangeAudioStatus: (file: TFileWithMeta, status: TStatusValue) => Promise<void>;
  handleChangeDocumentStatus: (file: TFileWithMeta, status: TStatusValue) => Promise<void>;
  onNewAudioFiles: (files: TFileWithMeta[]) => Promise<void>;
  onNewDocumentFiles: (files: TFileWithMeta[]) => Promise<void>;
  isFilesLoading: boolean;
  form: TForm;
  setForm: (form: TForm) => void;
  slugIsAvailable: boolean;
  setSlugIsAvailable: (isAvailable: boolean) => void;
  isNew: boolean;
};

export const MainBlock = ({
  repositoryId,
  audioFiles,
  setAudioFiles,
  audioFilesFromServer,
  setAudioFilesFromServer,
  newAudioFiles,
  setNewAudioFiles,
  documentFiles,
  setDocumentFiles,
  documentFilesFromServer,
  setDocumentFilesFromServer,
  newDocumentFiles,
  setNewDocumentFiles,
  handleChangeAudioStatus,
  handleChangeDocumentStatus,
  onNewAudioFiles,
  onNewDocumentFiles,
  isFilesLoading,
  form,
  setForm,
  slugIsAvailable,
  setSlugIsAvailable,
  isNew,
}: TProps) => {
  const { organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();

  const editAssignee = (form: TForm) => {
    openModal(ModalNamesEnum.AssignRepositoryModal, {
      repositoryId,
      repository: form,
      onSuccess: (assignee: TUser) => {
        setForm({ ...form, assignee });
        closeModal();
      },
    });
  };

  const onRemoveAudioFile = (file: TFileWithMeta) => {
    const removeFilter = (f: TFileWithMeta) => f.meta.Id !== file.meta.Id;
    setAudioFilesFromServer([...audioFilesFromServer.filter(removeFilter)]);
    setNewAudioFiles([...newAudioFiles.filter(removeFilter)]);
  };

  const onRemoveDocFile = (file: TFileWithMeta) => {
    const removeFilter = (f: TFileWithMeta) => f.meta.Id !== file.meta.Id;
    setDocumentFilesFromServer([...documentFilesFromServer.filter(removeFilter)]);
    setNewDocumentFiles([...newDocumentFiles.filter(removeFilter)]);
  };

  const notesDescription = <p>You can provide additional information</p>;
  const attachmentsDescription = <p>You can provide additional documents, exhibits and audio files.</p>;
  const audioAttachmentsDescription = <p>You can add video and audio files to submit as jobs.</p>;
  const assigneeDescription = <p>You can assign a case to a specific user</p>;

  return (
    <>
      <Block title="Provide a unique title for the case">
        <SmallWhiteInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (isNew && (!form.slug || (form.slug === slugify(form.name) && form.slug.length < 20))) {
              return setForm({ ...form, name: e.target.value, slug: slugify(e.target.value) });
            }
            setForm({ ...form, name: e.target.value });
          }}
          name="name"
          label="Title"
          value={form.name ?? ''}
          required={true}
        />
      </Block>
      <Block title="Case link">
        <SmallWhiteInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setForm({ ...form, slug: e.target.value });
          }}
          name="name"
          label="Case ID"
          value={form.slug ?? ''}
          required={true}
          handleBlur={async () => {
            if (form.slug === '') {
              return setSlugIsAvailable(true);
            }
            const data = await checkSlugIsAvailable(organization?.slug ?? '', form.slug, repositoryId);
            setSlugIsAvailable(data?.available);
          }}
          error={!slugIsAvailable}
        />
        {!slugIsAvailable ? <div className="text-[#ff2f2f]">Case ID is not available</div> : <></>}
      </Block>
      <Block title="Notes" description={notesDescription}>
        <SmallWhiteTextarea
          onChange={(e) => {
            setForm({ ...form, notes: e.target.value });
          }}
          name="notes"
          label="Type your notes here"
          value={form.notes ?? ''}
        />
      </Block>
      <Block title="Attachments" description={attachmentsDescription}>
        <FileUpload
          objectId={repositoryId}
          objectType={UploadObjectType.REPOSITORY}
          files={documentFiles}
          setFiles={setDocumentFiles}
          onChangeStatus={handleChangeDocumentStatus}
          onNewFiles={onNewDocumentFiles}
          isFilesLoading={isFilesLoading}
          accept={EACCEPTED_DOCUMENT_FORMATS.join(',')}
          onRemoveFile={onRemoveDocFile}
          multiple={true}
        />
        <div className="uploadDescription">
          Upload your files here. You can upload multiple files at once. Supported file types: .doc, .docx, .txt, .pdf,
          .rtf
        </div>
      </Block>

      <Block title="Audio files" description={audioAttachmentsDescription}>
        <FileUpload
          objectId={repositoryId}
          objectType={UploadObjectType.REPOSITORY}
          files={audioFiles}
          setFiles={setAudioFiles}
          onChangeStatus={handleChangeAudioStatus}
          onNewFiles={onNewAudioFiles}
          isFilesLoading={isFilesLoading}
          accept={EACCEPTED_AUDIO_FORMATS.join(',')}
          allowPlayback={true}
          onRemoveFile={onRemoveAudioFile}
          multiple={true}
        />
        <div className="uploadDescription">
          Upload audio files here. You can upload multiple files at once. Supported file types include: Zoom
          multi-channel audio, For The Record (FTR), MP3, WAV and other audio formats.
        </div>
      </Block>

      <Restricted to={EPermission.editRepositories}>
        <>
          {repositoryId ? (
            <Block title="Assignee" description={assigneeDescription}>
              <AssigneeBlockContent>
                {form.assignee ? (
                  <div className="assignee">
                    <StyledLink to={routes.userDetails.make(organization?.slug ?? '', form.assignee?.id)}>
                      <p className="assignName">
                        {form.assignee?.name} {form.assignee?.lastname}
                      </p>
                      <p className="assignEmail">{form.assignee?.email || ''}</p>
                    </StyledLink>
                  </div>
                ) : null}
                <PencilIcon onClick={() => editAssignee(form)} />
              </AssigneeBlockContent>
            </Block>
          ) : null}
        </>
      </Restricted>
    </>
  );
};
