import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { GearSvg } from '@components/icons/Gear';
import { Restricted } from '@providers/PermissionProvider/Restricted';

import { TProps } from './types/TProps';
import { EllipsisLink } from './styles/EllipsisLink';
import { DetailsWrapper } from './styles/DetailsWrapper';
import { WorkspaceName } from './styles/WorkspaceName';
import { EPermission } from 'app/types/enums/EPermission';

export const SelectedWorkspaceName = ({
  workspace,
  organization,
  isOpen,
  setWorkspaceListOpen,
  multipleWorkspacesInOrg,
}: TProps) => {
  const navigate = useNavigate();

  const closeWorkspaceList = () => multipleWorkspacesInOrg && setWorkspaceListOpen(!isOpen);

  return (
    <>
      {isOpen ? (
        <EllipsisLink onClick={closeWorkspaceList}>{workspace.name ?? 'Workspaces'}</EllipsisLink>
      ) : (
        <div className="ml-2">
          <Restricted
            to={EPermission.manageWorkspaces}
            fallback={
              <WorkspaceName onClick={closeWorkspaceList} style={{ lineHeight: '250%' }}>
                {workspace.name}
              </WorkspaceName>
            }
          >
            <>
              <WorkspaceName onClick={closeWorkspaceList}>{workspace.name}</WorkspaceName>
              <DetailsWrapper onClick={() => navigate(routes.workspaceDetails.make(organization.slug, workspace.slug))}>
                <span className="details-span">Details</span>
                <GearSvg style={{ width: '12px' }} />
              </DetailsWrapper>
            </>
          </Restricted>
        </div>
      )}
    </>
  );
};
