import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 26px;
  right: 15px;

  button {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    border: 1px solid transparent;
    //border-image: linear-gradient(to right, #7aabf3 0%, #2765be 100%) 1;
    border-radius: 5px;
    white-space: nowrap;

    background-image: linear-gradient(white, white), linear-gradient(to right, #7aabf3, #2765be);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    .inner {
      border-radius: 5px;
      padding: 5px 15px;
      color: transparent;
      background-image: linear-gradient(to right, #7aabf3, #2765be);
      background-clip: text;
    }

    :hover {
      background-image: linear-gradient(to right, #7aabf3, #2765be);
      background-clip: border-box;
      .inner {
        color: #ffffff;
      }
    }
  }
`;
