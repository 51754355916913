import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { PositiveIcon } from '@components/icons/Positive';
import { Button } from '@components/form-elements/buttons/Button';

import { routes } from '@routes';
import { Wrapper } from './styles';

const StyledIconWrapper = styled.div`
  width: 60px;
  margin: 0 auto;
`;

export const RegistrationSuccessPage = () => {
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(location.search);
  const email = urlSearchParams.get('email');
  const goto = urlSearchParams.get('goto');

  const goToLogin = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (goto) {
      navigate(`${routes.signin.make()}?goto=${goto}&email=${encodeURIComponent(email ?? '')}`);
      return;
    }
    navigate(routes.signin.make());
  };

  return (
    <Wrapper>
      <StyledIconWrapper>
        <PositiveIcon className="positiveIcon" />
      </StyledIconWrapper>
      <h1 className="great">Great!</h1>
      <h2 className="accountCreated">Your account has been created successfully.</h2>

      <Button size="medium" onClick={goToLogin}>
        Sign In
      </Button>
    </Wrapper>
  );
};
