import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';
import { useAPI } from '@hooks/useAPI';
import { JobsService } from '../../../../../../API';

const ButtonsWrapper = styled.div`
  padding: 10px 30px 30px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;

  button {
    padding: 9px 15px;
    min-width: 130px;
  }

  .leftSide {
    display: flex;

    button {
      flex: 0 0 auto;
      margin: 0 30px 0 0;
      width: auto;
    }
  }
`;

type TProps = {
  filesLength: number;
  saveFilesOrder: () => void;
  errorJobNameMessage: string;
};

export const Buttons = ({ filesLength, saveFilesOrder, errorJobNameMessage }: TProps) => {
  const { call } = useAPI();
  const { isLoading, newFiles, isFilesLoading, isFilesUploading, setIsLoading, form } = usePageContext();
  const navigate = useNavigate();
  const { workspace, organization } = useAuthContext();
  const [shouldGoBackAfterLoading, setShouldGoBackAfterLoading] = useState(false);

  useEffect(() => {
    if (shouldGoBackAfterLoading && !isLoading) {
      navigate(-1);
    }
  }, [isLoading]);

  const noBillingAccount = !organization?.billingAccount?.id;
  // const noBalance = (organization?.billingAccount?.currentBalance || 0) <= 0;
  // const cannotSubmitJob = noBillingAccount || noBalance;
  const isThereBadFiles = !!newFiles.find((f) => f.meta?.status === 'done' && f.meta?.duration === 0);
  const isThereNotReadyFileStatuses = !!newFiles.find((f) => !['done', 'removed'].includes(f.meta?.status));
  const isThereJobNameError = !!errorJobNameMessage;
  const disableCondition =
    isThereBadFiles ||
    isThereNotReadyFileStatuses ||
    isLoading ||
    isFilesLoading ||
    isFilesUploading ||
    !filesLength ||
    form.disabled ||
    noBillingAccount ||
    isThereJobNameError;
  const draftDisableCondition =
    isThereJobNameError ||
    isThereBadFiles ||
    isThereNotReadyFileStatuses ||
    isLoading ||
    isFilesLoading ||
    isFilesUploading ||
    form.disabled;
  const meetingTypeCondition =
    (form.item?.meetingType ?? '') === 'RECORDING_SESSION_REMOTE'
      ? !!form.item?.meetingLink
      : (form.item?.meetingType ?? '') === 'RECORDING_SESSION_HYBRID'
        ? true
        : !!form.item?.meetingAddress;
  // ? !!form.item?.meetingLink && !!form.item?.meetingAddress
  const scheduleDisableCondition =
    isThereBadFiles ||
    isThereNotReadyFileStatuses ||
    isLoading ||
    isFilesLoading ||
    isFilesUploading ||
    form.disabled ||
    !form.item?.meetingType ||
    !form.item?.meetingDate ||
    !form.item?.meetingTime ||
    !form.item?.meetingTimezone ||
    !form.item?.meetingAssigneeId ||
    !meetingTypeCondition;

  const handleDraft = async () => {
    if (!workspace || !form.item) {
      return;
    }
    setIsLoading(true);
    saveFilesOrder();
    setShouldGoBackAfterLoading(true);
    await call(
      JobsService.createOrEditWsJob({ wsid: workspace?.id, requestBody: { ...form.item, status: 'JOB_STATUS_DRAFT' } }),
    );
    setIsLoading(false);
    return;
  };

  const onSchedule = async () => {
    if (!workspace) {
      return;
    }
    setIsLoading(true);
    saveFilesOrder();
    setShouldGoBackAfterLoading(true);
    await call(
      JobsService.createOrEditWsJob({
        wsid: workspace?.id || '',
        requestBody: {
          ...form.item,
          status: 'JOB_STATUS_SCHEDULED',
        },
      }),
    );
    setIsLoading(false);
    return;
  };

  const goBack = () => {
    navigate(-1);
    return;
  };

  const renderLeftSideButtons = () => {
    return (
      <>
        {form.item?.status && jobIsScheduled(form.item?.status) ? null : (
          <>
            <Button
              variant="contained"
              disabled={!organization?.permissions?.submitJobs || disableCondition}
              type="submit"
            >
              Calculate Price
            </Button>

            <Button variant="bold" onClick={handleDraft} type="button" disabled={draftDisableCondition}>
              Save Draft
            </Button>
          </>
        )}

        {form.item?.status && !jobIsScheduled(form.item?.status) ? null : (
          <Button variant="bold" onClick={onSchedule} type="button" disabled={scheduleDisableCondition}>
            Schedule
          </Button>
        )}
      </>
    );
  };

  return (
    <ButtonsWrapper>
      <div className="leftSide">{renderLeftSideButtons()}</div>
      <div className="rightSide">
        <Button variant="outlined" onClick={goBack} type="button">
          Cancel
        </Button>
      </div>
    </ButtonsWrapper>
  );
};
