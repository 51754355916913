import { useAuthContext } from '@providers/AuthProvider';
import { GenericPageError } from '@components/GenericPageError';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';

export const FormGenericErrors = () => {
  const { organization } = useAuthContext();
  const { isLoading } = usePageContext();

  if (isLoading) {
    return <></>;
  }
  const showContactAdminToSetBillingAccount =
    !organization?.permissions?.manageBilling && !organization?.billingAccount?.id;
  const contactAdminToSetBillingAccountMessage =
    'Please contact Organization Administrator to set up the Billing Account for the Organization';

  const showSetBillingAccount = !showContactAdminToSetBillingAccount && !organization?.billingAccount?.id;
  const setBillingAccountMessage = 'Please set up the Billing Account for the Organization';

  return (
    <>
      {showContactAdminToSetBillingAccount && <GenericPageError message={contactAdminToSetBillingAccountMessage} />}
      {showSetBillingAccount && <GenericPageError message={setBillingAccountMessage} />}
    </>
  );
};
