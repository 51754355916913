import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { routes } from '@routes';

import { validatePassword } from '@helpers/validatePassword';
import { Error } from '@components/Error';
import { Spinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { PositiveIcon } from '@components/icons/Positive';

import { EyeIcon } from '@components/icons/Eye';
import { EyeClosedIcon } from '@components/icons/EyeClosed';
import { CheckEmail } from '@components/icons/CheckEmail';

import { usePageContext } from '../../providers/PageProvider';
import { Wrapper } from './styles/Wrapper';

const StyledIconWrapper = styled.div`
  width: 60px;
  margin: 0 auto;
`;

export const PasswordResetPageContent = () => {
  const { password, setPassword, confirmation, setConfirmation, error, setError, isLoading, reset, isComplete } =
    usePageContext();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const goToLogin: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    navigate(routes.signin.make());
  };

  const goToRecovery: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    navigate(routes.passwordRecovery.make());
  };

  const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setError(validatePassword(password, confirmation));
    if (!validatePassword(password, confirmation)) {
      reset();
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const goToRegistration = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    navigate(routes.signup.make());
  };

  if (isComplete && !error) {
    return (
      <Wrapper>
        <div className="root">
          <StyledIconWrapper>
            <PositiveIcon className="positiveIcon" />
          </StyledIconWrapper>
          <h1 className="completePasswordReset">Password Reset</h1>
          <h2 className="completeYourPasswordHasBeen">
            Your password has been successfully reset. <br />
            Click below to log in.
          </h2>
          <Button className="margin30" onClick={goToLogin} size="medium">
            Continue
          </Button>
        </div>
      </Wrapper>
    );
  }

  if (isComplete && error === 'ExpiredCodeException') {
    return (
      <Wrapper>
        <div className="root">
          <StyledIconWrapper>
            <CheckEmail className="checkEmailIcon" />
          </StyledIconWrapper>
          <h1 className="completePasswordReset">Password Reset</h1>
          <h2 className="completeYourPasswordHasBeen">
            The password reset link is expired. <br />
            Please start the recovery process again.
          </h2>
          <Button className="margin30" onClick={goToRecovery} size="medium">
            Return
          </Button>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isLoading && <Spinner overlay={true} />}
      <div className="root">
        <div className="content">
          <div>
            <h1 className="setNewPassword">Set New Password</h1>
            <h2 className="yourNewPassword">Your new password must be different to previously used passwords.</h2>
          </div>

          <div className="form">
            <form onSubmit={handleFormSubmit}>
              <div className="margin30">
                <Input
                  onChange={(e) => {
                    setError('');
                    setPassword(e.target.value);
                  }}
                  onIconClick={togglePasswordVisibility}
                  name="password"
                  label="Password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={password || ''}
                  required={true}
                  icon={isPasswordVisible ? EyeIcon : EyeClosedIcon}
                />
              </div>
              <div className="margin30">
                <Input
                  onChange={(e) => {
                    setError('');
                    setConfirmation(e.target.value);
                  }}
                  onIconClick={togglePasswordVisibility}
                  name="confirmation"
                  label="Confirm password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  error={!!confirmation && confirmation !== password}
                  value={confirmation ?? ''}
                  required={true}
                  icon={isPasswordVisible ? EyeIcon : EyeClosedIcon}
                />
              </div>

              {error ? (
                <div className="formError">
                  <Error error={error} />
                </div>
              ) : null}

              <Button
                disabled={!password || !confirmation || password !== confirmation || isLoading}
                type="submit"
                size="medium"
                className="margin30"
              >
                Reset Password
              </Button>

              <p className="dontHaveAnAccount">
                {"Don't have an account yet? "}&nbsp;&nbsp;
                <span onClick={goToRegistration} className="signUp">
                  Sign Up
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
