import { Spinner } from '@components/spinners/Spinner';

import { Wrapper } from './styles/Wrapper';
import { usePageContext } from '../../providers/PageProvider';
import { RegistrationPageFirstStage } from './stages/first';
import { RegistrationPageSurveyStage } from './stages/signupSurvey';
import { RegistrationPagePhoneStage } from './stages/signupPhone';
import { RegistrationPageCodeStage } from './stages/signupCode';
import { RegistrationPageSuccessStage } from './stages/success';

export const RegistrationPageContent = () => {
  const { stage, isLoading } = usePageContext();

  return (
    <>
      {isLoading && <Spinner overlay={true} />}
      <Wrapper>
        {stage === 'first' ? <RegistrationPageFirstStage /> : null}
        {stage === 'signupSurvey' ? <RegistrationPageSurveyStage /> : null}
        {stage === 'signupPhone' ? <RegistrationPagePhoneStage /> : null}
        {stage === 'signupCode' ? <RegistrationPageCodeStage /> : null}
        {stage === 'success' ? <RegistrationPageSuccessStage /> : null}
      </Wrapper>
    </>
  );
};
