import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import background from './background.jpg';

export const Wrapper = styled.div`
  @media (max-width: 960px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1.188rem;
    padding-right: 1.313rem;
    padding-bottom: 1.188rem;
  }

  display: flex;
  flex-direction: row;
  text-align: center;
  min-height: 100vh;
  width: 100%;

  .leftSide {
    @media (max-width: 960px) {
      flex-basis: 100%;
      box-shadow: none;
      width: 100%;
    }
    flex-basis: 40%;
    text-align: center;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 0 100px rgba(47, 93, 135, 0.2);
    position: relative;
    max-width: 580px;
  }
  .rightSide {
    @media (max-width: 960px) {
      display: none;
    }
    background: center / cover no-repeat url(${background});
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 1500px) {
      flex-basis: 100%;
    }
  }
  .poweredBy {
    width: 400px;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #858dbd;
    margin-bottom: 35px;
    text-align: left;
    letter-spacing: 0.02em;
    & a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .convertZoom {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #2f5d87;
    max-width: 440px;
  }
  .link {
    color: currentColor;
    text-decoration: none;
  }
  .leftSideFooter {
    position: absolute;
    bottom: 50px;

    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #40608f;
  }
`;
