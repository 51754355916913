import styled from 'styled-components';

export const CellNoEllipsisContent = styled.div`
  margin: 0;
  overflow: visible !important;
  text-overflow: unset !important;
  text-wrap: none !important;
  white-space: normal !important;
  display: flex;
  gap: 10px;
`;
