import { TAudioFile } from 'app/types/entities/TAudioFile';

export const mapAttachmentsToTFileWithMeta = (attachments: TAudioFile[]) => {
  return attachments.map((attachment) => ({
    meta: {
      Id: attachment.id,
      name: attachment.fileName,
      size: Number(attachment.fileSize),
      status: 'done',
    },
    file: attachment,
    cancel: () => {},
    restart: () => {},
  }));
};
