import styled from 'styled-components';

export const LogoText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  margin: 0;
  padding: 0;
  margin-top: -2px;
  letter-spacing: 0.02em;
  color: #858dbd;
`;
