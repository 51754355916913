import React from 'react';

export const SixDots = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 14 14">
    <path
      d="M2.667 1.333a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0ZM7 0a1.333 1.333 0 1 0 0 2.667A1.333 1.333 0 0 0 7 0Zm5.667 2.667a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666ZM1.333 11.333a1.333 1.333 0 1 0 0 2.667 1.333 1.333 0 0 0 0-2.667Zm5.667 0A1.333 1.333 0 1 0 7 14a1.333 1.333 0 0 0 0-2.667Zm5.667 0a1.334 1.334 0 1 0 0 2.668 1.334 1.334 0 0 0 0-2.668ZM1.333 5.667a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666Zm5.667 0a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666Zm5.667 0a1.334 1.334 0 1 0 0 2.667 1.334 1.334 0 0 0 0-2.667Z"
      fill="#858DBD"
    />
  </svg>
);
