import styled from 'styled-components';

export const PickerHeader = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    padding: 0 10px;
    cursor: pointer;
  }
`;
