import { Popover } from '@components/Popover';

import { usePageContext } from '../../../../providers/PageProvider';
import { Filter } from './components/Filter';

export const FilterPopover = () => {
  const { anchorEl, setAnchorEl, dateFilter, setDateFilter } = usePageContext();

  return (
    <Popover
      styles="margin-top: 40px; margin-left: -100px;"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      <Filter filter={dateFilter} setFilter={setDateFilter} close={() => setAnchorEl(null)} />
    </Popover>
  );
};
