import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthContext } from '@providers/AuthProvider';
import { routes } from '@routes';
import { TJobComment } from 'app/types/entities/TJobComment';
import { TUser } from 'app/types/entities/TUser';
import { TickingDateAgo } from '@components/Date/TickingDateAgo';

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const Wrapper = styled.div`
  .comment {
    padding: 20px 0 0;
    display: flex;
    gap: 10px;

    .comment-right {
      .comment-title {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #858dbd;
        padding: 0 0 5px;
      }

      .comment-username a {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #858dbd;
        text-decoration: underline;
        margin: 0 10px 0 0;
      }

      .comment-date {
        font-weight: 400;
      }

      .comment-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #00122d;
      }
    }
  }
`;

const AvatarBlock = styled.div`
  flex: 0 0 auto;
  padding: 0 0 10px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
`;
const Avatar = styled.img`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
`;
const AvatarName = styled.div`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  text-transform: capitalize;
`;

type TProps = {
  comments?: TJobComment[];
};

export const renderAvatar = (user: TUser) => {
  if (!user) {
    return '';
  }
  const avatarName = `${user?.name[0]}${user?.lastname[0]}`;
  return (
    <AvatarBlock>
      {user?.avatar ? <Avatar src={user.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
    </AvatarBlock>
  );
};

export const CommentsList = ({ comments }: TProps) => {
  const { me, organization } = useAuthContext();

  if (!comments?.length) {
    return null;
  }

  return (
    <Wrapper>
      {comments?.map((comment) => (
        <div key={comment.id} className="comment">
          {renderAvatar(comment.creator)}
          <div className="comment-right">
            <div className="comment-title">
              <span className="comment-username">
                {comment.creator.id === me.id ? (
                  <StyledLink to={routes.accountPage.make()}>
                    {comment.creator.name} {comment.creator.lastname}
                  </StyledLink>
                ) : (
                  <StyledLink to={routes.userDetails.make(organization?.slug ?? '', comment.creator.id)}>
                    {comment.creator.name} {comment.creator.lastname}
                  </StyledLink>
                )}
              </span>
              <span className="comment-date">
                <TickingDateAgo date={comment.createdAt} />
              </span>
            </div>
            <div className="comment-text">
              {comment.comment.split('\n').map((c, i) => (
                <p key={i}>{c}</p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </Wrapper>
  );
};
