import { useState } from 'react';

import { DesktopTutorialVideo } from '@components/Modal/DesktopTutorialVideo';
import { VsLogo } from '@components/Logo';

import { DownloadDesktopCard } from './components/DownloadDesktop';

import { Wrapper } from './styles/Wrapper';
import { Box } from './styles/Box';
import { WelcomeAndMenu } from './components/WelcomeAndMenu';

export const Sidebar = () => {
  const [isDesktopTutorialOpen, setIsDesktopTutorialOpen] = useState(false);

  return (
    <>
      <Wrapper className="z-20 fixed top-0 h-full">
        <div className="container h-full flex flex-col">
          <Box>
            <VsLogo />
          </Box>

          <WelcomeAndMenu />

          <DownloadDesktopCard />
          <DesktopTutorialVideo isOpen={isDesktopTutorialOpen} onClose={() => setIsDesktopTutorialOpen(false)} />
        </div>
      </Wrapper>
    </>
  );
};
