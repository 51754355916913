import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import Wave from './wave.svg';
import { useEffect, useState } from 'react';
import { Spinner } from '@components/spinners/Spinner';
import { useAPI } from '@hooks/useAPI';
import { AuthService } from 'app/API';

const Root = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 130px;
  position: relative;
  text-align: center;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding: 40px;

  p {
    margin: 0;
  }
`;
const WaveWrapper = styled.div`
  padding: 0 40px 40px;

  svg {
    margin: 0 auto;
  }
`;

export const SSOLogin = () => {
  const { call } = useAPI();
  const { search } = useLocation();
  const [codeExchangeError, setCodeExchangeError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function callExchangeCodeEndpoint() {
      const params = new URLSearchParams(search);
      const code = params.get('code');
      const state = params.get('state');

      console.log('code', code);
      console.log('state', state);

      if (!code) {
        return;
      }

      console.log('exchanging code...');

      call(AuthService.ssoLogin({ requestBody: { code } }))
        .then((res) => {
          console.log('AuthService.ssoLogin', res.body);
          if (res.body.error) {
            setCodeExchangeError(res.body.error);
            setLoading(false);
            return;
          }

          window.location.href = '/dashboard';
        })
        .catch((err) => {
          console.error(err);
          setCodeExchangeError(err.message);
        });
    }

    callExchangeCodeEndpoint();
  }, []);

  const query = new URLSearchParams(search);
  const error = query.get('error');
  const errorDescription = query.get('error_description');

  const errorMessage = (
    <div>
      <p>There was an error logging in from the provider.</p>
      <p>Please try again or contact support.</p>
      <p>Technical Error Details: {error}</p>
      <pre className="whitespace-pre-line">{errorDescription}</pre>
    </div>
  );

  return (
    <Root>
      <Description>
        {error || codeExchangeError ? (
          codeExchangeError || errorMessage
        ) : (
          <p>Logging in from the provider. Please wait...</p>
        )}
      </Description>
      {loading ? (
        <div className="relative p-4">
          <Spinner />
        </div>
      ) : null}
      <WaveWrapper>
        <Wave />
      </WaveWrapper>
    </Root>
  );
};
