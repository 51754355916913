import { RelativeSpinner65 } from '@components/spinners/Spinner';
import { Wrapper } from './styles/Wrapper';

export const Initialization = () => {
  return (
    <Wrapper>
      <h1>Please wait...</h1>
      <RelativeSpinner65 />
    </Wrapper>
  );
};
