export const SoundWave = () => (
  <svg width="540" height="49" viewBox="0 0 540 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M155.962 14.4315C137.383 14.4315 97.2929 24.1644 86.048 24.1644C108.538 24.1644 145.695 35.5753 155.962 35.5753C166.229 35.5753 170.385 29.5342 187.986 29.5342C205.586 29.5342 221.72 49 239.321 49C256.922 49 261.811 29.5342 269.878 29.5342C277.945 29.5342 297.012 49 305.324 49C313.635 49 320.969 35.5753 326.591 35.5753C332.214 35.5753 343.459 49 354.948 49C366.437 49 375.971 39.7705 400.661 32.0514C420.413 25.876 501.784 24.2203 540 24.1644C453.219 24.1644 418.262 22.6541 400.661 17.9555C377.44 11.7565 370.104 0 354.948 0C339.792 0 334.658 14.4315 326.591 14.4315C318.524 14.4315 320.48 0 305.324 0C290.167 0 283.323 20.137 269.878 20.137C256.433 20.137 256.188 0 239.321 0C222.454 0 201.431 20.137 187.986 20.137C174.541 20.137 174.541 14.4315 155.962 14.4315Z"
      fill="#CADCF8"
      fillOpacity="0.2"
    />
    <path
      d="M358.126 10C376.704 10 407.506 24.5763 453.952 24.5763C408.728 24.5763 377.927 38.8136 358.126 38.8136C342.725 38.8136 340.036 30 328.791 30C320.235 30 317.546 38.8136 303.124 38.8136C288.701 38.8136 279.167 27.6271 271.1 27.6271C263.033 27.6271 248.855 40 232.965 40C215.364 40 199.964 27.6271 185.052 27.6271C173.318 27.6271 159.629 30.8475 140.317 30.8475C110.982 30.8475 129.805 24.5763 0 24.5763C117.827 24.5763 115.627 18.3051 140.317 18.3051C158.651 18.3051 176.985 21.6949 185.052 21.6949C206.075 21.6949 209.497 10 232.965 10C248.121 10 257.655 21.6949 271.1 21.6949C284.545 21.6949 286.745 10 303.124 10C318.035 10 317.057 18.3051 328.791 18.3051C342.236 18.3051 341.992 10 358.126 10Z"
      fill="#CADCF8"
      fillOpacity="0.2"
    />
    <path d="M0 24H540" stroke="#D0E3FE" />
  </svg>
);
