import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';

import { firstDayOfCurrentWeek } from './firstDayOfCurrentWeek';
import { firstDayOfCurrentMonth } from './firstDayOfCurrentMonth';
import { startOfCurrentDay } from './startOfCurrentDay';

export const getSelectedDateFilterValue = (jobsFilter: TJobsFilter) => {
  let to = jobsFilter.to || 0;
  let from = jobsFilter.from || 0;
  const today = startOfCurrentDay().getTime();
  const thisWeekStart = firstDayOfCurrentWeek().getTime();
  const thisMonthStart = firstDayOfCurrentMonth().getTime();
  if (from) {
    from = parseInt(new Date(from).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (to) {
    to = parseInt(new Date(to).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (from === today && to === today) {
    return 'today';
  }
  if (from === thisWeekStart && to === today) {
    return 'week';
  }
  if (from === thisMonthStart && to === today) {
    return 'month';
  }
  return '';
};
