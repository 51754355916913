import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: default;

  .label {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    padding: 0 0 15px;
    color: #858dbd;
  }

  .datepicker-wrapper {
    padding: 0 0 12px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0 0 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #858dbd;
  }
`;
