import React from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { Error } from '@components/Error';
import { Spinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { EmailIcon } from '@components/icons/Email';
import { CheckEmail } from '@components/icons/CheckEmail';

import { Wrapper } from './styles/Wrapper';
import { StyledIconWrapper } from './styles/StyledIconWrapper';
import { usePageContext } from '../../providers/PageProvider';

export const PasswordRecoveryPageContent = () => {
  const { isComplete, email, setEmail, setError, isLoading, error, recovery, resetForm } = usePageContext();
  const navigate = useNavigate();

  const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    recovery();
  };

  const backToLogin = (evt: React.MouseEvent) => {
    evt.preventDefault();
    navigate(routes.signin.make());
  };

  const goToRegistration = (evt: React.MouseEvent) => {
    evt.preventDefault();
    navigate(routes.signup.make());
  };

  const resend = (evt: React.MouseEvent) => {
    evt.preventDefault();
    resetForm();
  };

  if (isComplete) {
    return (
      <Wrapper>
        <StyledIconWrapper>
          <CheckEmail className="checkEmailIcon" />
        </StyledIconWrapper>
        <h1 className="completePlease">Check Your Email</h1>
        <h2 className="completeWeSent">
          We sent a password reset link to <br /> {email}
        </h2>
        <h3 className="completeDidnt">
          {'Didn’t receive the email?'}&nbsp;&nbsp;
          <span onClick={resend} className="completeResend">
            Click here to resend
          </span>
        </h3>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isLoading && <Spinner overlay={true} />}
      <div className="root">
        <div className="content">
          <div>
            <h1 className="forgotPassword">Forgot Password?</h1>
            <h2 className="noWorries">No worries, we’ll send you reset instructions.</h2>
          </div>

          <div className="form">
            <form onSubmit={handleFormSubmit}>
              <div className="margin30">
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError('');
                  }}
                  name="email"
                  label="Email"
                  type="text"
                  value={email || ''}
                  required={true}
                  icon={EmailIcon}
                />
              </div>

              {error ? (
                <div className="formError">
                  <Error error={error} />
                </div>
              ) : null}

              <Button size="medium" disabled={!email || isLoading} type="submit" className="margin30">
                Reset Password
              </Button>

              <a href="#" className="backToLogin" tabIndex={-1} onClick={backToLogin}>
                ← Back to log in
              </a>

              <p className="dontHaveAnAccount">
                {"Don't have an account yet? "}&nbsp;&nbsp;
                <span onClick={goToRegistration} className="signUp">
                  Sign Up
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
