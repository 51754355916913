import { TeamCard } from './TeamCard';
import { AddPlusButton } from '@components/form-elements/buttons/AddPlusButton';
import styled from 'styled-components';
import { TRole } from 'app/types/entities/TRole';

const Wrapper = styled.div`
  margin: 20px 0 0;

  & > .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

type TProps = {
  teams: TRole[];
  handleRemoval: (teamId: string) => void;
  addTeam?: () => void;
};

export function TeamCards({ teams, handleRemoval, addTeam }: TProps) {
  const handleDeleteTeam = (team: TRole) => {
    handleRemoval(team?.id);
  };

  return (
    <Wrapper>
      <ul role="list" className="list">
        {teams.map((team, idx) => (
          <TeamCard key={idx} team={team} handleDeleteTeam={handleDeleteTeam} />
        ))}
        {addTeam && teams.length == 0 ? (
          <li className="col-span-1 flex rounded-md border-lavender-blue border-dashed border-2 text-[#C1C1C1] font-[600] p-4">
            Add a team by clicking &ldquo;+&rdquo; icon on the right side.
          </li>
        ) : null}
        {addTeam ? <AddPlusButton onClick={addTeam} /> : null}
      </ul>
    </Wrapper>
  );
}
