import { ReactElement } from 'react';
import styled from 'styled-components';

import { Logo } from '@components/icons/Logo';
import { AdminLink } from '@components/Header/components/AdminLink';
import { NotificationsIcon } from '@components/Header/components/NotificationsIcon';
import { Avatar } from '@components/Header/components/Avatar';
import { useInitContext } from '@providers/InitProvider';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  min-width: 100vw;
  width: 100%;
  padding: 20px 25px;
  background: #ffffff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  position: relative;

  .right-side {
    position: absolute;
    right: 25px;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`;

const LogoText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  margin: 0;
  padding: 0;
  margin-top: -2px;
  letter-spacing: 0.02em;
  color: #858dbd;
`;

type TProps = {
  children: ReactElement | ReactElement[];
};

export const CleanLayout = ({ children }: TProps) => {
  const { isInitialized, isAuthenticated } = useInitContext();
  return (
    <Root>
      <Header>
        <Logo />
        <LogoText>Powered by VoiceScript Inc</LogoText>

        {isInitialized && isAuthenticated ? (
          <div className="right-side">
            <NotificationsIcon />
            <AdminLink />
            <Avatar />
          </div>
        ) : null}
      </Header>
      {children}
    </Root>
  );
};
