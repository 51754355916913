import { useState } from 'react';

import { routes } from '@routes';

import { DownloadApp } from '@components/icons/DownloadApp';
import { getDownloadDesktopAppLink } from '@helpers/getDownloadDesktopAppLink';

import { VideoIcon } from './icons/Video';
import { CrossIcon } from './icons/Cross';

import { Wrapper } from './styles/Wrapper';
import { CheckGuide } from './styles/CheckGuide';
import { HammerBackgroundIcon } from './styles/HammerBackgroundIcon';
import { UserService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

export const DownloadDesktopCard = () => {
  const { call } = useAPI();
  const [isDisabled, setIsDisabled] = useState(window.localStorage.getItem('disableDownloadDesktopCard'));

  const close = () => {
    window.localStorage.setItem('disableDownloadDesktopCard', 'true');
    setIsDisabled('true');
  };

  if (isDisabled) {
    return null;
  }

  return (
    <Wrapper>
      <div className="wrapper">
        <div className="noOverflow">
          <div className="cross-wrapper" onClick={close}>
            <CrossIcon />
          </div>
          <div className="title">Get the Desktop Editor</div>
          <div className="description">Enhanced editing features and audio player</div>
          <a
            className="download-as"
            href={getDownloadDesktopAppLink()}
            onClick={() => call(UserService.downloadedClient())}
            target="_blank"
            rel="noreferrer"
          >
            <DownloadApp />
            Download AutoScript
          </a>
          <HammerBackgroundIcon className="hammer" />
          <CheckGuide to={routes.desktopAppHelp.make()}>
            <VideoIcon />
            Check our Editor Guide
          </CheckGuide>
        </div>
      </div>
    </Wrapper>
  );
};
