import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { useAPI } from '@hooks/useAPI';
import { routes } from '@routes';
import { AuthService } from 'app/API';

type TState = {
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  stage: string;
  setStage: (v: string) => void;
  fromEmail: boolean;
  name: string;
  setName: (v: string) => void;
  lastName: string;
  setLastName: (v: string) => void;
  accountType: string;
  setAccountType: (v: string) => void;
  company: string;
  setCompany: (v: string) => void;
  agreement: boolean;
  setAgreement: (v: boolean) => void;
  goto: string;

  paramsEmail: string | null;
  email: string;
  setEmail: (v: string) => void;
  password: string;
  setPassword: (v: string) => void;
  confirmation: string;
  setConfirmation: (v: string) => void;

  howDidYouHearAboutUs: string;
  setHowDidYouHearAboutUs: (v: string) => void;
  howDidYouHearAboutUsReferral: string;
  setHowDidYouHearAboutUsReferral: (v: string) => void;
  howDidYouHearAboutUsComment: string;
  setHowDidYouHearAboutUsComment: (v: string) => void;
  error: string;
  setError: (v: string) => void;
  goToLogin: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  codeSentDate: number;
  setCodeSentDate: (v: number) => void;
  phone: string;
  setPhone: (v: string) => void;
  OTPCode: string;
  setOTPCode: (v: string) => void;
  register: () => Promise<void>;
};

const PageContext = createContext<TState>({
  isLoading: false,
  setIsLoading: () => null,
  stage: 'first',
  setStage: () => null,
  fromEmail: false,
  name: '',
  setName: () => null,
  lastName: '',
  setLastName: () => null,
  accountType: '',
  setAccountType: () => null,
  company: '',
  setCompany: () => null,
  agreement: false,
  setAgreement: () => null,
  goto: '',

  paramsEmail: '',
  email: '',
  setEmail: () => null,
  password: '',
  setPassword: () => null,
  confirmation: '',
  setConfirmation: () => null,

  howDidYouHearAboutUs: '',
  setHowDidYouHearAboutUs: () => null,
  howDidYouHearAboutUsReferral: '',
  setHowDidYouHearAboutUsReferral: () => null,
  howDidYouHearAboutUsComment: '',
  setHowDidYouHearAboutUsComment: () => null,
  error: '',
  setError: () => null,
  goToLogin: () => null,
  codeSentDate: 0,
  setCodeSentDate: () => null,
  phone: '',
  setPhone: () => null,
  OTPCode: '',
  setOTPCode: () => null,
  register: () => Promise.resolve(),
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fromEmail, setFromEmail] = useState(false);
  const [stage, setStage] = useState('first');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [company, setCompany] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [email, setEmail] = useState('');
  const [goto, setGoto] = useState('');
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState('');
  const [howDidYouHearAboutUsReferral, setHowDidYouHearAboutUsReferral] = useState('');
  const [howDidYouHearAboutUsComment, setHowDidYouHearAboutUsComment] = useState('');
  const [codeSentDate, setCodeSentDate] = useState(0);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [OTPCode, setOTPCode] = useState('');

  const urlSearchParams = new URLSearchParams(location.search);
  const paramsEmail = urlSearchParams.get('email');
  const paramsGoto = urlSearchParams.get('goto');

  useEffect(() => {
    if (paramsEmail) {
      setEmail(decodeURIComponent(paramsEmail));
      setFromEmail(true);
    } else {
      setEmail('');
    }
    if (paramsGoto) {
      setGoto(paramsGoto);
    } else {
      setGoto('');
    }
  }, [location.search]);

  const goToLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const urlSearchParams = new URLSearchParams(location.search);
    const goto = urlSearchParams.get('goto');
    const email = urlSearchParams.get('email');
    if (goto) {
      navigate(`${routes.signin.make()}?goto=${goto}&email=${encodeURIComponent(email ?? '')}`);
      return;
    }
    navigate(routes.signin.make());
  };

  const register = async () => {
    setIsLoading(true);
    const redirectUrl = window.localStorage.getItem('redirectUrl');
    await call(
      AuthService.register({
        requestBody: {
          email,
          name,
          lastName,
          password,
          phone,
          company,
          accountType,
          redirectUrl: redirectUrl ?? '',
          agreement,
          goto,
          fromEmail,
          howDidYouHearAboutUs,
          howDidYouHearAboutUsReferral,
          howDidYouHearAboutUsComment,
        },
      }),
      {
        onError: (error) => {
          setError(error.body?.message ?? 'Unknown error');
          setStage('first');
          setIsLoading(false);
        },
        onSuccess: () => {
          if (fromEmail && goto) {
            navigate(goto);
          }
          setStage('success');
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <PageContext.Provider
      value={{
        isLoading,
        setIsLoading,
        stage,
        setStage,
        fromEmail,
        name,
        setName,
        lastName,
        setLastName,
        accountType,
        setAccountType,
        company,
        setCompany,
        agreement,
        setAgreement,
        goto,
        paramsEmail,
        email,
        setEmail,
        password,
        setPassword,
        confirmation,
        setConfirmation,
        error,
        setError,
        howDidYouHearAboutUs,
        setHowDidYouHearAboutUs,
        howDidYouHearAboutUsReferral,
        setHowDidYouHearAboutUsReferral,
        howDidYouHearAboutUsComment,
        setHowDidYouHearAboutUsComment,
        goToLogin,
        codeSentDate,
        setCodeSentDate,
        phone,
        setPhone,
        OTPCode,
        setOTPCode,
        register,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
