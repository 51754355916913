import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';

import { Header } from '@components/Header';
import { Add as AddIcon } from '@components/icons/Add';
import { Button } from '@components/form-elements/buttons/Button';
import { DeadlineCalendar } from '@pages/User/DashboardPage/DeadlineCalendar';
import { JobsList } from '@pages/User/ScriberDashboardPage/JobsList';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { DashboardService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const StyledButton = styled(Button)`
  width: 130px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 24px 30px;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 950px;

  & .left-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  & .high-line {
    min-width: 600px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    & .card {
      width: 100%;
    }
  }

  & .line {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  & .card {
    min-width: 280px;
    width: 100%;
    height: 170px;
    background: #ffffff;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px;
    position: relative;

    &.card-deadline {
      height: auto;
      max-height: 670px;
      width: 280px;
    }

    &.card-jobsList {
      height: auto;
      max-height: 490px;
    }

    & .title {
      color: #858dbd;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .superTitle {
      color: #40608f;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .subTitle {
      color: #7cb1fe;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 47px;
      margin-top: -2px;
    }

    & .description {
      color: #858dbd;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
    }
  }
`;

export const ScriberDashboardPage = () => {
  const { call } = useAPI();
  const navigate = useNavigate();
  const { organization, workspace, me } = useAuthContext();
  const [dashboardInfo, setDashboardInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!organization?.id) {
      return;
    }
    setIsLoading(true);
    call(DashboardService.getScriberDashboardInfo({ oid: organization?.id ?? '' })).then((dashboardInfo) => {
      setDashboardInfo(dashboardInfo);
      setIsLoading(false);
    });
  }, [organization]);

  if (!me.organizations.length) {
    navigate(routes.accountPage.make());
    return null;
  }

  const handleAddJob = () => {
    navigate(routes.newJob.make(organization?.slug ?? '', workspace?.slug ?? ''));
  };

  const headerLeftSideContent = [];
  if (organization?.permissions?.submitJobs) {
    headerLeftSideContent.push(
      <StyledButton key="button" data-testid="create_job_header_button" size="small" onClick={handleAddJob}>
        <AddIcon height="12px" width="12px" />
        Create New Job
      </StyledButton>,
    );
  }

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>Dashboard - AutoScript</title>
        </Helmet>
        <Header title="Dashboard" leftSideContent={headerLeftSideContent} />

        <LinearProgressLoader active={isLoading} />
        <Wrapper></Wrapper>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - AutoScript</title>
      </Helmet>
      <Header title="Dashboard" leftSideContent={headerLeftSideContent} />

      <Wrapper>
        <div className="left-wrapper">
          <div className="line">
            <div className="card">
              <div className="title">My Efficiency</div>
              <div className="subTitle">{dashboardInfo?.myJobs?.length || 0} Active Jobs</div>
              <div className="description">Completed</div>
              <div className="description">{dashboardInfo?.completedJobs || 0}</div>
            </div>

            <div className="card">
              <div className="title">Number of Jobs to Claim</div>
              <div className="subTitle">{dashboardInfo?.jobsToClaim?.length || 0} Available</div>
              <div className="description">Total Limit</div>
              <div className="description">
                {dashboardInfo?.concurrentJobsLimit
                  ? `${dashboardInfo?.concurrentJobsLimit} concurrent jobs`
                  : 'Not set'}
              </div>
            </div>
          </div>
          <div className="line">
            <div className="card card-jobsList">
              <div className="superTitle">My Jobs List</div>
              <JobsList jobs={dashboardInfo?.myJobs ?? []} />
            </div>
            <div className="card card-jobsList">
              <div className="superTitle">Jobs Available to Claim</div>
              <JobsList jobs={dashboardInfo?.jobsToClaim ?? []} />
            </div>
          </div>
        </div>
        <div className="card card-deadline">
          <div className="superTitle">Deadline Calendar</div>
          <DeadlineCalendar calendar={dashboardInfo?.deadlineCalendar ?? []} />
        </div>
      </Wrapper>
    </>
  );
};
