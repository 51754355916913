import { useLocation } from 'react-router-dom';

import { StyledLink } from './styles/StyledLink';
import { LinkContent } from './components/LinkContent';
import { TProps } from './types/TProps';
import { isLinkActive } from './helpers/isLinkActive';

export const SidebarLink = ({ to, className = 'sidebar-link', text, Icon, iconOffset, activeRoutes }: TProps) => {
  const location = useLocation();

  let active = false;
  activeRoutes.forEach((route) => {
    if (isLinkActive(route, location)) {
      active = true;
    }
  });
  return (
    <StyledLink to={to} className={`${className} ${active ? 'active' : ''}`}>
      <LinkContent text={text} Icon={Icon} iconOffset={iconOffset} active={active} />
    </StyledLink>
  );
};
