import styled from 'styled-components';

type TProps = {
  iconOffset: number;
};

export const StyledIconLinkContent = styled('span')`
  display: flex;
  width: 200px;
  font-family: 'General Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  color: #858dbd;
  position: relative;
  padding-left: 31px;
  flex: 0 0 auto;

  svg,
  img {
    position: absolute;
    top: 50%;
    left: ${(props: TProps) => props.iconOffset || 0}px;
    transform: translate(0, -50%);
  }
`;
