import styled from 'styled-components';

import { WhiteTimeZoneSelect } from './WhiteTimeZoneSelect';

export const SmallWhiteTimeZoneSelect = styled(WhiteTimeZoneSelect)`
  .select {
    padding: 7px 10px;
  }

  .label {
    left: 11px;
  }

  .optionsLabel {
    padding: 8px 0;
  }
`;
