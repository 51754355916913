type TProps = {
  alt?: string;
  title?: string;
  width?: string;
};

export const ASOne = ({ width = '18px' }: TProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13359 2.01314C2.83565 2.01314 2.54992 2.13167 2.33924 2.34265C2.12857 2.55363 2.01021 2.83978 2.01021 3.13815V14.8618C2.01021 15.1602 2.12857 15.4464 2.33924 15.6574C2.54992 15.8683 2.83565 15.9869 3.13359 15.9869H14.8403C15.1382 15.9869 15.4239 15.8683 15.6346 15.6574C15.8453 15.4464 15.9636 15.1602 15.9636 14.8618V11.6645C15.9636 11.1086 16.4136 10.6579 16.9687 10.6579C17.5238 10.6579 17.9738 11.1086 17.9738 11.6645V14.8618C17.9738 15.6941 17.6437 16.4923 17.056 17.0809C16.4684 17.6694 15.6713 18 14.8403 18H3.13359C2.30251 18 1.50547 17.6694 0.917808 17.0809C0.330146 16.4923 0 15.6941 0 14.8618V3.13815C0 2.30586 0.330145 1.50766 0.917808 0.919143C1.50547 0.330626 2.30251 0 3.13359 0H6.32632C6.88142 0 7.33142 0.450657 7.33142 1.00657C7.33142 1.56248 6.88142 2.01314 6.32632 2.01314H3.13359Z"
        fill="#858DBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71809 10.2999C7.32717 9.90517 7.32976 9.26785 7.72387 8.87636L14.5643 2.08143L11.3051 2.06817C10.75 2.06591 10.3018 1.61342 10.3041 1.05752C10.3064 0.501608 10.7582 0.0527857 11.3133 0.0550452L16.999 0.0781812C17.5541 0.0804393 18.0022 0.532923 18 1.08883L17.9769 6.7828C17.9746 7.33871 17.5228 7.78753 16.9677 7.78528C16.4126 7.78302 15.9644 7.33053 15.9667 6.77462L15.9799 3.51071L9.13952 10.3056C8.74541 10.6971 8.10901 10.6945 7.71809 10.2999Z"
        fill="#858DBD"
      />
    </svg>
  );
};
