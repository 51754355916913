import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { routes } from '@routes';
import { WorkspacesService } from '../../../API';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  display: flex;

  .leftSide {
    width: 230px;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #878787;
    flex: 0 0 auto;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      padding: 0 0 10px;
    }
  }

  .rightSide {
    width: 100%;

    .rightSide-columns {
      display: flex;
      gap: 10px;
    }
  }
`;

const FirstFormRowWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 0 0 5px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const MessagesFormRowWrapper = styled.div`
  width: 100%;
`;
const MessagesInfoMessage = styled.div`
  color: #858dbd;
  text-align: left;
  padding: 0 0 20px;
`;
const MessagesErrorMessage = styled.div`
  color: #ff2f2f;
  font-weight: 500;
  text-align: center;
  line-height: 100%;
  padding: 0 0 20px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 280px;
  flex: 0 0 auto;

  .whiteInput {
    padding: 4px 0 0;

    input {
      height: 30px;
      background: white !important;
    }
  }
`;

const SaveWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 0 20px;
`;

const SaveButton = styled(Button)`
  width: 130px;
`;

type TProps = {
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
};

export const WorkspaceDetailsForm = ({ isSubmitting, setIsSubmitting }: TProps) => {
  const { call } = useAPI();
  const { workspace, updateMe, organization } = useAuthContext();
  const navigate = useNavigate();
  const canEditWS = organization?.permissions?.manageWorkspaces;

  const [formValues, setFormValues] = useState({
    name: workspace?.name,
    slug: workspace?.slug,
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    slug: '',
  });
  const [queryError, setQueryError] = useState('');

  useEffect(() => {
    setFormValues({
      name: workspace?.name,
      slug: workspace?.slug,
    });
  }, [workspace?.id]);

  const isSlugAvailable = async (value: string) => {
    setIsSubmitting(true);
    // only a-z 0-9 and - are allowed
    const regex = /^[a-z0-9-]+$/;
    if (!value.match(regex)) {
      setFormErrors({ ...formErrors, slug: 'Only a-z, 0-9 and - are allowed' });
      setIsSubmitting(false);
      return;
    }

    const data = await call(
      WorkspacesService.isSlugAvailable({
        oid: organization?.id ?? '',
        wsSlug: value,
      }),
    );
    data.available
      ? setFormErrors({ ...formErrors, slug: '' })
      : setFormErrors({ ...formErrors, slug: 'This URL is already in use' });
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const data = await call(
      WorkspacesService.updateWorkspace({
        wsid: workspace?.id ?? '',
        requestBody: {
          name: formValues.name || '',
          slug: formValues.slug || '',
        },
      }),
    );
    if (data.success) {
      await updateMe();
      navigate(routes.workspaceDetails.make(organization?.slug, formValues.slug));
      setIsSubmitting(false);
      return;
    }
    if (data.error) {
      setQueryError(data.error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Wrapper>
        <div className="leftSide">
          <h3>General</h3>
        </div>

        <div className="rightSide">
          <div className="rightSide-columns">
            <FirstFormRowWrapper>
              <InputWrapper>
                <label htmlFor="name">Workspace name</label>
                <Input
                  className="whiteInput"
                  disabled={!canEditWS || isSubmitting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormValues({
                      ...formValues,
                      name: e.target.value,
                    })
                  }
                  name="name"
                  value={formValues.name}
                  label="Workspace name"
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="slug">Workspace URL</label>
                <Input
                  className="whiteInput"
                  disabled={!canEditWS}
                  name="slug"
                  value={formValues.slug}
                  label="Workspace URL"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormValues({ ...formValues, slug: e.target.value });
                    isSlugAvailable(e.target.value);
                  }}
                  error={!!formErrors.slug}
                />
              </InputWrapper>
            </FirstFormRowWrapper>
          </div>

          <MessagesFormRowWrapper>
            {queryError ? <MessagesErrorMessage>{queryError}</MessagesErrorMessage> : null}
            {formErrors.name ? <MessagesErrorMessage>{formErrors.name}</MessagesErrorMessage> : null}
            {formErrors.slug ? <MessagesErrorMessage>{formErrors.slug}</MessagesErrorMessage> : null}
            {workspace?.slug !== formValues.slug ? (
              <MessagesInfoMessage>This change will invalidate sent out job sharing links</MessagesInfoMessage>
            ) : null}
          </MessagesFormRowWrapper>

          {canEditWS ? (
            <SaveWrapper>
              <SaveButton
                variant="bold"
                disabled={
                  !canEditWS ||
                  isSubmitting ||
                  !!(formErrors.slug || formErrors.name) ||
                  !(formValues.slug && formValues.name)
                }
                onClick={() => handleSubmit()}
              >
                Save
              </SaveButton>
            </SaveWrapper>
          ) : null}
        </div>
      </Wrapper>
    </>
  );
};
