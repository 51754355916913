import React from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { useAuthContext } from '@providers/AuthProvider';
import { UniversalCard } from '@components/UniversalCard';
import { getShortName } from '@helpers/getShortName';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { TOrganization } from 'app/types/entities/TOrganization';

export type TProps = {
  workspace: TWorkspace;
  organization?: TOrganization;
  handleWorkspaceClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, workspace: TWorkspace) => void;
};

export type TAction = {
  label: string;
  onClick?: () => void;
  className?: string;
};

export const WorkspaceCard = ({ workspace, organization }: TProps) => {
  const { openModal } = useModalContext();
  const { me } = useAuthContext();
  const navigate = useNavigate();

  const handleDeleteWorkspace = () => {
    openModal(ModalNamesEnum.DeleteWorkspaceModal, { workspace });
  };

  const handleDetails = () => {
    const organization = me.organizations.find((o) => o.id == workspace.organizationId);
    if (organization) {
      navigate(routes.workspaceDetails.make(organization?.slug ?? '', workspace.slug));
    }
  };

  const actions: TAction[] = [{ label: 'Details', onClick: handleDetails }];
  if (organization?.permissions?.manageWorkspaces) {
    actions.push({ label: '', className: 'divider' });
    actions.push({ label: 'Remove', className: 'red', onClick: handleDeleteWorkspace });
  }

  const content = (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          handleDetails();
        }}
        href={routes.workspaceDetails.make(organization?.slug ?? '', workspace.slug)}
        className="font-medium text-queen-blue hover:text-primary"
      >
        {workspace.name || workspace.slug}
      </a>
      <p className="text-primary text-xs">{organization?.name}&nbsp;</p>
      <p className="text-primary text-xs">&nbsp;</p>
    </>
  );

  const workspaceShortname = getShortName(workspace.name || workspace.slug);

  return (
    <UniversalCard
      key={workspace.id}
      content={content}
      shortName={workspaceShortname}
      actions={actions}
      onClick={handleDetails}
    />
  );
};
