import { useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { ClickAway } from '@components/ClickAway';

import { TOrganization } from '../../../../../../../../../../../../../types/entities/TOrganization';

import { CurrentOrganizationRow } from './components/CurrentOrganizationRow';
import { OrganizationRow } from './components/OrganizationRow';

import { Wrapper } from './styles/Wrapper';
import { OrgSVGSpan } from './styles/OrgSVGSpan';
import { StyledAddBlue } from './styles/StyledAddBlue';
import { StyledSixDots } from './styles/StyledSixDots';
import { OrgLink } from './styles/OrgLink';
import { OrgListCreate } from './styles/OrgListCreate';

export const OrganizationList = () => {
  const [orgListOpen, setOrgListOpen] = useState(false);
  const { me, organization } = useAuthContext();

  const navigate = useNavigate();

  const { openModal } = useModalContext();

  const handleOrganizationClick = (org: TOrganization) => {
    setOrgListOpen(false);
    navigate(routes.dashboard.make(org.slug));
  };

  const handleCreateOrganization = () => {
    setOrgListOpen(false);
    openModal(ModalNamesEnum.NewOrganizationModal);
  };

  return (
    <Wrapper>
      <ClickAway onClickAway={() => setOrgListOpen(false)}>
        <>
          {organization ? (
            <CurrentOrganizationRow
              organization={organization}
              setOrgListOpen={setOrgListOpen}
              orgListOpen={orgListOpen}
            />
          ) : null}
          {organization ? (
            <div className={`relative ${orgListOpen ? '' : 'hidden'}`}>
              <div className="w-full h-auto max-h-52 overflow-y-auto absolute z-30 bg-white shadow-xl">
                <ul className="py-1 overflow-hidden">
                  {me.organizations
                    ? me.organizations
                        .filter((org) => org.id != organization.id)
                        .map((org: TOrganization, index: number) => (
                          <OrganizationRow key={index} org={org} handleOrganizationClick={handleOrganizationClick} />
                        ))
                    : null}
                  <li className="h-9 cursor-pointer text-primary text-base hover:bg-alice-blue flex flex-row">
                    <OrgLink to={routes.organizationsList.pattern} onClick={() => setOrgListOpen(false)}>
                      <OrgSVGSpan>
                        <StyledSixDots color="#858DBD" />
                      </OrgSVGSpan>
                      <span className="pl-0 leading-7">View all organizations</span>
                    </OrgLink>
                  </li>
                  <li className="h-9 cursor-pointer text-primary text-base hover:bg-alice-blue flex flex-row">
                    <OrgListCreate onClick={handleCreateOrganization}>
                      <OrgSVGSpan>
                        <StyledAddBlue stroke="#858DBD" />
                      </OrgSVGSpan>
                      <span className="pl-0 leading-7 whitespace-nowrap overflow-ellipsis">
                        Create new organization
                      </span>
                    </OrgListCreate>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
        </>
      </ClickAway>
    </Wrapper>
  );
};
