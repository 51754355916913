import { Calendar } from '@components/icons/Calendar';

import ErrorIcon from './icons/error.svg';
import { TProps } from './types/TProps';

export const Icon = ({ onIconClick, error }: TProps) => {
  if (error) {
    return <ErrorIcon onClick={onIconClick} className={`icon ${onIconClick ? 'clickable' : ''}`} />;
  }
  return <Calendar onClick={onIconClick} className={`icon ${onIconClick ? 'clickable' : ''}`} />;
};
