import styled from 'styled-components';

export const Wrapper = styled.div`
  display: contents;
  text-align: center;
  .root {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
    width: 100%;
    max-width: 66%;
  }

  .forgotPassword {
    color: #40608f;
    margin: 0 0 50px;
    font-size: 18px;

    line-height: 22px;
    font-weight: 600;
  }
  .noWorries {
    text-align: center;
    margin: 0 0 30px;
    color: #858dbd;

    line-height: 12px;
    font-size: 12px;
    font-weight: 600;
  }
  .form {
    text-align: initial;
  }
  .content {
    margin: auto;
  }
  .backToLogin {
    display: block;
    text-align: center;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline;
    color: #40608f;
    margin: 0 0 30px;
    &:hover {
      color: #40608f;
      text-decoration: none;
    }
  }
  .dontHaveAnAccount {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #40608f;
    text-align: center;
    margin: 0 0 30px;
  }
  .signUp {
    color: #40608f;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .formError {
    margin-bottom: 30px;
  }
  .margin30 {
    margin-bottom: 30px;
  }
  .checkEmailIcon {
    margin: 0 0 30px;
  }
  .completePlease {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #40608f;
    margin: 0 0 30px;
  }
  .completeWeSent {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #858dbd;
    margin: 0 0 30px;
  }
  .completeDidnt {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #40608f;
  }
  .completeResend {
    cursor: pointer;
    text-decoration: underline;
    color: #40608f;
  }
`;
