import { Block } from '@components/Block';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';

type RepositoryItem = {
  label: string;
  value: string;
};

export const LayoutBlock = () => {
  const { form, setFormItemValue, isLoading, layouts } = usePageContext();

  const handleSelectChange = async (item: RepositoryItem) => {
    setFormItemValue('layoutId', item?.value === 'null' ? null : item?.value);
  };

  const layoutsList = [
    {
      label: 'None',
      value: 'null',
    },
    ...(layouts || []).map((l) => ({
      value: l.id,
      label: l.name,
    })),
  ];

  if (!form.item?.status || jobIsScheduled(form.item?.status)) {
    return null;
  }

  return (
    <Block title="Cloud Layout">
      <SmallWhiteSelect
        name="layoutId"
        label="Cloud Layout"
        value={form.item?.layoutId || 'null'}
        disabled={layoutsList.length < 2 || isLoading}
        onChange={(label, value) => handleSelectChange({ label, value })}
        options={layoutsList}
        required={false}
      />
    </Block>
  );
};
