import styled from 'styled-components';

export const MainContent = styled.main`
  padding: 20px 30px 50px;
  min-height: calc(100vh - 120px);

  .no-notifications {
    color: #858dbd;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .notifications-list {
    min-height: 240px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .notification {
    display: flex;
    gap: 10px;
    .content {
      position: relative;
      overflow: hidden;
      width: 100%;
      .unseen-dot {
        background: #7cb1fe;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        position: absolute;
        right: 4px;
        top: 34px;
      }
      .delete-button {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 0;
        top: 4px;
        padding: 0;
        flex: 0 0 auto;
        border: none;
        svg {
          width: 100%;
          height: 100%;
        }
        :hover {
          svg path {
            stroke: #40608f;
          }
        }
      }
      .org-name {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        padding: 0 0 4px;
        margin: 0;
        color: #858dbd;
      }
      .message {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        padding: 0 20px 8px 0;
        margin: 0;
        color: #313131;
      }
      a {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        padding: 0 0 8px;
        margin: -5px 0 0;
        color: #858dbd;
        position: relative;
        display: block;
        &:hover {
          color: #40608f;
        }
      }
      .created {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        margin: 0;
        color: #858dbd;
      }
    }
  }

  .entity-name {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-transform: uppercase;
    border-radius: 5px;
    height: 60px;
    width: 60px;
    background: #cadcf8;
    img {
      border-radius: 5px;
    }
  }
`;
