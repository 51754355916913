export const DownloadApp = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.13278 7.66667V1M6.13278 7.66667L3.91056 5.44444M6.13278 7.66667L8.355 5.44444M1 11H11.2656"
        stroke="#40608F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
