import React from 'react';

export const CheckEmail = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#F4F8FE" />
      <circle cx="30" cy="30" r="20" fill="#E2ECFC" />
      <path
        d="M22.0004 21.1503C19.8741 21.1503 18.1504 22.874 18.1504 25.0003V34.8003C18.1504 36.9266 19.8741 38.6503 22.0004 38.6503H37.0003C39.1266 38.6503 40.8503 36.9266 40.8503 34.8003V28.5003C40.8503 28.0309 40.4698 27.6503 40.0003 27.6503C39.5309 27.6503 39.1503 28.0309 39.1503 28.5003V34.8003C39.1503 35.9877 38.1878 36.9503 37.0003 36.9503H22.0004C20.813 36.9503 19.8504 35.9877 19.8504 34.8003V26.7861L28.8143 31.3577C29.0498 31.4778 29.3278 31.4815 29.5664 31.3677L36.0664 28.2677C36.4901 28.0656 36.6698 27.5583 36.4677 27.1346C36.2657 26.7109 35.7583 26.5312 35.3346 26.7333L29.2131 29.6527L19.8537 24.8794C19.9164 23.7483 20.8535 22.8503 22.0004 22.8503H33.7004C34.1698 22.8503 34.5504 22.4697 34.5504 22.0003C34.5504 21.5309 34.1698 21.1503 33.7004 21.1503H22.0004Z"
        fill="#40608F"
      />
      <path
        d="M42.5005 22.9004C42.5005 24.8334 40.9335 26.4004 39.0005 26.4004C37.0675 26.4004 35.5005 24.8334 35.5005 22.9004C35.5005 20.9674 37.0675 19.4004 39.0005 19.4004C40.9335 19.4004 42.5005 20.9674 42.5005 22.9004Z"
        fill="#40608F"
      />
    </svg>
  );
};
