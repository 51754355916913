import React from 'react';

export const SubscriptionsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 8C10 8.79565 9.68393 9.55871 9.12132 10.1213C8.55871 10.6839 7.79565 11 7 11C6.20435 11 5.44129 10.6839 4.87868 10.1213C4.31607 9.55871 4 8.79565 4 8C4 7.20435 4.31607 6.44129 4.87868 5.87868C5.44129 5.31607 6.20435 5 7 5C7.79565 5 8.55871 5.31607 9.12132 5.87868C9.68393 6.44129 10 7.20435 10 8ZM11 16C11 14.9391 10.5786 13.9217 9.82843 13.1716C9.07828 12.4214 8.06087 12 7 12C5.93913 12 4.92172 12.4214 4.17157 13.1716C3.42143 13.9217 3 14.9391 3 16V19H11V16Z"
      fill="#858DBD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7.00012C16.5523 7.00012 17 7.44784 17 8.00012V16.0001C17 16.5524 16.5523 17.0001 16 17.0001C15.4477 17.0001 15 16.5524 15 16.0001V8.00012C15 7.44784 15.4477 7.00012 16 7.00012Z"
      fill="#858DBD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 12.0001C11 11.4478 11.4477 11.0001 12 11.0001L20 11.0001C20.5523 11.0001 21 11.4478 21 12.0001C21 12.5524 20.5523 13.0001 20 13.0001H12C11.4477 13.0001 11 12.5524 11 12.0001Z"
      fill="#858DBD"
    />
  </svg>
);
