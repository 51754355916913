import React from 'react';
import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { StyledSelect } from '../../styles/StyledSelect';
import { usePageContext } from '../../../../providers/PageProvider';

export const RegistrationPageSurveyStage = () => {
  const {
    setStage,
    howDidYouHearAboutUs,
    setHowDidYouHearAboutUs,
    howDidYouHearAboutUsReferral,
    setHowDidYouHearAboutUsReferral,
    howDidYouHearAboutUsComment,
    setHowDidYouHearAboutUsComment,
    goToLogin,
  } = usePageContext();

  const isCommentRequired = howDidYouHearAboutUs === 'Other';
  const isButtonDisabled =
    !howDidYouHearAboutUs?.length ||
    (howDidYouHearAboutUs === 'Referral' && !howDidYouHearAboutUsReferral?.length) ||
    (howDidYouHearAboutUs === 'Other' && !howDidYouHearAboutUsComment?.length);

  const handleFormSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setStage('signupPhone');
  };

  const howDidYouHearOptions = [
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Google/Search Engine', label: 'Google/Search Engine' },
    { value: 'TCRA', label: 'TCRA' },
    { value: 'Word of mouth', label: 'Word of mouth' },
    { value: 'Referral', label: 'Referral' },
    { value: 'Other', label: 'Other' },
  ];

  return (
    <div className="root">
      <h1 className="header">How Did You Hear About Us?</h1>

      <form onSubmit={handleFormSubmit}>
        <div className="margin20">
          <StyledSelect
            name="howDidYouHearAboutUs"
            label="Select from this list..."
            value={howDidYouHearAboutUs ?? ''}
            onChange={(name, value) => {
              setHowDidYouHearAboutUs(value);
            }}
            options={howDidYouHearOptions}
            required={true}
            hideSelectedOption={false}
          />
        </div>

        {howDidYouHearAboutUs && howDidYouHearAboutUs === 'Referral' ? (
          <div className="margin20">
            <Input
              onChange={(e) => {
                setHowDidYouHearAboutUsReferral(e.target.value);
              }}
              name="howDidYouHearAboutUsReferral"
              label="Referral"
              value={howDidYouHearAboutUsReferral || ''}
              required={true}
            />
          </div>
        ) : null}

        <div className="margin20">
          <Input
            onChange={(e) => {
              setHowDidYouHearAboutUsComment(e.target.value);
            }}
            name="howDidYouHearAboutUsComment"
            label="Leave a comment"
            value={howDidYouHearAboutUsComment || ''}
            required={isCommentRequired}
          />
        </div>

        <Button size="medium" disabled={isButtonDisabled} className="margin30" type="submit">
          Next
        </Button>
      </form>

      <p className="haveAnAccount">
        Have an account?&nbsp;&nbsp;
        <span onClick={goToLogin} className="signIn">
          Sign In
        </span>
      </p>
    </div>
  );
};
