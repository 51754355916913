import Wave from './wave.svg';
import styled from 'styled-components';
import { Button } from '@components/form-elements/buttons/Button';

const Root = styled.div`
  min-height: 100vh;
  max-width: 540px;
  margin: 0 auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 0 0 110px;
  position: relative;
  text-align: center;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;

  h1 {
    font-size: 42px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #858dbd;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
`;

const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 0 50px;
  color: #858dbd;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  a {
    color: #858dbd;
  }
`;
const TermsPrivacy = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  padding: 20px 40px 10px;
`;
const CopyRight = styled.div`
  padding: 0 40px;
`;
const WaveWrapper = styled.div`
  svg {
    width: 100%;
    margin: 0 auto;
  }
`;

const ButtonsWrapper = styled.div`
  padding: 0 40px 40px;
`;
const StyledButton = styled(Button)`
  width: 130px;
`;

export const InternalErrorPage = () => {
  const onClick = () => {
    window.location.href = '/';
  };

  return (
    <Root>
      <h1>Something went wrong...</h1>
      <p>AutoScript team is working to resolve the problem, please try again later.</p>

      <WaveWrapper>
        <Wave />
      </WaveWrapper>

      <ButtonsWrapper>
        <StyledButton size="small" onClick={onClick}>
          Go Back
        </StyledButton>
      </ButtonsWrapper>

      <Footer>
        <TermsPrivacy>
          <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          |{' '}
          <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </TermsPrivacy>
        <CopyRight>&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</CopyRight>
      </Footer>
    </Root>
  );
};
