import { useEffect, useState } from 'react';
import { useAuthContext } from '@providers/AuthProvider';
import { useAPI } from '@hooks/useAPI';
import { DashboardService } from 'app/API';

export const useDashboard = () => {
  const { call } = useAPI();
  const { organization } = useAuthContext();
  const [dashboardInfo, setDashboardInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const fetch = () => {
    setIsLoading(true);
    call(DashboardService.getDashboardInfo({ oid: organization?.id ?? '' })).then((dashboardInfo) => {
      setDashboardInfo(dashboardInfo);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetch();
  }, [organization]);

  return { isLoading, dashboardInfo };
};
