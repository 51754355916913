import React, { useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';

import { uploadAvatar } from '@queries/uploadS3Image';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Input } from '@components/form-elements/Input';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Button } from '@components/form-elements/buttons/Button';
import { TrashBin } from '@components/icons/TrashBin';
import { IconButton } from '@components/form-elements/buttons/IconButton';

import { EyeIcon } from '@components/icons/Eye';
import { EyeClosedIcon } from '@components/icons/EyeClosed';
import toast from 'react-hot-toast';
import { isArray } from 'lodash-es';
import { UpdateAccountDto, UserService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput input {
    background: white !important;
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div``;

const RightSideColumns = styled.div`
  display: flex;
  gap: 10px;
`;

const InputBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  width: 270px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const ErrorMessage = styled.div`
  color: #ff2f2f;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 40px 0 0;
  text-align: center;
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #b4b9d9;
  text-align: left;
  position: relative;
  padding: 10px 0;
  width: 100%;
`;

const AvatarBlock = styled.div`
  padding: 0 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
`;
const UploadAvatarButton = styled(Button)`
  width: 130px;
`;
const StyledIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
const RemoveAvatarButton = styled(StyledIconButton)`
  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
const Avatar = styled.img`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
`;
const AvatarName = styled.div`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  text-transform: capitalize;
`;

export const UserDetails = () => {
  const { call } = useAPI();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { me, updateMe } = useAuthContext();
  const { openModal, closeModal } = useModalContext();

  const [form, setForm] = useState({
    name: me.name,
    lastname: me.lastname,
    email: me.email,
    phone: me.phone,
    password: '',
    repeatPassword: '',
  });

  const submitAvatar = () => {
    openModal(ModalNamesEnum.ImageUploadModal, {
      onSuccess: async (avatar: string) => {
        await uploadAvatar(avatar);
        await updateMe();
        closeModal();
      },
    });
  };

  const removeAvatar = async () => {
    await call(UserService.deleteAvatar());
    await updateMe();
  };

  const renderAvatar = () => {
    const avatarName = `${me.name[0]}${me.lastname[0]}`;

    return (
      <AvatarBlock>
        {me?.avatar ? <Avatar src={me.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
        <UploadAvatarButton variant="outlined" onClick={submitAvatar} type="button">
          Upload Picture
        </UploadAvatarButton>
        {me.avatar ? (
          <RemoveAvatarButton title="Remove" onClick={removeAvatar}>
            <TrashBin />
          </RemoveAvatarButton>
        ) : null}
      </AvatarBlock>
    );
  };

  const submit = async () => {
    if (form.password !== form.repeatPassword) {
      setError(`Please enter the same password in both fields`);
      return;
    }

    setError(null);
    setIsSubmitting(true);
    const formFields: UpdateAccountDto = {
      name: form.name,
      lastname: form.lastname,
    };

    if (form.password) {
      formFields.password = form.password;
    }

    try {
      const result = await call(UserService.updateAccount({ requestBody: formFields }));
      if (result.statusCode === 400 && isArray(result.message)) {
        toast.error(result.message.join('\n'));
        setIsSubmitting(false);
        return;
      }
    } catch (error: any) {
      if (error.status === 400 && isArray(error.body.message)) {
        toast.error(error.body.message.join('\n'));
        return;
      }

      toast.error('Something went wrong :( \nPlease connect to administrator');
    }

    await updateMe();
    toast.success('Successfully updated');
    setIsSubmitting(false);
  };

  return (
    <>
      <Wrapper>
        {isSubmitting && <AbsoluteSpinner overlay={true} />}
        <LeftSide>
          <h3>General</h3>
          <p>Update your account details and password.</p>
        </LeftSide>

        <RightSide>
          {renderAvatar()}
          <RightSideColumns>
            <InputBlockWrapper>
              <InputWrapper>
                <label htmlFor="name">First name</label>
                <Input
                  className="whiteInput"
                  disabled={isSubmitting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      name: e.target.value,
                    })
                  }
                  name="name"
                  value={form.name}
                  label="First name"
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="email">Email address</label>
                <Input
                  className="whiteInput"
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      email: e.target.value.toLowerCase(),
                    })
                  }
                  name="email"
                  value={form.email}
                  label="Email address"
                />
              </InputWrapper>
            </InputBlockWrapper>
            <InputBlockWrapper>
              <InputWrapper>
                <label htmlFor="lastName">Last name</label>
                <Input
                  className="whiteInput"
                  disabled={isSubmitting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      lastname: e.target.value,
                    })
                  }
                  name="lastName"
                  value={form.lastname}
                  label="Last name"
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="phone">Phone</label>
                <Input
                  className="whiteInput"
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      phone: e.target.value,
                    })
                  }
                  name="phone"
                  value={form.phone}
                  label="Phone"
                />
              </InputWrapper>
            </InputBlockWrapper>
          </RightSideColumns>

          <Description>
            For assistance in changing your email or phone number, please contact{' '}
            <a
              href="https://support.voicescript.ai/portal/en/home"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              support
            </a>
            .
          </Description>

          <RightSideColumns>
            <InputBlockWrapper>
              <InputWrapper>
                <label htmlFor="newPassword">New password</label>
                <Input
                  className="whiteInput"
                  disabled={isSubmitting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      password: e.target.value,
                    })
                  }
                  onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  name="password"
                  label="New password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={form.password}
                  required={true}
                  icon={isPasswordVisible ? EyeIcon : EyeClosedIcon}
                />
              </InputWrapper>
            </InputBlockWrapper>
            <InputBlockWrapper>
              <InputWrapper>
                <label htmlFor="repeatPassword">Repeat password</label>
                <Input
                  className="whiteInput"
                  disabled={isSubmitting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      repeatPassword: e.target.value,
                    })
                  }
                  onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  name="repeatPassword"
                  label="Repeat password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={form.repeatPassword}
                  required={true}
                  icon={isPasswordVisible ? EyeIcon : EyeClosedIcon}
                />
              </InputWrapper>
            </InputBlockWrapper>
          </RightSideColumns>
          <Description>Minimum 6 characters with the symbols and numbers</Description>
        </RightSide>
      </Wrapper>

      <div className="grid place-items-end">
        <div className="w-36">
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Button className="my-2" disabled={isSubmitting} variant="bold" onClick={submit}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
