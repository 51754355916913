import React from 'react';

export const Add = (props: React.SVGProps<SVGSVGElement>) => {
  // eslint-disable-next-line react/prop-types
  const { stroke } = props;

  return (
    <svg
      width="14"
      height="14"
      fill="none"
      {...props}
      stroke={stroke || 'white'}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 1V13" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 7L13 7" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
