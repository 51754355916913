export const ArrowIcon = ({ className, ...restProps }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className ?? 'arrow-icon'}
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M1.5 10.5L9.5 1.5L17.5 10.5"
        stroke="#858DBD"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
