import { client } from 'app/http';
import { TJob } from 'app/types/entities/TJob';

type TResponse = {
  data: TJob[];
  count: number;
};

export const getAllJobs = async (
  page = 1,
  pageSize = 25,
  orderBy = 'id',
  orderType = 'asc',
  search = '',
  repositoryId = '',
): Promise<TResponse> => {
  const { data } =
    (await client.get(
      `repository/${repositoryId}/jobs?pageNo=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${encodeURIComponent(
        search,
      )}`,
    )) || {};

  return {
    data: data.data,
    count: data.count,
  };
};
